// THIS IS A GENERATED FILE, DONT MODIFY , use `npm run codegen` to regenerate
/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Upload: any;
};

export type Aggregate = {
  __typename?: 'Aggregate';
  _id?: Maybe<Scalars['ID']>;
  results?: Maybe<Array<Maybe<ResultsAggregate>>>;
};

export type AmountDelivery = {
  __typename?: 'AmountDelivery';
  currency?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  deliveryTime?: Maybe<Scalars['Date']>;
  error?: Maybe<Scalars['String']>;
};

export type Area = {
  __typename?: 'Area';
  _id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['Int']>;
  nameEs?: Maybe<Scalars['String']>;
  nameEsFriendly?: Maybe<Scalars['String']>;
};

export type AreaNew = {
  nameEs?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Attachments = {
  __typename?: 'Attachments';
  fileName?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
};

export type AttachmentsNew = {
  fileName?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
};

export type Auth = {
  __typename?: 'Auth';
  OK: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  me?: Maybe<User>;
};

export type Author = {
  __typename?: 'Author';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  books?: Maybe<Array<Maybe<Book>>>;
};

export type AuthorNew = {
  name?: Maybe<Scalars['String']>;
};

export type Availability = {
  __typename?: 'Availability';
  OK?: Maybe<Scalars['Boolean']>;
  msg?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Banner = {
  __typename?: 'Banner';
  active?: Maybe<Scalars['Boolean']>;
  launching?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  price?: Maybe<Scalars['Float']>;
  data?: Maybe<Scalars['String']>;
};

export type BannerBoNew = {
  _id?: Maybe<Scalars['ID']>;
  banner?: Maybe<Scalars['Boolean']>;
  launching?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  price?: Maybe<Scalars['Float']>;
};

export type BannerInfoNew = {
  active?: Maybe<Scalars['Boolean']>;
  launching?: Maybe<Scalars['Boolean']>;
};

export type BasicUser = {
  _id: Scalars['ID'];
  email: Scalars['String'];
  date?: Maybe<Scalars['Date']>;
};

export type Basket = {
  __typename?: 'Basket';
  _id: Scalars['ID'];
  ean: Scalars['String'];
  units: Scalars['Int'];
  product_type?: Maybe<Scalars['String']>;
  book?: Maybe<Book>;
};

export type BasketNew = {
  _id: Scalars['ID'];
  ean: Scalars['String'];
  units: Scalars['Int'];
};

export type Book = {
  __typename?: 'Book';
  _id: Scalars['ID'];
  id: Scalars['Int'];
  product_type?: Maybe<Scalars['String']>;
  group_property_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  ean: Scalars['String'];
  title: Scalars['String'];
  titleFriendly: Scalars['String'];
  not_released: Scalars['Boolean'];
  brand?: Maybe<Brand>;
  prices?: Maybe<Prices>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
  isVariantGroup?: Maybe<Scalars['Boolean']>;
  areas_of_interest_text?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  themes_text?: Maybe<Array<Maybe<Scalars['String']>>>;
  vat?: Maybe<Vat>;
  dimensions?: Maybe<Dimensions>;
  weight?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  stock_available?: Maybe<Scalars['Int']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  has_description?: Maybe<Scalars['Boolean']>;
  has_picture?: Maybe<Scalars['Boolean']>;
  book?: Maybe<BookInfo>;
  discount?: Maybe<DiscountInfo>;
  subjects?: Maybe<Array<Maybe<Scalars['String']>>>;
  pics?: Maybe<Array<Maybe<Picture>>>;
  reposition_level?: Maybe<Scalars['Int']>;
  attachments?: Maybe<Array<Maybe<Attachments>>>;
  deleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Author>>>;
  clicks?: Maybe<Scalars['Int']>;
  variant?: Maybe<Variant>;
  variantProduct?: Maybe<Book>;
  tags?: Maybe<Array<Maybe<Tags>>>;
  suscription?: Maybe<Array<Maybe<SuscriptionBook>>>;
  variantChildren?: Maybe<Array<Maybe<Book>>>;
  defaultVariant?: Maybe<Scalars['Int']>;
  /** default_variant: String */
  prepublication?: Maybe<Prepublication>;
  flashOffer?: Maybe<FlashOffer>;
  star?: Maybe<Star>;
  banner?: Maybe<Banner>;
  availability?: Maybe<Availability>;
  mainImg?: Maybe<Scalars['String']>;
  allImg?: Maybe<Array<Maybe<ImgProduct>>>;
  priceWithDiscount?: Maybe<Scalars['Float']>;
  multiplyBookSearch?: Maybe<Scalars['Float']>;
  deliveryTime?: Maybe<Scalars['String']>;
  deliveryName?: Maybe<Scalars['String']>;
  lastBuyPrice?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  buyExpenses?: Maybe<Scalars['Float']>;
  isNotToPublish?: Maybe<Scalars['Boolean']>;
  sales?: Maybe<Scalars['Int']>;
  featured?: Maybe<Scalars['Boolean']>;
  is_new?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['String']>;
  priority_nov?: Maybe<Scalars['String']>;
  relatedInfo?: Maybe<Book>;
  /** var control pre-publicaction */
  ebookContent?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BookAxonNew = {
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  productType?: Maybe<Scalars['Int']>;
  ean?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  copyright?: Maybe<Scalars['Int']>;
  notReleased?: Maybe<Scalars['Boolean']>;
  supplierReference?: Maybe<Scalars['String']>;
  instoreFrom?: Maybe<Scalars['Date']>;
  priceSale?: Maybe<Scalars['Float']>;
  priceClearing?: Maybe<Scalars['Float']>;
  priceSupplier?: Maybe<Scalars['Float']>;
  priceCatalog?: Maybe<Scalars['Float']>;
  saleSpecialDiscount?: Maybe<Scalars['Float']>;
  ssdFrom?: Maybe<Scalars['Date']>;
  ssdTo?: Maybe<Scalars['Date']>;
  dimensionsDepth?: Maybe<Scalars['Float']>;
  dimensionsWidth?: Maybe<Scalars['Float']>;
  dimensionsHeight?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  stockPurchased: Scalars['Int'];
  stockSold: Scalars['Int'];
  stockDeposit: Scalars['Int'];
  stockInspection: Scalars['Int'];
  stockInventoryDiff: Scalars['Int'];
  stockRepositionlevel?: Maybe<Scalars['Int']>;
  stockInventory?: Maybe<Scalars['Int']>;
  stockWarehouse?: Maybe<Scalars['Int']>;
  stockStore?: Maybe<Scalars['Int']>;
  lastBuyPrice?: Maybe<Scalars['Float']>;
  lastBuyDate?: Maybe<Scalars['Date']>;
  lastBuyDocument?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  exchangeKey?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  buyExpenses?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  vatBuy?: Maybe<Scalars['Float']>;
  vatSale?: Maybe<Scalars['Float']>;
  isUnavailable?: Maybe<Scalars['Boolean']>;
  hasDescription?: Maybe<Scalars['Boolean']>;
  hasPicture?: Maybe<Scalars['Boolean']>;
  hasCover: Scalars['Boolean'];
  isCanalSalud?: Maybe<Scalars['Boolean']>;
  isNotToPublish?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['Int']>;
  supplierId?: Maybe<Scalars['String']>;
  editedBy?: Maybe<Scalars['Int']>;
  deliveryTime?: Maybe<Scalars['String']>;
  deliveryName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type BookInfo = {
  __typename?: 'BookInfo';
  authors?: Maybe<Array<Maybe<Author>>>;
  lang?: Maybe<Scalars['String']>;
  pages?: Maybe<Scalars['Int']>;
  binding?: Maybe<Scalars['String']>;
  edition?: Maybe<Edition>;
  description?: Maybe<Scalars['String']>;
  table_of_contents?: Maybe<Scalars['String']>;
  relatedInfo?: Maybe<Book>;
};

export type BookInfoNew = {
  authors?: Maybe<Array<Maybe<AuthorNew>>>;
  lang?: Maybe<Scalars['String']>;
  pages?: Maybe<Scalars['Int']>;
  binding?: Maybe<Scalars['String']>;
  edition?: Maybe<EditionNew>;
  description?: Maybe<Scalars['String']>;
  table_of_contents?: Maybe<Scalars['String']>;
};

export type BookInvoice = {
  __typename?: 'BookInvoice';
  _id: Scalars['ID'];
  ean: Scalars['String'];
  title: Scalars['String'];
  vat?: Maybe<Vat>;
  prices?: Maybe<Prices>;
  mainImg?: Maybe<Scalars['String']>;
};

export type BookNew = {
  _id?: Maybe<Scalars['ID']>;
  id: Scalars['Int'];
  related?: Maybe<Scalars['Int']>;
  product_type?: Maybe<Scalars['String']>;
  ean: Scalars['String'];
  title: Scalars['String'];
  not_released: Scalars['Boolean'];
  isVariantGroup?: Maybe<Scalars['Boolean']>;
  defaultVariant?: Maybe<Scalars['Int']>;
  brand?: Maybe<BrandNew>;
  prices?: Maybe<PricesNew>;
  vat?: Maybe<VatNew>;
  dimensions?: Maybe<DimensionsNew>;
  weight?: Maybe<Scalars['Float']>;
  stock_available?: Maybe<Scalars['Int']>;
  created_at: Scalars['String'];
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
  themes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  unavailable?: Maybe<Scalars['Boolean']>;
  has_description?: Maybe<Scalars['Boolean']>;
  has_picture?: Maybe<Scalars['Boolean']>;
  book?: Maybe<BookInfoNew>;
  discount?: Maybe<DiscountInfoNew>;
  subjects?: Maybe<Array<Maybe<Scalars['String']>>>;
  pictures?: Maybe<Array<Maybe<PictureNew>>>;
  scheduled?: Maybe<ScheduledNew>;
  titleFriendly?: Maybe<Scalars['String']>;
  reposition_level?: Maybe<Scalars['Int']>;
  attachments?: Maybe<Array<Maybe<AttachmentsNew>>>;
  deleted?: Maybe<Scalars['Boolean']>;
  mainImg?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<TagBookNew>>>;
  pics?: Maybe<Array<Maybe<PicsNew>>>;
  group_property_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  variant?: Maybe<VariantNew>;
  isNotToPublish?: Maybe<Scalars['Boolean']>;
  deliveryTime?: Maybe<Scalars['String']>;
  deliveryName?: Maybe<Scalars['String']>;
  areas_of_interest_text?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes_text?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastBuyPrice?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  buyExpenses?: Maybe<Scalars['Float']>;
};

export type Brand = {
  __typename?: 'Brand';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  books?: Maybe<Array<Maybe<Book>>>;
};

export type BrandJournal = {
  __typename?: 'BrandJournal';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type BrandJournalNew = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type BrandNew = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type CancelOrder = {
  _id?: Maybe<Scalars['ID']>;
  state_order: Scalars['String'];
};

export type CancelOrderProduct = {
  order_id: Scalars['ID'];
  items: Array<Maybe<InvoiceItemsNew>>;
};

export type Card = {
  __typename?: 'Card';
  type?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  expire_month?: Maybe<Scalars['Int']>;
  expire_year?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

export type Carrier = {
  __typename?: 'Carrier';
  _id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  destination: Scalars['String'];
  destinationCost?: Maybe<Scalars['Float']>;
  additionalCost?: Maybe<Scalars['Float']>;
  estimatedDays?: Maybe<Scalars['String']>;
  additionalCharge?: Maybe<Scalars['Int']>;
};

export type CarrierType = {
  name: Scalars['String'];
  destination: Scalars['String'];
  destinationCost?: Maybe<Scalars['Float']>;
  additionalCost?: Maybe<Scalars['Float']>;
  estimatedDays?: Maybe<Scalars['String']>;
  additionalCharge?: Maybe<Scalars['Int']>;
};

export type CartMetaData = {
  __typename?: 'CartMetaData';
  cartData?: Maybe<Scalars['String']>;
};

export type ConfirmSaveCard = {
  __typename?: 'ConfirmSaveCard';
  ok?: Maybe<Scalars['Boolean']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  _id?: Maybe<Scalars['ID']>;
  campaign?: Maybe<Scalars['String']>;
  coupon: Scalars['String'];
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  discount: Scalars['Float'];
  value: Scalars['Float'];
  times?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  redeemed?: Maybe<Scalars['Boolean']>;
  giftcard?: Maybe<Scalars['Boolean']>;
  accountCode?: Maybe<Scalars['Boolean']>;
  multiple?: Maybe<Scalars['Boolean']>;
  multiRedeem?: Maybe<Scalars['Boolean']>;
  productType?: Maybe<Array<Maybe<Scalars['String']>>>;
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CouponData = {
  _id?: Maybe<Scalars['ID']>;
  campaign?: Maybe<Scalars['String']>;
  coupon: Scalars['String'];
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  discount: Scalars['Float'];
  value: Scalars['Float'];
  productId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  redeemed?: Maybe<Scalars['Boolean']>;
  giftcard?: Maybe<Scalars['Boolean']>;
  accountCode?: Maybe<Scalars['Boolean']>;
  multiple?: Maybe<Scalars['Boolean']>;
  multiRedeem?: Maybe<Scalars['Boolean']>;
  productType?: Maybe<Array<Maybe<Scalars['String']>>>;
  active?: Maybe<Scalars['Boolean']>;
};

export type CouponsUsed = {
  __typename?: 'CouponsUsed';
  _id?: Maybe<Scalars['ID']>;
  coupon?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
  usedDate?: Maybe<Scalars['Date']>;
};

export type CustomerDetails = {
  __typename?: 'CustomerDetails';
  email?: Maybe<Scalars['String']>;
};

export type DeleteUserCompletely = {
  __typename?: 'DeleteUserCompletely';
  OK?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Boolean']>;
};

export type DeletedUser = {
  __typename?: 'DeletedUser';
  OK?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Boolean']>;
};

export type Dimensions = {
  __typename?: 'Dimensions';
  depth?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type DimensionsNew = {
  depth?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type DiscountInfo = {
  __typename?: 'DiscountInfo';
  value?: Maybe<Scalars['Float']>;
  since?: Maybe<Scalars['Date']>;
  until?: Maybe<Scalars['Date']>;
  binding?: Maybe<Scalars['String']>;
  edition?: Maybe<Edition>;
  description?: Maybe<Scalars['String']>;
  table_of_contents?: Maybe<Scalars['String']>;
};

export type DiscountInfoNew = {
  value?: Maybe<Scalars['Float']>;
  since?: Maybe<Scalars['Date']>;
  until?: Maybe<Scalars['Date']>;
};

export type Ebooks = {
  __typename?: 'Ebooks';
  _id?: Maybe<Scalars['ID']>;
  ean?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  buyDate?: Maybe<Scalars['Date']>;
  mainImg?: Maybe<Scalars['String']>;
};

export type Edition = {
  __typename?: 'Edition';
  number?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
};

export type EditionNew = {
  number?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
};

export type FeaturedBo = {
  _id?: Maybe<Scalars['ID']>;
  priority_nov?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  path: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type FileProduct = {
  __typename?: 'FileProduct';
  id: Scalars['String'];
  protocol?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  productEan?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  contentLength?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  serie?: Maybe<Scalars['Int']>;
  postedBy?: Maybe<PostedBy>;
  product?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type FileProductNew = {
  id: Scalars['String'];
  protocol?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  contentLength?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  hash?: Maybe<Scalars['String']>;
  serie?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  postedBy?: Maybe<PostedByNew>;
};

export type Funding_Instruments = {
  __typename?: 'Funding_instruments';
  credit_card?: Maybe<Card>;
};

export type IvAtype = {
  __typename?: 'IVAtype';
  vat: Scalars['Float'];
  total: Scalars['Float'];
};

export type IvAtypeNew = {
  vat: Scalars['Float'];
  total: Scalars['Float'];
};

export type ImgProduct = {
  __typename?: 'ImgProduct';
  link?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type InvoiceBook = {
  _id?: Maybe<Scalars['ID']>;
  ean?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  vat?: Maybe<VatNew>;
  prices?: Maybe<PricesNew>;
  priceWithDiscount?: Maybe<Scalars['Float']>;
};

export type InvoiceData = {
  order_id?: Maybe<Scalars['ID']>;
  orderSequence?: Maybe<Scalars['Int']>;
  userId: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
  amountIVA?: Maybe<Scalars['Float']>;
  amountWithoutIVA?: Maybe<Scalars['Float']>;
  confirmDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  methodPayment?: Maybe<Scalars['String']>;
  accountCode?: Maybe<Scalars['Boolean']>;
  deliveryAddress?: Maybe<OrderDeliveryAddressNew>;
  fiscalAddress?: Maybe<OrderDeliveryAddressNew>;
  fullNameUser?: Maybe<Scalars['String']>;
  IVAtype?: Maybe<Array<Maybe<IvAtypeNew>>>;
  items: Array<Maybe<InvoiceItemsNew>>;
  transport?: Maybe<Scalars['String']>;
  amountDiscount?: Maybe<Scalars['Float']>;
  orderPaidCompletelly?: Maybe<Scalars['Boolean']>;
  paymentIntentId?: Maybe<Scalars['String']>;
};

export type InvoiceItems = {
  __typename?: 'InvoiceItems';
  units?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  book?: Maybe<BookInvoice>;
  priceSign?: Maybe<Scalars['Float']>;
  priceSignDeal?: Maybe<Scalars['Float']>;
};

export type InvoiceItemsNew = {
  _id?: Maybe<Scalars['ID']>;
  units?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  book?: Maybe<InvoiceBook>;
  priceSignDeal?: Maybe<Scalars['Float']>;
  priceSign?: Maybe<Scalars['Float']>;
};

export type Is_NewBo = {
  _id?: Maybe<Scalars['ID']>;
  is_new?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['String']>;
};

export type Link = {
  __typename?: 'Link';
  href?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type LocationNew = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Magazine = {
  __typename?: 'Magazine';
  _id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  titleFriendly?: Maybe<Scalars['String']>;
  journals?: Maybe<Array<Maybe<MagazineJournals>>>;
  packs?: Maybe<Array<Maybe<MagazinePacks>>>;
  createdAt?: Maybe<Scalars['String']>;
};

export type MagazineJournals = {
  __typename?: 'MagazineJournals';
  _id?: Maybe<Scalars['ID']>;
  id_journal?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  issn?: Maybe<Scalars['String']>;
  issues?: Maybe<Scalars['String']>;
  brand?: Maybe<BrandJournal>;
  country_key?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  subjects?: Maybe<Array<Maybe<Scalars['String']>>>;
  packs?: Maybe<Array<Maybe<MagazinePacks>>>;
  titleFriendly?: Maybe<Scalars['String']>;
};

export type MagazineJournalsNew = {
  id_journal?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  issn?: Maybe<Scalars['String']>;
  issues?: Maybe<Scalars['String']>;
  country_key?: Maybe<Scalars['String']>;
  brand?: Maybe<BrandJournalNew>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  subjects?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MagazineNew = {
  title?: Maybe<Scalars['String']>;
  titleFriendly?: Maybe<Scalars['String']>;
  journals?: Maybe<Array<Maybe<MagazineJournalsNew>>>;
  packs?: Maybe<Array<Maybe<MagazinePacksNew>>>;
  creationDate?: Maybe<Scalars['String']>;
};

export type MagazinePacks = {
  __typename?: 'MagazinePacks';
  _id?: Maybe<Scalars['ID']>;
  id_pack?: Maybe<Scalars['Float']>;
  journals?: Maybe<Array<Maybe<MagazineJournals>>>;
  rates?: Maybe<Array<Maybe<MagazinePacksRates>>>;
};

export type MagazinePacksNew = {
  id_pack?: Maybe<Scalars['Float']>;
  journals?: Maybe<Array<Maybe<Scalars['Float']>>>;
  rates?: Maybe<Array<Maybe<MagazinePacksRatesNew>>>;
};

export type MagazinePacksRates = {
  __typename?: 'MagazinePacksRates';
  _id?: Maybe<Scalars['ID']>;
  id_rate?: Maybe<Scalars['Float']>;
  subscriber_type?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  valid_to?: Maybe<Scalars['Date']>;
  is_valid?: Maybe<Scalars['Boolean']>;
};

export type MagazinePacksRatesNew = {
  id_rate?: Maybe<Scalars['Float']>;
  subscriber_type?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  valid_to?: Maybe<Scalars['String']>;
  is_valid?: Maybe<Scalars['Boolean']>;
};

export type Me = {
  __typename?: 'Me';
  _id: Scalars['ID'];
  email: Scalars['String'];
  profile?: Maybe<UserProfile>;
};

export type MetadataOutputStripe = {
  __typename?: 'MetadataOutputStripe';
  basket?: Maybe<Array<Maybe<Basket>>>;
};

export type MetadataStripe = {
  basket?: Maybe<Array<Maybe<BasketNew>>>;
};

export type NewAddress = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  location?: Maybe<LocationNew>;
  deliverTo?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  cif?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  isOutUE?: Maybe<Scalars['Boolean']>;
};

export type NewBillingData = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  cif?: Maybe<Scalars['String']>;
};

export type Newsletter = {
  __typename?: 'Newsletter';
  areaOfInterest?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type NewsletterSignUp = {
  __typename?: 'NewsletterSignUp';
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  topics?: Maybe<Array<Maybe<Scalars['String']>>>;
  fbaja?: Maybe<Scalars['Date']>;
  active?: Maybe<Scalars['Boolean']>;
  idUserOld?: Maybe<Scalars['Int']>;
  falta?: Maybe<Scalars['Date']>;
  fupdate?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  TopicData?: Maybe<Array<Maybe<TopicData>>>;
};

export type NewsletterStatus = {
  __typename?: 'NewsletterStatus';
  status: Scalars['String'];
  areaOfInterest?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type Order = {
  __typename?: 'Order';
  _id?: Maybe<Scalars['ID']>;
  orderPaidCompletelly?: Maybe<Scalars['Boolean']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  orderSequence?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  order_id?: Maybe<Scalars['String']>;
  payerId?: Maybe<Scalars['String']>;
  amountIVA?: Maybe<Scalars['Float']>;
  amountWithoutIVA?: Maybe<Scalars['Float']>;
  transport?: Maybe<Scalars['String']>;
  amountTransport?: Maybe<Scalars['Float']>;
  amountTransportStock?: Maybe<Scalars['Float']>;
  amountTransportNoStock?: Maybe<Scalars['Float']>;
  accountCode?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  /** Monto que se ha cobrado hasta el momento */
  amountCharged?: Maybe<Scalars['Float']>;
  methodPayment?: Maybe<Scalars['String']>;
  fullNameUser?: Maybe<Scalars['String']>;
  fullNameUserClean?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<OrderDeliveryAddress>;
  IVAtype?: Maybe<Array<Maybe<IvAtype>>>;
  legalNameFiscalData?: Maybe<Scalars['String']>;
  fiscalDoc?: Maybe<Scalars['String']>;
  otherTaxes?: Maybe<Array<Maybe<OtherTaxes>>>;
  fiscalAddress?: Maybe<OrderFiscalAddress>;
  items?: Maybe<Array<Maybe<OrderItems>>>;
  state_order?: Maybe<Scalars['String']>;
  amountDiscount?: Maybe<Scalars['Float']>;
  discountIdCode?: Maybe<Scalars['String']>;
  discountIdCodeWork?: Maybe<Scalars['String']>;
  confirmDate?: Maybe<Scalars['Date']>;
  autorizationCode?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['String']>;
  order_multiple?: Maybe<Scalars['Boolean']>;
  giftCardCode?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  outcome?: Maybe<OutcomeOrder>;
  billGridId?: Maybe<Scalars['String']>;
  billId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedAtISO?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Maybe<Order>>>;
  couponObject?: Maybe<Coupon>;
  refund?: Maybe<Refund>;
  observations?: Maybe<Scalars['String']>;
  internObservations?: Maybe<Scalars['String']>;
  responsibles?: Maybe<Array<Maybe<Responsible>>>;
};

export type OrderDeliveryAddress = {
  __typename?: 'OrderDeliveryAddress';
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  cif?: Maybe<Scalars['String']>;
  isOutUE?: Maybe<Scalars['Boolean']>;
};

export type OrderDeliveryAddressNew = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  cif?: Maybe<Scalars['String']>;
  isOutUE?: Maybe<Scalars['Boolean']>;
};

export type OrderFiscalAddress = {
  __typename?: 'OrderFiscalAddress';
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  cif?: Maybe<Scalars['String']>;
};

/**
 * input OrderFiscalAddressNew {
 *   name: String
 *   address: String
 *   locality: String
 *   cif: String
 * }
 */
export type OrderFiscalAddressNew = {
  address?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  businessName?: Maybe<Scalars['String']>;
  cif?: Maybe<Scalars['String']>;
};

export type OrderInternalObservations = {
  _id: Scalars['ID'];
  internObservations: Scalars['String'];
};

export type OrderItems = {
  __typename?: 'OrderItems';
  _id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  book?: Maybe<Book>;
  shipped?: Maybe<Scalars['String']>;
  invoice?: Maybe<Order>;
  priceSign?: Maybe<Scalars['Float']>;
  priceSignDeal?: Maybe<Scalars['Float']>;
  itemState?: Maybe<Scalars['String']>;
  canceledDate?: Maybe<Scalars['Date']>;
};

export type OrderItemsNew = {
  _id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['Float']>;
  shipped?: Maybe<Scalars['String']>;
};

export type OrderNew = {
  _id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  amountIVA?: Maybe<Scalars['Float']>;
  transport?: Maybe<Scalars['String']>;
  amountTransport?: Maybe<Scalars['Float']>;
  amountTransportStock?: Maybe<Scalars['Float']>;
  amountTransportNoStock?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  amountWithoutIVA?: Maybe<Scalars['Float']>;
  methodPayment?: Maybe<Scalars['String']>;
  fullNameUser?: Maybe<Scalars['String']>;
  fullNameUserClean?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<OrderDeliveryAddressNew>;
  IVAtype?: Maybe<Array<Maybe<IvAtypeNew>>>;
  billGridId?: Maybe<Scalars['String']>;
  legalNameFiscalData?: Maybe<Scalars['String']>;
  fiscalDoc?: Maybe<Scalars['String']>;
  fiscalAddress?: Maybe<OrderDeliveryAddressNew>;
  items?: Maybe<Array<Maybe<OrderItemsNew>>>;
  state_order?: Maybe<Scalars['String']>;
  amountDiscount?: Maybe<Scalars['String']>;
  discountIdCode?: Maybe<Scalars['String']>;
  discountIdCodeWork?: Maybe<Scalars['String']>;
  confirmDate?: Maybe<Scalars['Date']>;
  autorizationCode?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['String']>;
  order_multiple?: Maybe<Scalars['Boolean']>;
  accountCode?: Maybe<Scalars['Boolean']>;
  giftCardCode?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  outcome?: Maybe<OutcomeNew>;
  observations?: Maybe<Scalars['String']>;
  orderPaidCompletelly?: Maybe<Scalars['Boolean']>;
  paymentIntentId?: Maybe<Scalars['String']>;
};

export type OrderObservations = {
  _id: Scalars['ID'];
  observations: Scalars['String'];
};

export type OrderResponsibles = {
  _id: Scalars['ID'];
  responsible?: Maybe<BasicUser>;
};

export type OtherTaxes = {
  __typename?: 'OtherTaxes';
  name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
};

export type Outcome = {
  __typename?: 'Outcome';
  network_status?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  risk_level?: Maybe<Scalars['String']>;
  seller_message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OutcomeNew = {
  network_status?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  risk_level?: Maybe<Scalars['String']>;
  seller_message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OutcomeOrder = {
  __typename?: 'OutcomeOrder';
  network_status?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  risk_level?: Maybe<Scalars['String']>;
  seller_message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Payer = {
  __typename?: 'Payer';
  payment_method?: Maybe<Scalars['String']>;
  funding_instruments?: Maybe<Array<Maybe<Funding_Instruments>>>;
};

export type Payment = {
  __typename?: 'Payment';
  id?: Maybe<Scalars['String']>;
  payer?: Maybe<Payer>;
  links?: Maybe<Array<Maybe<Link>>>;
  redirect_urls?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  outcome?: Maybe<Outcome>;
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  id?: Maybe<Scalars['String']>;
  payment_method?: Maybe<Scalars['String']>;
};

export type PicsNew = {
  serie?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type Picture = {
  __typename?: 'Picture';
  serie?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
};

export type PictureNew = {
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PostedBy = {
  __typename?: 'PostedBy';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PostedByNew = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** input de tipo prePublicacion */
export type PrePublicationBo = {
  /** id del libro */
  _id?: Maybe<Scalars['ID']>;
  /** variable que decide si es prepublicacion */
  prePublication?: Maybe<Scalars['Boolean']>;
};

export type Prepublication = {
  __typename?: 'Prepublication';
  launching?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type FlashOffer = {
  __typename?: 'FlashOffer';
  launching?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type PrepublicationBoNew = {
  _id?: Maybe<Scalars['ID']>;
  launching?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type Prices = {
  __typename?: 'Prices';
  buy?: Maybe<Scalars['Float']>;
  sale?: Maybe<Scalars['Float']>;
  clearing?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  ssdFrom?: Maybe<Scalars['Date']>;
  ssdTo?: Maybe<Scalars['Date']>;
  saleSpecialDiscount?: Maybe<Scalars['Float']>;
};

export type PricesNew = {
  buy?: Maybe<Scalars['Float']>;
  sale?: Maybe<Scalars['Float']>;
  clearing?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  saleSpecialDiscount?: Maybe<Scalars['Float']>;
  ssdFrom?: Maybe<Scalars['Date']>;
  ssdTo?: Maybe<Scalars['Date']>;
  priceSignDeal?: Maybe<Scalars['Float']>;
};

export type Property = {
  __typename?: 'Property';
  color?: Maybe<Scalars['String']>;
  finish?: Maybe<Scalars['String']>;
  cuff?: Maybe<Scalars['String']>;
};

export type PropertyNew = {
  color?: Maybe<Scalars['String']>;
  finish?: Maybe<Scalars['String']>;
  cuff?: Maybe<Scalars['String']>;
};

export type PublicRefundData = {
  product?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  cause?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  datePurchase?: Maybe<Scalars['Date']>;
  amount?: Maybe<Scalars['Float']>;
  observations?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type RangePrice = {
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type Refund = {
  __typename?: 'Refund';
  _id?: Maybe<Scalars['ID']>;
  orderId?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  cause?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  datePurchase?: Maybe<Scalars['Date']>;
  dateIssue?: Maybe<Scalars['Date']>;
  dateSolved?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['String']>;
  responsibles?: Maybe<Array<Maybe<Responsible>>>;
  emailText?: Maybe<Scalars['String']>;
};

export type RefundData = {
  orderId?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  cause?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  observations?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  emailText?: Maybe<Scalars['String']>;
};

export type RelatedInfo = {
  __typename?: 'RelatedInfo';
  _id: Scalars['ID'];
  id: Scalars['Int'];
  product_type?: Maybe<Scalars['String']>;
  ean: Scalars['String'];
  title: Scalars['String'];
  priceWithDiscount?: Maybe<Scalars['Float']>;
  prices?: Maybe<Prices>;
  ebookContent?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Responsible = {
  __typename?: 'Responsible';
  _id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type ResultsAggregate = {
  __typename?: 'ResultsAggregate';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  category?: Maybe<Scalars['String']>;
};

export type RootMutation = {
  __typename?: 'RootMutation';
  deleteDocumentCollection?: Maybe<Scalars['Boolean']>;
  deleteDocumentCollectionByEmail?: Maybe<Scalars['Boolean']>;
  saveTagsBO?: Maybe<State>;
  saveResponsibleOrder?: Maybe<Order>;
  saveResponsibleRefund?: Maybe<Refund>;
  saveInternalObservationsOrder?: Maybe<State>;
  saveObservationsOrder?: Maybe<State>;
  refundRequest?: Maybe<State>;
  saveRefundRequest?: Maybe<State>;
  toggleBanner?: Maybe<State>;
  togglePrepublication?: Maybe<State>;
  toggleStar?: Maybe<State>;
  toggleFeatured?: Maybe<State>;
  toggleIs_new?: Maybe<State>;
  removeBanner?: Maybe<State>;
  suscriptionRequest?: Maybe<Suscription>;
  saveShoppingCart?: Maybe<ShoppingCart>;
  saveShippingData?: Maybe<Shipping>;
  deleteClient?: Maybe<State>;
  deleteShipping?: Maybe<State>;
  saveNewsletterSignUp?: Maybe<NewsletterSignUp>;
  cancelOrder?: Maybe<Order>;
  cancelOrderProduct?: Maybe<Order>;
  generateInvoice?: Maybe<Order>;
  saveSysvar?: Maybe<Sysvar>;
  saveSysvars?: Maybe<State>;
  generateTags?: Maybe<State>;
  couponRedeem?: Maybe<Coupon>;
  deleteCoupon?: Maybe<State>;
  saveCoupon?: Maybe<Coupon>;
  saveCoupons?: Maybe<State>;
  signUp?: Maybe<Auth>;
  login?: Maybe<Auth>;
  adminLogin?: Maybe<Auth>;
  updateBook?: Maybe<Book>;
  addToBasket?: Maybe<User>;
  removeToBasket?: Maybe<User>;
  modifyToBasket?: Maybe<User>;
  emptyBasket?: Maybe<User>;
  updateUser?: Maybe<User>;
  updateUserBO?: Maybe<User>;
  deleteUser?: Maybe<DeletedUser>;
  deleteUserCompletely?: Maybe<DeletedUser>;
  updateDirection?: Maybe<User>;
  updateBillingData?: Maybe<User>;
  deleteAddress?: Maybe<User>;
  deleteBillingData?: Maybe<User>;
  newBook?: Maybe<State>;
  newBookAll?: Maybe<State>;
  updateBooks?: Maybe<State>;
  newMagazine?: Maybe<Magazine>;
  newOrder?: Maybe<Order>;
  newEbookOrder?: Maybe<Order>;
  /** processManuallyPaypalOrder(payerID: String!, transactionId: String!): State */
  enableShoppingCartToUser?: Maybe<State>;
  createPayment?: Maybe<Payment>;
  updateProduct?: Maybe<Book>;
  insertClick?: Maybe<Book>;
  insertRecentView?: Maybe<User>;
  insertClickID?: Maybe<Book>;
  deleteClick?: Maybe<Book>;
  get3DToken?: Maybe<Token>;
  getRetrieveStripeSession?: Maybe<SessionStripe>;
  createStripeCheckoutSession?: Maybe<Token>;
  updateDataFromSuccessStripePayment?: Maybe<ConfirmSaveCard>;
  executePayment?: Maybe<Payment>;
  setAreaOfInterest?: Maybe<User>;
  subscribeToNewsletter?: Maybe<User>;
  packSuscriptionRequest?: Maybe<User>;
  rememberPassword?: Maybe<State>;
  checkShoppingCart?: Maybe<Array<Maybe<User>>>;
  optInSuscription?: Maybe<State>;
  optOutSuscription?: Maybe<State>;
  cancelNewsletter?: Maybe<StateFeedback>;
  cancelNewsletterBO?: Maybe<NewsletterSignUp>;
  sendMail?: Maybe<State>;
  singleUpload?: Maybe<UploadFeedback>;
  saveBillPdfGridFS?: Maybe<UploadFeedback>;
  testLogs?: Maybe<State>;
  saveFiles?: Maybe<FileProduct>;
  saveFilesMany?: Maybe<FileProduct>;
  newTag?: Maybe<State>;
  newArea?: Maybe<State>;
  updateImgsBook?: Maybe<State>;
  reimportBook?: Maybe<State>;
  reasignImg?: Maybe<State>;
  reasignYear?: Maybe<State>;
  createTagAllBooks?: Maybe<State>;
  fixOrdersAddress?: Maybe<State>;
  setPrePublication?: Maybe<State>;
};

export type RootMutationDeleteDocumentCollectionArgs = {
  collection?: Maybe<Scalars['String']>;
  idDocument?: Maybe<Scalars['ID']>;
};

export type RootMutationDeleteDocumentCollectionByEmailArgs = {
  collection?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type RootMutationSaveTagsBoArgs = {
  data: TagsBoData;
};

export type RootMutationSaveResponsibleOrderArgs = {
  data: OrderResponsibles;
};

export type RootMutationSaveResponsibleRefundArgs = {
  data: OrderResponsibles;
};

export type RootMutationSaveInternalObservationsOrderArgs = {
  data: OrderInternalObservations;
};

export type RootMutationSaveObservationsOrderArgs = {
  data: OrderObservations;
};

export type RootMutationRefundRequestArgs = {
  data: PublicRefundData;
};

export type RootMutationSaveRefundRequestArgs = {
  data: RefundData;
};

export type RootMutationToggleBannerArgs = {
  data?: Maybe<BannerBoNew>;
};

export type RootMutationTogglePrepublicationArgs = {
  data?: Maybe<PrepublicationBoNew>;
};

export type RootMutationToggleStarArgs = {
  data?: Maybe<StarBoNew>;
};

export type RootMutationToggleFeaturedArgs = {
  data?: Maybe<FeaturedBo>;
};

export type RootMutationToggleIs_NewArgs = {
  data?: Maybe<Is_NewBo>;
};

export type RootMutationRemoveBannerArgs = {
  _id: Scalars['ID'];
};

export type RootMutationSuscriptionRequestArgs = {
  user_id: Scalars['ID'];
  pack_id: Scalars['String'];
  message: Scalars['String'];
};

export type RootMutationSaveShoppingCartArgs = {
  data: ShoppingCartData;
};

export type RootMutationSaveShippingDataArgs = {
  data: ShippingData;
};

export type RootMutationDeleteClientArgs = {
  id: Scalars['String'];
};

export type RootMutationDeleteShippingArgs = {
  id: Scalars['String'];
};

export type RootMutationSaveNewsletterSignUpArgs = {
  data: NlSupData;
};

export type RootMutationCancelOrderArgs = {
  data: CancelOrder;
};

export type RootMutationCancelOrderProductArgs = {
  data: CancelOrderProduct;
};

export type RootMutationGenerateInvoiceArgs = {
  data: InvoiceData;
};

export type RootMutationSaveSysvarArgs = {
  data: SysvarData;
};

export type RootMutationSaveSysvarsArgs = {
  data: Array<Maybe<SysvarData>>;
};

export type RootMutationGenerateTagsArgs = {
  bookEan: Scalars['String'];
};

export type RootMutationCouponRedeemArgs = {
  coupon: Scalars['String'];
  user: Scalars['String'];
};

export type RootMutationDeleteCouponArgs = {
  id: Scalars['String'];
};

export type RootMutationSaveCouponArgs = {
  data: CouponData;
};

export type RootMutationSaveCouponsArgs = {
  data: Array<Maybe<CouponData>>;
};

export type RootMutationSignUpArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  profile?: Maybe<UserProfileNew>;
};

export type RootMutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type RootMutationAdminLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type RootMutationUpdateBookArgs = {
  id: Scalars['Int'];
  changes: Scalars['String'];
};

export type RootMutationAddToBasketArgs = {
  idUser: Scalars['ID'];
  idProduct: Scalars['String'];
  ean: Scalars['String'];
  units?: Maybe<Scalars['Int']>;
};

export type RootMutationRemoveToBasketArgs = {
  idUser: Scalars['ID'];
  idProduct: Scalars['String'];
};

export type RootMutationModifyToBasketArgs = {
  idUser: Scalars['ID'];
  idProduct: Scalars['String'];
  operation?: Maybe<Scalars['Boolean']>;
};

export type RootMutationEmptyBasketArgs = {
  idUser: Scalars['ID'];
};

export type RootMutationUpdateUserArgs = {
  _id: Scalars['ID'];
  newUserVar: UserNew;
};

export type RootMutationUpdateUserBoArgs = {
  newUserVar: UserNew;
};

export type RootMutationDeleteUserArgs = {
  _id: Scalars['ID'];
};

export type RootMutationDeleteUserCompletelyArgs = {
  _id: Scalars['ID'];
};

export type RootMutationUpdateDirectionArgs = {
  _id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  newAddressVar: NewAddress;
};

export type RootMutationUpdateBillingDataArgs = {
  _id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  newAddressVar: NewAddress;
};

export type RootMutationDeleteAddressArgs = {
  _id: Scalars['ID'];
  index: Scalars['Int'];
};

export type RootMutationDeleteBillingDataArgs = {
  _id: Scalars['ID'];
  index: Scalars['Int'];
};

export type RootMutationNewBookArgs = {
  id: Scalars['Int'];
  newBookVar: BookNew;
  microserviceSecret: Scalars['String'];
};

export type RootMutationNewBookAllArgs = {
  id?: Maybe<Scalars['Int']>;
  newBookVar: Array<Maybe<BookNew>>;
  microserviceSecret: Scalars['String'];
};

export type RootMutationUpdateBooksArgs = {
  eans: Array<Maybe<Scalars['String']>>;
  microserviceSecret: Scalars['String'];
};

export type RootMutationNewMagazineArgs = {
  newMagazineVar?: Maybe<MagazineNew>;
  microserviceSecret: Scalars['String'];
};

export type RootMutationNewOrderArgs = {
  newOrderVar?: Maybe<OrderNew>;
};

export type RootMutationNewEbookOrderArgs = {
  newOrderVar?: Maybe<OrderNew>;
};

export type RootMutationEnableShoppingCartToUserArgs = {
  _id: Scalars['ID'];
};

export type RootMutationCreatePaymentArgs = {
  method?: Maybe<Scalars['String']>;
  items: Array<Maybe<OrderItemsNew>>;
  total: Scalars['Float'];
  token?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  coupon?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['ID']>;
  state_order?: Maybe<Scalars['String']>;
  amountTransport?: Maybe<Scalars['Float']>;
  orderSequence: Scalars['Int'];
  isEbookCart?: Maybe<Scalars['Boolean']>;
  paymentIntentId?: Maybe<Scalars['String']>;
};

export type RootMutationUpdateProductArgs = {
  data: UpdateProduct;
};

export type RootMutationInsertClickArgs = {
  eanProduct?: Maybe<Scalars['String']>;
};

export type RootMutationInsertRecentViewArgs = {
  eanProduct?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type RootMutationInsertClickIdArgs = {
  idProduct?: Maybe<Scalars['String']>;
};

export type RootMutationDeleteClickArgs = {
  eanProduct?: Maybe<Scalars['String']>;
};

export type RootMutationGetRetrieveStripeSessionArgs = {
  session_id?: Maybe<Scalars['String']>;
};

export type RootMutationCreateStripeCheckoutSessionArgs = {
  amount: Scalars['Float'];
};

export type RootMutationUpdateDataFromSuccessStripePaymentArgs = {
  items: Array<Maybe<OrderItemsNew>>;
  total: Scalars['Float'];
  token?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  coupon?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['ID']>;
  state_order?: Maybe<Scalars['String']>;
  amountTransport?: Maybe<Scalars['Float']>;
  orderSequence: Scalars['Int'];
  isEbookCart?: Maybe<Scalars['Boolean']>;
  paymenIntentId: Scalars['String'];
};

export type RootMutationExecutePaymentArgs = {
  paymentId?: Maybe<Scalars['String']>;
  payerID?: Maybe<Scalars['String']>;
};

export type RootMutationSetAreaOfInterestArgs = {
  _id?: Maybe<Scalars['ID']>;
  numberAreaOfInterest?: Maybe<Scalars['Int']>;
};

export type RootMutationSubscribeToNewsletterArgs = {
  _id?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  areaOfInterest?: Maybe<Array<Maybe<Scalars['Int']>>>;
  active?: Maybe<Scalars['Boolean']>;
};

export type RootMutationPackSuscriptionRequestArgs = {
  _id?: Maybe<Scalars['ID']>;
  id_pack?: Maybe<Scalars['Int']>;
};

export type RootMutationRememberPasswordArgs = {
  email: Scalars['String'];
};

export type RootMutationCheckShoppingCartArgs = {
  microserviceSecret: Scalars['String'];
};

export type RootMutationOptInSuscriptionArgs = {
  _id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};

export type RootMutationOptOutSuscriptionArgs = {
  _id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};

export type RootMutationCancelNewsletterArgs = {
  email?: Maybe<Scalars['String']>;
};

export type RootMutationCancelNewsletterBoArgs = {
  email?: Maybe<Scalars['String']>;
};

export type RootMutationSendMailArgs = {
  email: Scalars['String'];
  text1: Scalars['String'];
  text2: Scalars['String'];
  subject: Scalars['String'];
};

export type RootMutationSingleUploadArgs = {
  name: Scalars['String'];
  type: Scalars['String'];
  orderId: Scalars['String'];
  isInvoice?: Maybe<Scalars['Boolean']>;
  imgString: Scalars['String'];
};

export type RootMutationSaveBillPdfGridFsArgs = {
  orderId: Scalars['ID'];
  isInvoice?: Maybe<Scalars['Boolean']>;
};

export type RootMutationSaveFilesArgs = {
  newFile: FileProductNew;
  microserviceSecret: Scalars['String'];
};

export type RootMutationSaveFilesManyArgs = {
  newFiles: Array<Maybe<FileProductNew>>;
  microserviceSecret: Scalars['String'];
};

export type RootMutationNewTagArgs = {
  newTagVar: Array<Maybe<TagNew>>;
  microserviceSecret: Scalars['String'];
};

export type RootMutationNewAreaArgs = {
  newAreaVar: Array<Maybe<AreaNew>>;
  microserviceSecret: Scalars['String'];
};

export type RootMutationUpdateImgsBookArgs = {
  ean?: Maybe<Scalars['String']>;
};

export type RootMutationReimportBookArgs = {
  ean: Scalars['String'];
};

export type RootMutationCreateTagAllBooksArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type RootMutationSetPrePublicationArgs = {
  data?: Maybe<PrePublicationBo>;
};

export type RootQuery = {
  __typename?: 'RootQuery';
  me?: Maybe<User>;
  getUserAreas?: Maybe<NewsletterStatus>;
  getAreaName?: Maybe<Area>;
  getArea?: Maybe<Area>;
  getAreasFromNewsletter?: Maybe<Array<Maybe<Area>>>;
  couponsCount?: Maybe<Scalars['Int']>;
  couponById?: Maybe<Coupon>;
  coupon?: Maybe<Coupon>;
  coupons?: Maybe<Array<Maybe<Coupon>>>;
  shoppingCartsCount?: Maybe<Scalars['Int']>;
  shoppingCartById?: Maybe<ShoppingCart>;
  shoppingCarts?: Maybe<Array<Maybe<ShoppingCart>>>;
  sysvarsCount?: Maybe<Scalars['Int']>;
  sysvarById?: Maybe<Sysvar>;
  sysvars?: Maybe<Array<Maybe<Sysvar>>>;
  tagsBOCount?: Maybe<Scalars['Int']>;
  tagsBOById?: Maybe<TagsBo>;
  tagsBO?: Maybe<Array<Maybe<TagsBo>>>;
  productsWithBannersCount?: Maybe<Scalars['Int']>;
  productsWithPrepublicationCount?: Maybe<Scalars['Int']>;
  productsWithStarCount?: Maybe<Scalars['Int']>;
  productWithBannersById?: Maybe<Book>;
  productFeaturedById?: Maybe<Book>;
  productsWithBanners?: Maybe<Array<Maybe<Book>>>;
  productWithPrepublicationById?: Maybe<Book>;
  productWithStarById?: Maybe<Book>;
  productsWithPrepublication?: Maybe<Array<Maybe<Book>>>;
  productsWithStar?: Maybe<Array<Maybe<Book>>>;
  productIs_newById?: Maybe<Book>;
  productsNovelty?: Maybe<Array<Maybe<Book>>>;
  productsNews?: Maybe<Array<Maybe<Book>>>;
  newsletterSignUpsCount?: Maybe<Scalars['Int']>;
  newsletterSignUpByArea?: Maybe<Array<Maybe<NewsletterSignUp>>>;
  newsletterSignUpById?: Maybe<NewsletterSignUp>;
  newsletterSignUps?: Maybe<Array<Maybe<NewsletterSignUp>>>;
  clientsCount?: Maybe<Scalars['Int']>;
  clientById?: Maybe<User>;
  clients?: Maybe<Array<Maybe<User>>>;
  shippingsCount?: Maybe<Scalars['Int']>;
  shippingById?: Maybe<Shipping>;
  shippings?: Maybe<Array<Maybe<Shipping>>>;
  shippingAPI?: Maybe<Array<Maybe<ShippingApiData>>>;
  suscriptionsCount?: Maybe<Scalars['Int']>;
  suscriptionById?: Maybe<Suscription>;
  suscriptions?: Maybe<Array<Maybe<Suscription>>>;
  couponCheck?: Maybe<Coupon>;
  booksCount?: Maybe<Scalars['Int']>;
  book?: Maybe<Book>;
  bookById?: Maybe<Book>;
  bookByIdBBDD?: Maybe<Book>;
  getAreas?: Maybe<Array<Maybe<Area>>>;
  getTags?: Maybe<Array<Maybe<Tag>>>;
  getTagsCustom?: Maybe<Array<Maybe<TagSearch>>>;
  getTagsSearch?: Maybe<Array<Maybe<TagSearch>>>;
  getTagsSearchCount?: Maybe<Scalars['Int']>;
  books?: Maybe<Array<Maybe<Book>>>;
  aggregate?: Maybe<Aggregate>;
  magazine?: Maybe<Magazine>;
  magazineById?: Maybe<Coupon>;
  magazines?: Maybe<Array<Maybe<Magazine>>>;
  magazinesCount?: Maybe<Scalars['Int']>;
  authors?: Maybe<Array<Maybe<Author>>>;
  author?: Maybe<Author>;
  user?: Maybe<User>;
  usersCount?: Maybe<Scalars['Int']>;
  userById?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  packs?: Maybe<Array<Maybe<MagazinePacks>>>;
  pack?: Maybe<MagazinePacks>;
  refundById?: Maybe<Refund>;
  refundsCount?: Maybe<Scalars['Int']>;
  refundsBOCount?: Maybe<Scalars['Int']>;
  refundsBO?: Maybe<Array<Maybe<Refund>>>;
  ordersBOCount?: Maybe<Scalars['Int']>;
  orderById?: Maybe<Order>;
  ordersBO?: Maybe<Array<Maybe<Order>>>;
  orders?: Maybe<Array<Maybe<Order>>>;
  getPendingOrder?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Order>;
  journalsCount?: Maybe<Scalars['Int']>;
  journalById?: Maybe<MagazineJournals>;
  journals?: Maybe<Array<Maybe<MagazineJournals>>>;
  journalsB?: Maybe<Array<Maybe<MagazineJournals>>>;
  journal?: Maybe<MagazineJournals>;
  getResultsPredictive?: Maybe<Array<Maybe<Book>>>;
  getAreasPredictive?: Maybe<Array<Maybe<Area>>>;
  getMagazinesPredictive?: Maybe<Array<Maybe<MagazineJournals>>>;
  tags?: Maybe<Array<Maybe<TagsResult>>>;
  resultsPredictive?: Maybe<Array<Maybe<TagsResult>>>;
  relatedProducts?: Maybe<Array<Maybe<Book>>>;
  getRecentViews?: Maybe<Array<Maybe<Book>>>;
  getBestSellers?: Maybe<Array<Maybe<Book>>>;
  getPrePublication?: Maybe<Array<Maybe<Book>>>;
  getImportantProduct?: Maybe<Array<Maybe<Book>>>;
  getRateRequest?: Maybe<AmountDelivery>;
  filesById?: Maybe<File>;
  filesCount?: Maybe<Scalars['Int']>;
  files?: Maybe<Array<Maybe<FileProduct>>>;
  checkStockItem?: Maybe<Array<Maybe<Book>>>;
  booksFeatured?: Maybe<Array<Maybe<Book>>>;
  booksNews?: Maybe<Array<Maybe<Book>>>;
};

export type RootQueryMeArgs = {
  state?: Maybe<Scalars['Float']>;
};

export type RootQueryGetUserAreasArgs = {
  email?: Maybe<Scalars['String']>;
};

export type RootQueryGetAreaNameArgs = {
  id: Scalars['Int'];
};

export type RootQueryGetAreaArgs = {
  nameEsFriendly: Scalars['String'];
};

export type RootQueryGetAreasFromNewsletterArgs = {
  email?: Maybe<Scalars['String']>;
};

export type RootQueryCouponsCountArgs = {
  giftcard: Scalars['Boolean'];
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryCouponByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryCouponArgs = {
  id: Scalars['String'];
};

export type RootQueryCouponsArgs = {
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  coupon?: Maybe<Scalars['String']>;
  giftcard?: Maybe<Scalars['Boolean']>;
  accountCode?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryShoppingCartsCountArgs = {
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryShoppingCartByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryShoppingCartsArgs = {
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQuerySysvarsCountArgs = {
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQuerySysvarByIdArgs = {
  _id: Scalars['String'];
};

export type RootQuerySysvarsArgs = {
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryTagsBoCountArgs = {
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryTagsBoByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryTagsBoArgs = {
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryProductsWithBannersCountArgs = {
  banner_status?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasBanner?: Maybe<Scalars['String']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryProductsWithPrepublicationCountArgs = {
  product_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  prepublication?: Maybe<Scalars['String']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryProductsWithStarCountArgs = {
  product_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  prepublication?: Maybe<Scalars['String']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryProductWithBannersByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryProductFeaturedByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryProductsWithBannersArgs = {
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  banner_status?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasBanner?: Maybe<Scalars['String']>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
  unpublish?: Maybe<Scalars['Boolean']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryProductWithPrepublicationByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryProductWithStarByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryProductsWithPrepublicationArgs = {
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  prepublication?: Maybe<Scalars['String']>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
  unpublish?: Maybe<Scalars['Boolean']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryProductsWithStarArgs = {
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  prepublication?: Maybe<Scalars['String']>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
  unpublish?: Maybe<Scalars['Boolean']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryProductIs_NewByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryProductsNoveltyArgs = {
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  banner_status?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  featured?: Maybe<Scalars['String']>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
  unpublish?: Maybe<Scalars['Boolean']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryProductsNewsArgs = {
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  banner_status?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  is_new?: Maybe<Scalars['String']>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
  unpublish?: Maybe<Scalars['Boolean']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryNewsletterSignUpsCountArgs = {
  status?: Maybe<Scalars['String']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryNewsletterSignUpByAreaArgs = {
  areas?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type RootQueryNewsletterSignUpByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryNewsletterSignUpsArgs = {
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryClientsCountArgs = {
  disabled?: Maybe<Scalars['String']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryClientByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryClientsArgs = {
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  disabled?: Maybe<Scalars['String']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryShippingsCountArgs = {
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryShippingByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryShippingsArgs = {
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryShippingApiArgs = {
  amount: Scalars['Float'];
  province: Scalars['String'];
  country: Scalars['String'];
  zip?: Maybe<Scalars['String']>;
};

export type RootQuerySuscriptionsCountArgs = {
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQuerySuscriptionByIdArgs = {
  _id: Scalars['String'];
};

export type RootQuerySuscriptionsArgs = {
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryCouponCheckArgs = {
  coupon: Scalars['String'];
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryBooksCountArgs = {
  author?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  lang?: Maybe<Array<Maybe<Scalars['String']>>>;
  rangePrice?: Maybe<RangePrice>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
  tags?: Maybe<Array<Maybe<TagsInput>>>;
  search?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryBookArgs = {
  ean: Scalars['String'];
};

export type RootQueryBookByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryBookByIdBbddArgs = {
  id: Scalars['String'];
};

export type RootQueryGetTagsArgs = {
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type RootQueryGetTagsCustomArgs = {
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type RootQueryGetTagsSearchArgs = {
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type RootQueryGetTagsSearchCountArgs = {
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryBooksArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  author?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  lang?: Maybe<Array<Maybe<Scalars['String']>>>;
  rangePrice?: Maybe<RangePrice>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
  areas_of_interest_text?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<TagsInput>>>;
  search?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  searchGeneral?: Maybe<Scalars['String']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderByScore?: Maybe<Scalars['Boolean']>;
  prox?: Maybe<Scalars['Boolean']>;
  area_prox?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  is_new?: Maybe<Scalars['Boolean']>;
};

export type RootQueryAggregateArgs = {
  collectionName: Scalars['String'];
  group: Scalars['String'];
  total?: Maybe<Scalars['String']>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  lang?: Maybe<Array<Maybe<Scalars['String']>>>;
  rangePrice?: Maybe<RangePrice>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
  tags?: Maybe<Array<Maybe<TagsInput>>>;
  type?: Maybe<Scalars['String']>;
  searchGeneral?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  version?: Maybe<Array<Maybe<Scalars['String']>>>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
  prox?: Maybe<Scalars['Boolean']>;
  area_prox?: Maybe<Scalars['String']>;
};

export type RootQueryMagazineArgs = {
  _id: Scalars['ID'];
};

export type RootQueryMagazineByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryMagazinesArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type RootQueryAuthorsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type RootQueryAuthorArgs = {
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type RootQueryUserArgs = {
  _id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};

export type RootQueryUsersCountArgs = {
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryUserByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryUsersArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryPacksArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type RootQueryPackArgs = {
  id_pack: Scalars['Int'];
};

export type RootQueryRefundByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryRefundsBoCountArgs = {
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
  refund_status?: Maybe<Scalars['String']>;
};

export type RootQueryRefundsBoArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
  refund_status?: Maybe<Scalars['String']>;
};

export type RootQueryOrdersBoCountArgs = {
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  methodPayment?: Maybe<Scalars['String']>;
};

export type RootQueryOrderByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryOrdersBoArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  methodPayment?: Maybe<Scalars['String']>;
};

export type RootQueryOrdersArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['ID']>;
  _id?: Maybe<Scalars['ID']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  updateGreaterThan?: Maybe<Scalars['String']>;
};

export type RootQueryOrderArgs = {
  _id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderSequence?: Maybe<Scalars['Int']>;
};

export type RootQueryJournalsCountArgs = {
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryJournalByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryJournalsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  lang?: Maybe<Array<Maybe<Scalars['String']>>>;
  version?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  searchGeneral?: Maybe<Scalars['String']>;
};

export type RootQueryJournalsBArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
  searchGeneralBO?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryJournalArgs = {
  id_journal?: Maybe<Scalars['Int']>;
  issn?: Maybe<Scalars['String']>;
};

export type RootQueryGetResultsPredictiveArgs = {
  search: Scalars['String'];
  limit: Scalars['Int'];
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type RootQueryGetAreasPredictiveArgs = {
  search: Scalars['String'];
  limit: Scalars['Int'];
};

export type RootQueryGetMagazinesPredictiveArgs = {
  search: Scalars['String'];
  limit: Scalars['Int'];
};

export type RootQueryTagsArgs = {
  searchTag: Scalars['String'];
  limit: Scalars['Int'];
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryResultsPredictiveArgs = {
  searchTag: Scalars['String'];
  limit: Scalars['Int'];
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RootQueryRelatedProductsArgs = {
  idUser?: Maybe<Scalars['String']>;
  eanProduct: Scalars['String'];
};

export type RootQueryGetRecentViewsArgs = {
  idUser?: Maybe<Scalars['String']>;
};

export type RootQueryGetBestSellersArgs = {
  product_type?: Maybe<Scalars['String']>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type RootQueryGetPrePublicationArgs = {
  product_type?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['Int']>;
};

export type RootQueryGetImportantProductArgs = {
  product_type?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['Int']>;
};

export type RootQueryGetRateRequestArgs = {
  idUser?: Maybe<Scalars['ID']>;
  destinationAddress?: Maybe<OrderDeliveryAddressNew>;
  package?: Maybe<NewPackage>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type RootQueryFilesByIdArgs = {
  _id: Scalars['String'];
};

export type RootQueryFilesCountArgs = {
  id: Scalars['String'];
};

export type RootQueryFilesArgs = {
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  product_contains?: Maybe<Scalars['String']>;
};

export type RootQueryCheckStockItemArgs = {
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ScheduledNew = {
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type SessionStripe = {
  __typename?: 'SessionStripe';
  id?: Maybe<Scalars['String']>;
  cancel_url?: Maybe<Scalars['String']>;
  success_url?: Maybe<Scalars['String']>;
  payment_status?: Maybe<Scalars['String']>;
  payment_intent?: Maybe<PaymentIntent>;
  customer_details?: Maybe<CustomerDetails>;
  metadata?: Maybe<MetadataOutputStripe>;
  client_secret?: Maybe<Scalars['String']>;
};

export type Shipping = {
  __typename?: 'Shipping';
  _id?: Maybe<Scalars['ID']>;
  ruleName?: Maybe<Scalars['String']>;
  dateSince?: Maybe<Scalars['Date']>;
  dateUntil?: Maybe<Scalars['Date']>;
  orderAmount?: Maybe<Scalars['Float']>;
  shippingCost?: Maybe<Scalars['Float']>;
  shippingCond?: Maybe<Scalars['String']>;
  carriers?: Maybe<Array<Maybe<Carrier>>>;
  createdAt?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type ShippingApiData = {
  __typename?: 'ShippingAPIData';
  company?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  days?: Maybe<Scalars['String']>;
};

export type ShippingData = {
  _id?: Maybe<Scalars['ID']>;
  ruleName?: Maybe<Scalars['String']>;
  dateSince?: Maybe<Scalars['Date']>;
  dateUntil?: Maybe<Scalars['Date']>;
  orderAmount?: Maybe<Scalars['Float']>;
  shippingCost: Scalars['Float'];
  shippingCond?: Maybe<Scalars['String']>;
  carriers?: Maybe<Array<Maybe<CarrierType>>>;
  active?: Maybe<Scalars['Boolean']>;
};

export type ShoppingAddress = {
  __typename?: 'ShoppingAddress';
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type ShoppingCart = {
  __typename?: 'ShoppingCart';
  _id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  user?: Maybe<UserSc>;
  amountIVA?: Maybe<Scalars['Float']>;
  amountWithoutIVA?: Maybe<Scalars['Float']>;
  transport?: Maybe<Scalars['Boolean']>;
  amountTransport?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  methodPayment?: Maybe<Scalars['String']>;
  fullNameUser?: Maybe<Scalars['String']>;
  fullNameUserClean?: Maybe<Scalars['String']>;
  IVAtype?: Maybe<Array<Maybe<IvAtype>>>;
  legalNameFiscalData?: Maybe<Scalars['String']>;
  fiscalDoc?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<OrderItems>>>;
  state_order?: Maybe<Scalars['String']>;
  amountDiscount?: Maybe<Scalars['String']>;
  discountIdCode?: Maybe<Scalars['String']>;
  discountIdCodeWork?: Maybe<Scalars['String']>;
  confirmDate?: Maybe<Scalars['Date']>;
  autorizationCode?: Maybe<Scalars['String']>;
  order_multiple?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type ShoppingCartData = {
  _id?: Maybe<Scalars['ID']>;
  user?: Maybe<UserScNew>;
  amountIVA?: Maybe<Scalars['Float']>;
  amountWithoutIVA: Scalars['Float'];
  amount: Scalars['Float'];
  IVAtype?: Maybe<Array<Maybe<IvAtypeNew>>>;
  items?: Maybe<Array<Maybe<OrderItemsNew>>>;
  order_multiple?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type Star = {
  __typename?: 'Star';
  active?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type StarBoNew = {
  _id?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type State = {
  __typename?: 'State';
  OK?: Maybe<Scalars['Boolean']>;
};

export type StateFeedback = {
  __typename?: 'StateFeedback';
  OK?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type Suscription = {
  __typename?: 'Suscription';
  _id?: Maybe<Scalars['ID']>;
  user_id?: Maybe<Scalars['String']>;
  pack_id?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  message: Scalars['String'];
  pack?: Maybe<Array<Maybe<MagazinePacks>>>;
  createdAt?: Maybe<Scalars['String']>;
};

export type SuscriptionBook = {
  __typename?: 'SuscriptionBook';
  email?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type Sysvar = {
  __typename?: 'Sysvar';
  _id?: Maybe<Scalars['ID']>;
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SysvarData = {
  _id?: Maybe<Scalars['ID']>;
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Tag = {
  __typename?: 'Tag';
  _id?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['Int']>;
  tagEs?: Maybe<Scalars['String']>;
};

export type TagBookNew = {
  category?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TagNew = {
  tagEs?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type TagSearch = {
  __typename?: 'TagSearch';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  nameFriendly?: Maybe<Scalars['String']>;
};

export type Tags = {
  __typename?: 'Tags';
  category?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TagsBo = {
  __typename?: 'TagsBO';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  nameFriendly?: Maybe<Scalars['String']>;
  productType?: Maybe<Array<Maybe<Scalars['String']>>>;
  length?: Maybe<Scalars['Int']>;
};

export type TagsBoData = {
  _id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  length: Scalars['Int'];
  productType?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TagsInput = {
  prop?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TagsResult = {
  __typename?: 'TagsResult';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  tagEs?: Maybe<Scalars['String']>;
};

export type Token = {
  __typename?: 'Token';
  token?: Maybe<Scalars['String']>;
};

export type TopicData = {
  __typename?: 'TopicData';
  _id?: Maybe<Scalars['ID']>;
  nameEs?: Maybe<Scalars['String']>;
};

export type UniversalSearch = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateProduct = {
  _id: Scalars['ID'];
  banner?: Maybe<BannerInfoNew>;
  discount?: Maybe<DiscountInfoNew>;
};

export type UploadFeedback = {
  __typename?: 'UploadFeedback';
  ok: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  gridId?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['ID']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  profile?: Maybe<UserProfile>;
  newsletterActive?: Maybe<Scalars['Boolean']>;
  bestCoupon?: Maybe<Coupon>;
  lastViews?: Maybe<Array<Maybe<UserRelated>>>;
};

export type UserEmails = {
  __typename?: 'UserEmails';
  address?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type UserNew = {
  _id?: Maybe<Scalars['ID']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  profile?: Maybe<UserProfileNew>;
  removalDate?: Maybe<Scalars['Date']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  alias?: Maybe<Scalars['String']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  NIF?: Maybe<Scalars['String']>;
  basketUpdated?: Maybe<Scalars['String']>;
  newsletter?: Maybe<Newsletter>;
  basketNotified?: Maybe<Scalars['Boolean']>;
  basket?: Maybe<Array<Maybe<Basket>>>;
  ebooks?: Maybe<Array<Maybe<Ebooks>>>;
  shoppingAddress?: Maybe<Array<Maybe<UserProfileShoppingAddress>>>;
  shopping_default?: Maybe<Scalars['Int']>;
  billingData?: Maybe<Array<Maybe<UserProfileBillingData>>>;
  billing_default?: Maybe<Scalars['Int']>;
  areaOfInterest?: Maybe<Scalars['Int']>;
  couponsUsed?: Maybe<Array<Maybe<CouponsUsed>>>;
  customerId?: Maybe<Scalars['String']>;
};

export type UserProfileBillingData = {
  __typename?: 'UserProfileBillingData';
  address?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  cif?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
};

export type UserProfileNew = {
  alias?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  NIF?: Maybe<Scalars['String']>;
  basketUpdated?: Maybe<Scalars['String']>;
  basketNotified?: Maybe<Scalars['Boolean']>;
  shopping_default?: Maybe<Scalars['Int']>;
  basket?: Maybe<Array<Maybe<BasketNew>>>;
  shoppingAddress?: Maybe<Array<Maybe<NewAddress>>>;
  billingData?: Maybe<Array<Maybe<NewAddress>>>;
};

export type UserProfileShoppingAddress = {
  __typename?: 'UserProfileShoppingAddress';
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  cif?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  isOutUE?: Maybe<Scalars['Boolean']>;
};

export type UserRelated = {
  __typename?: 'UserRelated';
  ean?: Maybe<Scalars['String']>;
  dateAdded?: Maybe<Scalars['String']>;
};

export type UserSc = {
  __typename?: 'UserSc';
  _id?: Maybe<Scalars['ID']>;
  alias?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  NIF?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  shoppingAddress?: Maybe<Array<Maybe<ShoppingAddress>>>;
};

export type UserScNew = {
  _id?: Maybe<Scalars['ID']>;
  alias?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  shoppingAddress?: Maybe<Array<Maybe<NewAddress>>>;
  billingData?: Maybe<Array<Maybe<NewAddress>>>;
};

export type Variant = {
  __typename?: 'Variant';
  group_id?: Maybe<Scalars['Int']>;
  properties?: Maybe<Array<Maybe<Property>>>;
};

export type VariantNew = {
  group_id?: Maybe<Scalars['Int']>;
  properties?: Maybe<Array<Maybe<PropertyNew>>>;
};

export type Vat = {
  __typename?: 'Vat';
  buy: Scalars['Float'];
  sale: Scalars['Float'];
};

export type VatNew = {
  buy?: Maybe<Scalars['Float']>;
  sale: Scalars['Float'];
};

export type NewPackage = {
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type NlSupData = {
  _id?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  topics: Array<Maybe<Scalars['String']>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  active?: Maybe<Scalars['Boolean']>;
  idUserOld?: Maybe<Scalars['Int']>;
  fbaja?: Maybe<Scalars['Date']>;
  falta?: Maybe<Scalars['Date']>;
  fupdate?: Maybe<Scalars['Date']>;
};

export type UpdateDirectionMutationVariables = Exact<{
  _id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  newAddressVar: NewAddress;
}>;

export type UpdateDirectionMutation = { __typename?: 'RootMutation' } & {
  updateDirection?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id' | 'email'> & {
        profile?: Maybe<
          { __typename?: 'UserProfile' } & Pick<
            UserProfile,
            | 'alias'
            | 'firstName'
            | 'lastName'
            | 'phone'
            | 'mobile'
            | 'areaOfInterest'
            | 'shopping_default'
          > & {
              basket?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Basket' } & Pick<Basket, '_id' | 'ean' | 'units'> & {
                        book?: Maybe<
                          { __typename?: 'Book' } & Pick<
                            Book,
                            'priceWithDiscount' | '_id' | 'title'
                          >
                        >;
                      }
                  >
                >
              >;
              shoppingAddress?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'UserProfileShoppingAddress' } & Pick<
                      UserProfileShoppingAddress,
                      | 'name'
                      | 'address'
                      | 'route'
                      | 'streetNumber'
                      | 'floor'
                      | 'postalCode'
                      | 'locality'
                      | 'province'
                      | 'country'
                      | 'comment'
                      | 'deliverTo'
                      | 'isOutUE'
                      | 'countryCode'
                      | 'cif'
                      | 'businessName'
                    > & {
                        location?: Maybe<
                          { __typename?: 'Location' } & Pick<Location, 'lat' | 'lng'>
                        >;
                      }
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type UpdateBillingDataMutationVariables = Exact<{
  _id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  newAddressVar: NewAddress;
}>;

export type UpdateBillingDataMutation = { __typename?: 'RootMutation' } & {
  updateBillingData?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id' | 'email'> & {
        profile?: Maybe<
          { __typename?: 'UserProfile' } & Pick<
            UserProfile,
            | 'alias'
            | 'firstName'
            | 'lastName'
            | 'phone'
            | 'mobile'
            | 'areaOfInterest'
            | 'shopping_default'
            | 'billing_default'
          > & {
              basket?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Basket' } & Pick<Basket, '_id' | 'ean' | 'units'> & {
                        book?: Maybe<
                          { __typename?: 'Book' } & Pick<
                            Book,
                            'priceWithDiscount' | '_id' | 'title'
                          >
                        >;
                      }
                  >
                >
              >;
              shoppingAddress?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'UserProfileShoppingAddress' } & Pick<
                      UserProfileShoppingAddress,
                      | 'name'
                      | 'address'
                      | 'route'
                      | 'streetNumber'
                      | 'floor'
                      | 'postalCode'
                      | 'locality'
                      | 'province'
                      | 'country'
                      | 'comment'
                      | 'deliverTo'
                      | 'isOutUE'
                      | 'countryCode'
                      | 'cif'
                      | 'businessName'
                    > & {
                        location?: Maybe<
                          { __typename?: 'Location' } & Pick<Location, 'lat' | 'lng'>
                        >;
                      }
                  >
                >
              >;
              billingData?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'UserProfileBillingData' } & Pick<
                      UserProfileBillingData,
                      | 'address'
                      | 'route'
                      | 'streetNumber'
                      | 'floor'
                      | 'postalCode'
                      | 'locality'
                      | 'province'
                      | 'country'
                      | 'countryCode'
                      | 'cif'
                      | 'businessName'
                    >
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type RememberPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type RememberPasswordMutation = { __typename?: 'RootMutation' } & {
  rememberPassword?: Maybe<{ __typename?: 'State' } & Pick<State, 'OK'>>;
};

export type SetAreaOfInterestMutationVariables = Exact<{
  _id: Scalars['ID'];
  numberAreaOfInterest?: Maybe<Scalars['Int']>;
}>;

export type SetAreaOfInterestMutation = { __typename?: 'RootMutation' } & {
  setAreaOfInterest?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id' | 'email'> & {
        profile?: Maybe<{ __typename?: 'UserProfile' } & Pick<UserProfile, 'areaOfInterest'>>;
      }
  >;
};

export type SubscribeToNewsletterMutationVariables = Exact<{
  _id?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  areaOfInterest?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  active?: Maybe<Scalars['Boolean']>;
}>;

export type SubscribeToNewsletterMutation = { __typename?: 'RootMutation' } & {
  subscribeToNewsletter?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id' | 'email'> & {
        profile?: Maybe<
          { __typename?: 'UserProfile' } & {
            newsletter?: Maybe<{ __typename?: 'Newsletter' } & Pick<Newsletter, 'areaOfInterest'>>;
          }
        >;
      }
  >;
};

export type UpdateUserMutationVariables = Exact<{
  _id: Scalars['ID'];
  newUserVar: UserNew;
}>;

export type UpdateUserMutation = { __typename?: 'RootMutation' } & {
  updateUser?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id' | 'email'> & {
        profile?: Maybe<
          { __typename?: 'UserProfile' } & Pick<
            UserProfile,
            'alias' | 'firstName' | 'lastName' | 'phone' | 'mobile'
          > & {
              basket?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Basket' } & Pick<Basket, '_id' | 'ean' | 'units'> & {
                        book?: Maybe<
                          { __typename?: 'Book' } & Pick<
                            Book,
                            'priceWithDiscount' | '_id' | 'title'
                          >
                        >;
                      }
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type DeleteUserMutationVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type DeleteUserMutation = { __typename?: 'RootMutation' } & {
  deleteUser?: Maybe<{ __typename?: 'DeletedUser' } & Pick<DeletedUser, 'OK' | 'error'>>;
};

export type DeleteAddressMutationVariables = Exact<{
  _id: Scalars['ID'];
  index: Scalars['Int'];
}>;

export type ChangePasswordMutationVariables = Exact<{
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
}>;

export type ChangePasswordData = Exact<{
  OK: Scalars['Boolean'];
  message: Scalars['String'];
  __typename: 'ChangePassword';
}>;

export type ChangePasswordMutation = Exact<{
  changePassword: ChangePasswordData;
}>;

export type DeleteAddressMutation = { __typename?: 'RootMutation' } & {
  deleteAddress?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id' | 'email'> & {
        profile?: Maybe<
          { __typename?: 'UserProfile' } & Pick<
            UserProfile,
            'alias' | 'firstName' | 'lastName' | 'areaOfInterest' | 'shopping_default'
          > & {
              newsletter?: Maybe<
                { __typename?: 'Newsletter' } & Pick<Newsletter, 'areaOfInterest'>
              >;
              basket?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Basket' } & Pick<Basket, '_id' | 'ean' | 'units'> & {
                        book?: Maybe<
                          { __typename?: 'Book' } & Pick<
                            Book,
                            'priceWithDiscount' | 'product_type' | 'titleFriendly' | '_id' | 'title'
                          >
                        >;
                      }
                  >
                >
              >;
              shoppingAddress?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'UserProfileShoppingAddress' } & Pick<
                      UserProfileShoppingAddress,
                      | 'name'
                      | 'address'
                      | 'route'
                      | 'streetNumber'
                      | 'floor'
                      | 'postalCode'
                      | 'locality'
                      | 'province'
                      | 'country'
                      | 'comment'
                      | 'countryCode'
                      | 'deliverTo'
                      | 'isOutUE'
                      | 'cif'
                      | 'businessName'
                    > & {
                        location?: Maybe<
                          { __typename?: 'Location' } & Pick<Location, 'lat' | 'lng'>
                        >;
                      }
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type DeleteBillingDataMutationVariables = Exact<{
  _id: Scalars['ID'];
  index: Scalars['Int'];
}>;

export type DeleteBillingDataMutation = { __typename?: 'RootMutation' } & {
  deleteBillingData?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id' | 'email'> & {
        profile?: Maybe<
          { __typename?: 'UserProfile' } & Pick<
            UserProfile,
            | 'alias'
            | 'firstName'
            | 'lastName'
            | 'areaOfInterest'
            | 'shopping_default'
            | 'billing_default'
          > & {
              newsletter?: Maybe<
                { __typename?: 'Newsletter' } & Pick<Newsletter, 'areaOfInterest'>
              >;
              basket?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Basket' } & Pick<Basket, '_id' | 'ean' | 'units'> & {
                        book?: Maybe<
                          { __typename?: 'Book' } & Pick<
                            Book,
                            'priceWithDiscount' | 'product_type' | 'titleFriendly' | '_id' | 'title'
                          >
                        >;
                      }
                  >
                >
              >;
              shoppingAddress?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'UserProfileShoppingAddress' } & Pick<
                      UserProfileShoppingAddress,
                      | 'name'
                      | 'address'
                      | 'route'
                      | 'streetNumber'
                      | 'floor'
                      | 'postalCode'
                      | 'locality'
                      | 'province'
                      | 'country'
                      | 'comment'
                      | 'countryCode'
                      | 'deliverTo'
                      | 'isOutUE'
                      | 'cif'
                      | 'businessName'
                    > & {
                        location?: Maybe<
                          { __typename?: 'Location' } & Pick<Location, 'lat' | 'lng'>
                        >;
                      }
                  >
                >
              >;
              billingData?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'UserProfileBillingData' } & Pick<
                      UserProfileBillingData,
                      | 'address'
                      | 'route'
                      | 'streetNumber'
                      | 'floor'
                      | 'postalCode'
                      | 'locality'
                      | 'province'
                      | 'country'
                      | 'countryCode'
                      | 'cif'
                      | 'businessName'
                    >
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type AddToBasketMutationVariables = Exact<{
  idUser: Scalars['ID'];
  idProduct: Scalars['String'];
  ean: Scalars['String'];
  units?: Maybe<Scalars['Int']>;
}>;

export type AddToBasketMutation = { __typename?: 'RootMutation' } & {
  addToBasket?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id'> & {
        profile?: Maybe<
          { __typename?: 'UserProfile' } & {
            basket?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Basket' } & Pick<Basket, '_id' | 'ean' | 'product_type' | 'units'>
                >
              >
            >;
          }
        >;
      }
  >;
};

export type ModifyToBasketMutationVariables = Exact<{
  idUser: Scalars['ID'];
  idProduct: Scalars['String'];
  operation?: Maybe<Scalars['Boolean']>;
}>;

export type ModifyToBasketMutation = { __typename?: 'RootMutation' } & {
  modifyToBasket?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id'> & {
        profile?: Maybe<
          { __typename?: 'UserProfile' } & {
            basket?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Basket' } & Pick<
                    Basket,
                    '_id' | 'ean' | 'units' | 'product_type'
                  > & {
                      book?: Maybe<
                        { __typename?: 'Book' } & Pick<
                          Book,
                          | '_id'
                          | 'ean'
                          | 'priceWithDiscount'
                          | 'titleFriendly'
                          | 'reposition_level'
                          | 'product_type'
                          | 'stock_available'
                          | 'title'
                          | 'weight'
                          | 'mainImg'
                        > & {
                            availability?: Maybe<
                              { __typename?: 'Availability' } & Pick<
                                Availability,
                                'OK' | 'msg' | 'status'
                              >
                            >;
                            dimensions?: Maybe<
                              { __typename?: 'Dimensions' } & Pick<
                                Dimensions,
                                'depth' | 'width' | 'height'
                              >
                            >;
                            prices?: Maybe<{ __typename?: 'Prices' } & Pick<Prices, 'sale'>>;
                            vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
                          }
                      >;
                    }
                >
              >
            >;
          }
        >;
      }
  >;
};

export type RemoveToBasketMutationVariables = Exact<{
  idUser: Scalars['ID'];
  idProduct: Scalars['String'];
}>;

export type RemoveToBasketMutation = { __typename?: 'RootMutation' } & {
  removeToBasket?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id'> & {
        profile?: Maybe<
          { __typename?: 'UserProfile' } & {
            basket?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Basket' } & Pick<Basket, '_id' | 'ean' | 'units'> & {
                      book?: Maybe<
                        { __typename?: 'Book' } & Pick<
                          Book,
                          | '_id'
                          | 'ean'
                          | 'priceWithDiscount'
                          | 'titleFriendly'
                          | 'reposition_level'
                          | 'product_type'
                          | 'stock_available'
                          | 'title'
                          | 'weight'
                          | 'mainImg'
                        > & {
                            availability?: Maybe<
                              { __typename?: 'Availability' } & Pick<
                                Availability,
                                'OK' | 'msg' | 'status'
                              >
                            >;
                            dimensions?: Maybe<
                              { __typename?: 'Dimensions' } & Pick<
                                Dimensions,
                                'depth' | 'width' | 'height'
                              >
                            >;
                            prices?: Maybe<{ __typename?: 'Prices' } & Pick<Prices, 'sale'>>;
                            vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
                          }
                      >;
                    }
                >
              >
            >;
          }
        >;
      }
  >;
};

export type NewOrderMutationVariables = Exact<{
  newOrderVar: OrderNew;
}>;

export type NewOrderMutation = { __typename?: 'RootMutation' } & {
  newOrder?: Maybe<
    { __typename?: 'Order' } & Pick<
      Order,
      | '_id'
      | 'order'
      | 'userId'
      | 'amountIVA'
      | 'orderSequence'
      | 'amountWithoutIVA'
      | 'transport'
      | 'amountTransport'
      | 'amount'
      | 'methodPayment'
      | 'fullNameUser'
      | 'fullNameUserClean'
      | 'legalNameFiscalData'
      | 'fiscalDoc'
      | 'state_order'
      | 'amountDiscount'
      | 'discountIdCode'
      | 'discountIdCodeWork'
      | 'confirmDate'
      | 'autorizationCode'
      | 'responseCode'
      | 'order_multiple'
      | 'giftCardCode'
      | 'errorCode'
      | 'deleted'
      | 'createdAt'
    > & {
        deliveryAddress?: Maybe<
          { __typename?: 'OrderDeliveryAddress' } & Pick<
            OrderDeliveryAddress,
            | 'name'
            | 'cif'
            | 'address'
            | 'route'
            | 'streetNumber'
            | 'floor'
            | 'postalCode'
            | 'locality'
            | 'province'
            | 'country'
            | 'comment'
            | 'countryCode'
            | 'isOutUE'
          >
        >;
        otherTaxes?: Maybe<
          Array<
            Maybe<
              { __typename?: 'OtherTaxes' } & Pick<OtherTaxes, 'amount' | 'description' | 'name'>
            >
          >
        >;
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: 'OrderItems' } & Pick<
                OrderItems,
                | '_id'
                | 'ean'
                | 'units'
                | 'priceSign'
                | 'priceSignDeal'
                | 'itemState'
                | 'canceledDate'
              > & {
                  book?: Maybe<
                    { __typename?: 'Book' } & Pick<
                      Book,
                      | 'priceWithDiscount'
                      | 'lastBuyPrice'
                      | 'exchangeRate'
                      | 'buyExpenses'
                      | '_id'
                      | 'id'
                      | 'title'
                    > & {
                        prices?: Maybe<{ __typename?: 'Prices' } & Pick<Prices, 'sale'>>;
                        vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type NewEbookOrderMutationVariables = Exact<{
  newOrderVar: OrderNew;
}>;

export type NewEbookOrderMutation = { __typename?: 'RootMutation' } & {
  newEbookOrder?: Maybe<
    { __typename?: 'Order' } & Pick<
      Order,
      | '_id'
      | 'order'
      | 'userId'
      | 'amountIVA'
      | 'orderSequence'
      | 'amountWithoutIVA'
      | 'amount'
      | 'methodPayment'
      | 'fullNameUser'
      | 'fullNameUserClean'
      | 'legalNameFiscalData'
      | 'fiscalDoc'
      | 'state_order'
      | 'amountDiscount'
      | 'discountIdCode'
      | 'discountIdCodeWork'
      | 'confirmDate'
      | 'autorizationCode'
      | 'responseCode'
      | 'order_multiple'
      | 'giftCardCode'
      | 'errorCode'
      | 'deleted'
      | 'createdAt'
    > & {
        otherTaxes?: Maybe<
          Array<
            Maybe<
              { __typename?: 'OtherTaxes' } & Pick<OtherTaxes, 'amount' | 'description' | 'name'>
            >
          >
        >;
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: 'OrderItems' } & Pick<
                OrderItems,
                | '_id'
                | 'ean'
                | 'units'
                | 'priceSign'
                | 'priceSignDeal'
                | 'itemState'
                | 'canceledDate'
              > & {
                  book?: Maybe<
                    { __typename?: 'Book' } & Pick<
                      Book,
                      | 'priceWithDiscount'
                      | 'lastBuyPrice'
                      | 'exchangeRate'
                      | 'buyExpenses'
                      | '_id'
                      | 'id'
                      | 'title'
                    > & {
                        prices?: Maybe<{ __typename?: 'Prices' } & Pick<Prices, 'sale'>>;
                        vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type CreatePaymentMutationVariables = Exact<{
  method?: Maybe<Scalars['String']>;
  items: Array<Maybe<OrderItemsNew>> | Maybe<OrderItemsNew>;
  total: Scalars['Float'];
  token?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  coupon?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['ID']>;
  state_order?: Maybe<Scalars['String']>;
  orderSequence: Scalars['Int'];
  amountTransport: Scalars['Float'];
  isEbookCart?: Maybe<Scalars['Boolean']>;
  paymentIntentId?: Maybe<Scalars['String']>;
}>;

export type CreatePaymentMutation = { __typename?: 'RootMutation' } & {
  createPayment?: Maybe<
    { __typename?: 'Payment' } & Pick<Payment, 'id' | 'status' | 'errorCode' | 'redirect_urls'> & {
        outcome?: Maybe<
          { __typename?: 'Outcome' } & Pick<
            Outcome,
            'network_status' | 'reason' | 'risk_level' | 'seller_message' | 'type'
          >
        >;
        payer?: Maybe<{ __typename?: 'Payer' } & Pick<Payer, 'payment_method'>>;
        links?: Maybe<Array<Maybe<{ __typename?: 'Link' } & Pick<Link, 'href' | 'method'>>>>;
      }
  >;
};

export type ExecutePaymentMutationVariables = Exact<{
  paymentId?: Maybe<Scalars['String']>;
  payerID?: Maybe<Scalars['String']>;
}>;

export type ExecutePaymentMutation = { __typename?: 'RootMutation' } & {
  executePayment?: Maybe<{ __typename?: 'Payment' } & Pick<Payment, 'id'>>;
};

export type Get3DTokenMutationVariables = Exact<{ [key: string]: never }>;

export type Get3DTokenMutation = { __typename?: 'RootMutation' } & {
  get3DToken?: Maybe<{ __typename?: 'Token' } & Pick<Token, 'token'>>;
};

export type SaveRefundRequestMutationVariables = Exact<{
  data: RefundData;
}>;

export type SaveRefundRequestMutation = { __typename?: 'RootMutation' } & {
  saveRefundRequest?: Maybe<{ __typename?: 'State' } & Pick<State, 'OK'>>;
};

export type EmptyBasketMutationVariables = Exact<{
  idUser: Scalars['ID'];
}>;

export type EmptyBasketMutation = { __typename?: 'RootMutation' } & {
  emptyBasket?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id'> & {
        profile?: Maybe<
          { __typename?: 'UserProfile' } & {
            basket?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Basket' } & Pick<Basket, '_id' | 'ean' | 'units'> & {
                      book?: Maybe<
                        { __typename?: 'Book' } & Pick<Book, '_id' | 'id' | 'title'> & {
                            prices?: Maybe<{ __typename?: 'Prices' } & Pick<Prices, 'sale'>>;
                            vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
                          }
                      >;
                    }
                >
              >
            >;
          }
        >;
      }
  >;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = { __typename?: 'RootMutation' } & {
  login?: Maybe<
    { __typename?: 'Auth' } & Pick<Auth, 'token' | 'OK' | 'error'> & {
        me?: Maybe<
          { __typename?: 'User' } & Pick<User, '_id' | 'email'> & {
              profile?: Maybe<
                { __typename?: 'UserProfile' } & Pick<
                  UserProfile,
                  'alias' | 'firstName' | 'lastName'
                > & {
                    basket?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'Basket' } & Pick<Basket, '_id' | 'ean' | 'units'> & {
                              book?: Maybe<{ __typename?: 'Book' } & Pick<Book, '_id' | 'title'>>;
                            }
                        >
                      >
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export type InsertClickMutationVariables = Exact<{
  eanProduct: Scalars['String'];
}>;

export type InsertClickMutation = { __typename?: 'RootMutation' } & {
  insertClick?: Maybe<{ __typename?: 'Book' } & Pick<Book, '_id'>>;
};

export type InsertClickIdMutationVariables = Exact<{
  idProduct: Scalars['String'];
}>;

export type InsertClickIdMutation = { __typename?: 'RootMutation' } & {
  insertClickID?: Maybe<{ __typename?: 'Book' } & Pick<Book, '_id'>>;
};

export type InsertRecentViewMutationVariables = Exact<{
  eanProduct?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}>;

export type InsertRecentViewMutation = { __typename?: 'RootMutation' } & {
  insertRecentView?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id'> & {
        lastViews?: Maybe<
          Array<Maybe<{ __typename?: 'UserRelated' } & Pick<UserRelated, 'ean' | 'dateAdded'>>>
        >;
      }
  >;
};

export type SuscriptionRequestMutationVariables = Exact<{
  user_id: Scalars['ID'];
  pack_id: Scalars['String'];
  message: Scalars['String'];
}>;

export type SuscriptionRequestMutation = { __typename?: 'RootMutation' } & {
  suscriptionRequest?: Maybe<{ __typename?: 'Suscription' } & Pick<Suscription, '_id'>>;
};

export type CancelNewsletterMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type CancelNewsletterMutation = { __typename?: 'RootMutation' } & {
  cancelNewsletter?: Maybe<
    { __typename?: 'StateFeedback' } & Pick<StateFeedback, 'OK' | 'message'>
  >;
};

export type OptInSuscriptionMutationVariables = Exact<{
  _id: Scalars['ID'];
  email: Scalars['String'];
}>;

export type OptInSuscriptionMutation = { __typename?: 'RootMutation' } & {
  optInSuscription?: Maybe<{ __typename?: 'State' } & Pick<State, 'OK'>>;
};

export type CouponCheckQueryVariables = Exact<{
  coupon: Scalars['String'];
  items?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type CouponCheckQuery = { __typename?: 'RootQuery' } & {
  couponCheck?: Maybe<
    { __typename?: 'Coupon' } & Pick<
      Coupon,
      '_id' | 'coupon' | 'discount' | 'value' | 'productType' | 'accountCode'
    >
  >;
};

export type GetAreaNameQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetAreaNameQuery = { __typename?: 'RootQuery' } & {
  getAreaName?: Maybe<
    { __typename?: 'Area' } & Pick<Area, '_id' | 'id' | 'nameEsFriendly' | 'nameEs'>
  >;
};

export type GetPrePublicationQueryVariables = Exact<{
  product_type: Scalars['String'];
  area?: Maybe<Scalars['Int']>;
}>;

export type GetPrePublicationQuery = { __typename?: 'RootQuery' } & {
  getPrePublication?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Book' } & Pick<
          Book,
          '_id' | 'id' | 'ean' | 'priceWithDiscount' | 'title' | 'titleFriendly' | 'mainImg'
        > & {
            prices?: Maybe<{ __typename?: 'Prices' } & Pick<Prices, 'sale'>>;
            authors?: Maybe<Array<Maybe<{ __typename?: 'Author' } & Pick<Author, 'name'>>>>;
          }
      >
    >
  >;
};

export type GetImportantProductQueryVariables = Exact<{
  product_type: Scalars['String'];
  area?: Maybe<Scalars['Int']>;
}>;

export type GetImportantProductQuery = { __typename?: 'RootQuery' } & {
  getImportantProduct?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Book' } & Pick<
          Book,
          '_id' | 'id' | 'ean' | 'priceWithDiscount' | 'title' | 'titleFriendly' | 'mainImg'
        > & {
            prices?: Maybe<{ __typename?: 'Prices' } & Pick<Prices, 'sale'>>;
            authors?: Maybe<Array<Maybe<{ __typename?: 'Author' } & Pick<Author, 'name'>>>>;
          }
      >
    >
  >;
};

export type GetAreasPredictiveQueryVariables = Exact<{
  search: Scalars['String'];
  limit: Scalars['Int'];
}>;

export type GetAreasPredictiveQuery = { __typename?: 'RootQuery' } & {
  getAreasPredictive?: Maybe<
    Array<Maybe<{ __typename?: 'Area' } & Pick<Area, '_id' | 'id' | 'nameEs' | 'nameEsFriendly'>>>
  >;
};

export type CheckStockItemQueryVariables = Exact<{
  items: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type CheckStockItemQuery = { __typename?: 'RootQuery' } & {
  checkStockItem?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Book' } & Pick<
          Book,
          | '_id'
          | 'title'
          | 'ean'
          | 'priceWithDiscount'
          | 'titleFriendly'
          | 'lastBuyPrice'
          | 'exchangeRate'
          | 'buyExpenses'
          | 'mainImg'
          | 'reposition_level'
          | 'stock_available'
          | 'product_type'
          | 'not_released'
          | 'unavailable'
          | 'group_property_names'
          | 'themes_text'
          | 'defaultVariant'
        > & {
            availability?: Maybe<
              { __typename?: 'Availability' } & Pick<Availability, 'OK' | 'msg' | 'status'>
            >;
            dimensions?: Maybe<
              { __typename?: 'Dimensions' } & Pick<Dimensions, 'depth' | 'width' | 'height'>
            >;
            book?: Maybe<
              { __typename?: 'BookInfo' } & Pick<
                BookInfo,
                'description' | 'table_of_contents' | 'lang' | 'pages' | 'binding'
              > & {
                  authors?: Maybe<
                    Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                  >;
                  edition?: Maybe<
                    { __typename?: 'Edition' } & Pick<Edition, 'number' | 'year' | 'month'>
                  >;
                }
            >;
            brand?: Maybe<{ __typename?: 'Brand' } & Pick<Brand, 'id' | 'name'>>;
            authors?: Maybe<Array<Maybe<{ __typename?: 'Author' } & Pick<Author, 'name'>>>>;
            prices?: Maybe<{ __typename?: 'Prices' } & Pick<Prices, 'sale'>>;
            vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
            suscription?: Maybe<
              Array<Maybe<{ __typename?: 'SuscriptionBook' } & Pick<SuscriptionBook, 'email'>>>
            >;
            tags?: Maybe<Array<Maybe<{ __typename?: 'Tags' } & Pick<Tags, 'items' | 'category'>>>>;
            variantChildren?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Book' } & Pick<
                    Book,
                    | '_id'
                    | 'id'
                    | 'title'
                    | 'ean'
                    | 'priceWithDiscount'
                    | 'titleFriendly'
                    | 'reposition_level'
                    | 'product_type'
                    | 'not_released'
                    | 'stock_available'
                    | 'unavailable'
                  > & {
                      dimensions?: Maybe<
                        { __typename?: 'Dimensions' } & Pick<
                          Dimensions,
                          'depth' | 'width' | 'height'
                        >
                      >;
                      book?: Maybe<
                        { __typename?: 'BookInfo' } & Pick<
                          BookInfo,
                          'lang' | 'pages' | 'binding'
                        > & {
                            authors?: Maybe<
                              Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                            >;
                            edition?: Maybe<
                              { __typename?: 'Edition' } & Pick<
                                Edition,
                                'number' | 'year' | 'month'
                              >
                            >;
                          }
                      >;
                      brand?: Maybe<{ __typename?: 'Brand' } & Pick<Brand, 'id' | 'name'>>;
                      authors?: Maybe<
                        Array<Maybe<{ __typename?: 'Author' } & Pick<Author, 'name'>>>
                      >;
                      prices?: Maybe<{ __typename?: 'Prices' } & Pick<Prices, 'sale'>>;
                      vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
                      variant?: Maybe<
                        { __typename?: 'Variant' } & Pick<Variant, 'group_id'> & {
                            properties?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'Property' } & Pick<Property, 'color' | 'finish'>
                                >
                              >
                            >;
                          }
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetAreasQueryVariables = Exact<{ [key: string]: never }>;

export type GetAreasQuery = { __typename?: 'RootQuery' } & {
  getAreas?: Maybe<
    Array<Maybe<{ __typename?: 'Area' } & Pick<Area, '_id' | 'id' | 'nameEs' | 'nameEsFriendly'>>>
  >;
};

export type GetTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTagsQuery = { __typename?: 'RootQuery' } & {
  getTags?: Maybe<Array<Maybe<{ __typename?: 'Tag' } & Pick<Tag, '_id' | 'id' | 'tagEs'>>>>;
};

export type CurrentProductsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  skip: Scalars['Int'];
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  lang?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  rangePrice?: Maybe<RangePrice>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  areas_of_interest_text?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<TagsInput>> | Maybe<TagsInput>>;
  type?: Maybe<Scalars['String']>;
  searchGeneral?: Maybe<Scalars['String']>;
  prox?: Maybe<Scalars['Boolean']>;
  area_prox?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  is_new?: Maybe<Scalars['Boolean']>;
}>;

export type CurrentProductsQuery = { __typename?: 'RootQuery' } & {
  books?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Book' } & Pick<
          Book,
          | 'id'
          | '_id'
          | 'titleFriendly'
          | 'title'
          | 'ean'
          | 'mainImg'
          | 'ebookContent'
          | 'product_type'
          | 'priority'
          | 'priority_nov'
          | 'priceWithDiscount'
          | 'not_released'
        > & {
            relatedInfo?: Maybe<
              { __typename?: 'Book' } & Pick<
                Book,
                | '_id'
                | 'id'
                | 'product_type'
                | 'priceWithDiscount'
                | 'ean'
                | 'title'
                | 'titleFriendly'
                | 'ebookContent'
                | 'weight'
              > & {
                  prices?: Maybe<
                    { __typename?: 'Prices' } & Pick<
                      Prices,
                      'sale' | 'clearing' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
                    >
                  >;
                  book?: Maybe<
                    { __typename?: 'BookInfo' } & Pick<BookInfo, 'pages' | 'lang' | 'binding'> & {
                        authors?: Maybe<
                          Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                        >;
                      }
                  >;
                  availability?: Maybe<
                    { __typename?: 'Availability' } & Pick<Availability, 'OK' | 'msg' | 'status'>
                  >;
                }
            >;
            prices?: Maybe<
              { __typename?: 'Prices' } & Pick<
                Prices,
                'sale' | 'clearing' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
              >
            >;
            vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
            tags?: Maybe<Array<Maybe<{ __typename?: 'Tags' } & Pick<Tags, 'category' | 'items'>>>>;
            book?: Maybe<
              { __typename?: 'BookInfo' } & Pick<BookInfo, 'lang' | 'binding'> & {
                  authors?: Maybe<
                    Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                  >;
                  edition?: Maybe<
                    { __typename?: 'Edition' } & Pick<Edition, 'year' | 'number' | 'month'>
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type AggregateQueryVariables = Exact<{
  collectionName: Scalars['String'];
  group: Scalars['String'];
  total?: Maybe<Scalars['String']>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  lang?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  rangePrice?: Maybe<RangePrice>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  tags?: Maybe<Array<Maybe<TagsInput>> | Maybe<TagsInput>>;
  type?: Maybe<Scalars['String']>;
  searchGeneral?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  version?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  prox?: Maybe<Scalars['Boolean']>;
  area_prox?: Maybe<Scalars['String']>;
}>;

export type AggregateQuery = { __typename?: 'RootQuery' } & {
  aggregate?: Maybe<
    { __typename?: 'Aggregate' } & Pick<Aggregate, '_id'> & {
        results?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ResultsAggregate' } & Pick<
                ResultsAggregate,
                '_id' | 'name' | 'total' | 'category'
              >
            >
          >
        >;
      }
  >;
};

export type MeQueryVariables = Exact<{
  state?: Maybe<Scalars['Float']>;
}>;

export type MeQuery = { __typename?: 'RootQuery' } & {
  me?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id' | 'email' | 'newsletterActive' | 'createdAt'> & {
        profile?: Maybe<
          { __typename?: 'UserProfile' } & Pick<
            UserProfile,
            | 'alias'
            | 'firstName'
            | 'lastName'
            | 'mobile'
            | 'phone'
            | 'areaOfInterest'
            | 'NIF'
            | 'shopping_default'
            | 'billing_default'
            | 'customerId'
          > & {
              newsletter?: Maybe<
                { __typename?: 'Newsletter' } & Pick<Newsletter, 'areaOfInterest'>
              >;
              ebooks?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Ebooks' } & Pick<
                      Ebooks,
                      '_id' | 'ean' | 'code' | 'buyDate' | 'mainImg'
                    >
                  >
                >
              >;
              basket?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Basket' } & Pick<Basket, '_id' | 'ean' | 'units'> & {
                        book?: Maybe<
                          { __typename?: 'Book' } & Pick<
                            Book,
                            | 'lastBuyPrice'
                            | 'exchangeRate'
                            | 'buyExpenses'
                            | 'priceWithDiscount'
                            | 'product_type'
                            | 'titleFriendly'
                            | '_id'
                            | 'title'
                          >
                        >;
                      }
                  >
                >
              >;
              shoppingAddress?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'UserProfileShoppingAddress' } & Pick<
                      UserProfileShoppingAddress,
                      | 'name'
                      | 'address'
                      | 'route'
                      | 'streetNumber'
                      | 'floor'
                      | 'postalCode'
                      | 'locality'
                      | 'isOutUE'
                      | 'province'
                      | 'country'
                      | 'comment'
                      | 'deliverTo'
                      | 'countryCode'
                      | 'cif'
                      | 'businessName'
                    > & {
                        location?: Maybe<
                          { __typename?: 'Location' } & Pick<Location, 'lat' | 'lng'>
                        >;
                      }
                  >
                >
              >;
              billingData?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'UserProfileBillingData' } & Pick<
                      UserProfileBillingData,
                      | 'address'
                      | 'route'
                      | 'streetNumber'
                      | 'floor'
                      | 'postalCode'
                      | 'locality'
                      | 'province'
                      | 'country'
                      | 'countryCode'
                      | 'cif'
                      | 'businessName'
                    >
                  >
                >
              >;
              couponsUsed?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'CouponsUsed' } & Pick<
                      CouponsUsed,
                      '_id' | 'coupon' | 'discount' | 'value' | 'usedDate'
                    >
                  >
                >
              >;
            }
        >;
        bestCoupon?: Maybe<
          { __typename?: 'Coupon' } & Pick<
            Coupon,
            '_id' | 'coupon' | 'discount' | 'value' | 'productType' | 'accountCode'
          >
        >;
      }
  >;
};

export type CurrentJournalsQueryVariables = Exact<{
  id_journal?: Maybe<Scalars['Int']>;
}>;

export type CurrentJournalsQuery = { __typename?: 'RootQuery' } & {
  journal?: Maybe<
    { __typename?: 'MagazineJournals' } & Pick<
      MagazineJournals,
      | '_id'
      | 'id_journal'
      | 'title'
      | 'version'
      | 'issn'
      | 'issues'
      | 'country_key'
      | 'titleFriendly'
      | 'languages'
    > & {
        brand?: Maybe<{ __typename?: 'BrandJournal' } & Pick<BrandJournal, 'id' | 'name'>>;
        packs?: Maybe<
          Array<
            Maybe<
              { __typename?: 'MagazinePacks' } & Pick<MagazinePacks, '_id' | 'id_pack'> & {
                  rates?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'MagazinePacksRates' } & Pick<
                          MagazinePacksRates,
                          | '_id'
                          | 'id_rate'
                          | 'subscriber_type'
                          | 'price'
                          | 'start'
                          | 'end'
                          | 'valid_to'
                          | 'is_valid'
                        >
                      >
                    >
                  >;
                  journals?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'MagazineJournals' } & Pick<
                          MagazineJournals,
                          | '_id'
                          | 'id_journal'
                          | 'title'
                          | 'version'
                          | 'issn'
                          | 'issues'
                          | 'country_key'
                          | 'titleFriendly'
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GetBookIdQueryVariables = Exact<{
  ean: Scalars['String'];
}>;

export type GetBookIdQuery = { __typename?: 'RootQuery' } & {
  book?: Maybe<{ __typename?: 'Book' } & Pick<Book, '_id' | 'id'>>;
};

export type CurrentProductQueryVariables = Exact<{
  ean: Scalars['String'];
}>;

export type CurrentProductQuery = { __typename?: 'RootQuery' } & {
  book?: Maybe<
    { __typename?: 'Book' } & Pick<
      Book,
      | '_id'
      | 'id'
      | 'title'
      | 'ean'
      | 'titleFriendly'
      | 'mainImg'
      | 'priceWithDiscount'
      | 'ebookContent'
      | 'weight'
      | 'product_type'
      | 'not_released'
      | 'stock_available'
      | 'isNotToPublish'
      | 'unavailable'
      | 'themes_text'
      | 'defaultVariant'
    > & {
        banner?: Maybe<{ __typename?: 'Banner' } & Pick<Banner, 'active' | 'launching'>>;
        prepublication?: Maybe<
          { __typename?: 'Prepublication' } & Pick<Prepublication, 'launching' | 'start' | 'end'>
        >;
        relatedInfo?: Maybe<
          { __typename?: 'Book' } & Pick<
            Book,
            | '_id'
            | 'id'
            | 'product_type'
            | 'priceWithDiscount'
            | 'ean'
            | 'title'
            | 'titleFriendly'
            | 'ebookContent'
            | 'weight'
            | 'mainImg'
          > & {
              allImg?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'ImgProduct' } & Pick<ImgProduct, 'link' | 'width' | 'height'>
                  >
                >
              >;
              book?: Maybe<
                { __typename?: 'BookInfo' } & Pick<BookInfo, 'lang' | 'pages' | 'binding'> & {
                    authors?: Maybe<
                      Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                    >;
                  }
              >;
              prices?: Maybe<
                { __typename?: 'Prices' } & Pick<
                  Prices,
                  'sale' | 'clearing' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
                >
              >;
              availability?: Maybe<
                { __typename?: 'Availability' } & Pick<Availability, 'OK' | 'msg' | 'status'>
              >;
              dimensions?: Maybe<
                { __typename?: 'Dimensions' } & Pick<Dimensions, 'depth' | 'width' | 'height'>
              >;
            }
        >;
        attachments?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Attachments' } & Pick<
                Attachments,
                'fileName' | 'fileType' | 'caption'
              >
            >
          >
        >;
        allImg?: Maybe<
          Array<
            Maybe<{ __typename?: 'ImgProduct' } & Pick<ImgProduct, 'link' | 'width' | 'height'>>
          >
        >;
        availability?: Maybe<
          { __typename?: 'Availability' } & Pick<Availability, 'OK' | 'msg' | 'status'>
        >;
        dimensions?: Maybe<
          { __typename?: 'Dimensions' } & Pick<Dimensions, 'depth' | 'width' | 'height'>
        >;
        book?: Maybe<
          { __typename?: 'BookInfo' } & Pick<
            BookInfo,
            'description' | 'table_of_contents' | 'lang' | 'pages' | 'binding'
          > & {
              authors?: Maybe<
                Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
              >;
              edition?: Maybe<
                { __typename?: 'Edition' } & Pick<Edition, 'number' | 'year' | 'month'>
              >;
            }
        >;
        brand?: Maybe<{ __typename?: 'Brand' } & Pick<Brand, 'id' | 'name'>>;
        authors?: Maybe<Array<Maybe<{ __typename?: 'Author' } & Pick<Author, 'name'>>>>;
        prices?: Maybe<
          { __typename?: 'Prices' } & Pick<
            Prices,
            'sale' | 'saleSpecialDiscount' | 'ssdTo' | 'ssdFrom'
          >
        >;
        vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
        suscription?: Maybe<
          Array<Maybe<{ __typename?: 'SuscriptionBook' } & Pick<SuscriptionBook, 'email'>>>
        >;
        tags?: Maybe<Array<Maybe<{ __typename?: 'Tags' } & Pick<Tags, 'items' | 'category'>>>>;
        variantProduct?: Maybe<
          { __typename?: 'Book' } & Pick<
            Book,
            'ean' | 'title' | 'priceWithDiscount' | 'titleFriendly'
          >
        >;
        variant?: Maybe<
          { __typename?: 'Variant' } & Pick<Variant, 'group_id'> & {
              properties?: Maybe<
                Array<
                  Maybe<{ __typename?: 'Property' } & Pick<Property, 'color' | 'finish' | 'cuff'>>
                >
              >;
            }
        >;
        variantChildren?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Book' } & Pick<
                Book,
                | '_id'
                | 'id'
                | 'title'
                | 'ean'
                | 'priceWithDiscount'
                | 'titleFriendly'
                | 'product_type'
                | 'not_released'
                | 'themes_text'
                | 'mainImg'
                | 'unavailable'
              > & {
                  dimensions?: Maybe<
                    { __typename?: 'Dimensions' } & Pick<Dimensions, 'depth' | 'width' | 'height'>
                  >;
                  availability?: Maybe<
                    { __typename?: 'Availability' } & Pick<Availability, 'OK' | 'msg' | 'status'>
                  >;
                  book?: Maybe<
                    { __typename?: 'BookInfo' } & Pick<
                      BookInfo,
                      'table_of_contents' | 'lang' | 'pages' | 'binding' | 'description'
                    > & {
                        authors?: Maybe<
                          Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                        >;
                        edition?: Maybe<
                          { __typename?: 'Edition' } & Pick<Edition, 'number' | 'year' | 'month'>
                        >;
                      }
                  >;
                  brand?: Maybe<{ __typename?: 'Brand' } & Pick<Brand, 'id' | 'name'>>;
                  authors?: Maybe<Array<Maybe<{ __typename?: 'Author' } & Pick<Author, 'name'>>>>;
                  allImg?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ImgProduct' } & Pick<
                          ImgProduct,
                          'link' | 'width' | 'height'
                        >
                      >
                    >
                  >;
                  tags?: Maybe<
                    Array<Maybe<{ __typename?: 'Tags' } & Pick<Tags, 'items' | 'category'>>>
                  >;
                  prices?: Maybe<{ __typename?: 'Prices' } & Pick<Prices, 'sale'>>;
                  vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
                  variant?: Maybe<
                    { __typename?: 'Variant' } & Pick<Variant, 'group_id'> & {
                        properties?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'Property' } & Pick<
                                Property,
                                'color' | 'finish' | 'cuff'
                              >
                            >
                          >
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type CurrentProductIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CurrentProductIdQuery = { __typename?: 'RootQuery' } & {
  book?: Maybe<
    { __typename?: 'Book' } & Pick<
      Book,
      | '_id'
      | 'id'
      | 'ebookContent'
      | 'title'
      | 'ean'
      | 'titleFriendly'
      | 'mainImg'
      | 'priceWithDiscount'
      | 'weight'
      | 'product_type'
      | 'not_released'
      | 'stock_available'
      | 'isNotToPublish'
      | 'unavailable'
      | 'themes_text'
      | 'defaultVariant'
    > & {
        banner?: Maybe<{ __typename?: 'Banner' } & Pick<Banner, 'active' | 'launching'>>;
        relatedInfo?: Maybe<
          { __typename?: 'Book' } & Pick<
            Book,
            | '_id'
            | 'id'
            | 'product_type'
            | 'priceWithDiscount'
            | 'ean'
            | 'title'
            | 'titleFriendly'
            | 'ebookContent'
            | 'weight'
          > & {
              prices?: Maybe<
                { __typename?: 'Prices' } & Pick<
                  Prices,
                  'sale' | 'clearing' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
                >
              >;
              availability?: Maybe<
                { __typename?: 'Availability' } & Pick<Availability, 'OK' | 'msg' | 'status'>
              >;
              book?: Maybe<
                { __typename?: 'BookInfo' } & Pick<BookInfo, 'pages' | 'lang' | 'binding'> & {
                    authors?: Maybe<
                      Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                    >;
                  }
              >;
            }
        >;
        prepublication?: Maybe<
          { __typename?: 'Prepublication' } & Pick<Prepublication, 'launching' | 'start' | 'end'>
        >;
        attachments?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Attachments' } & Pick<
                Attachments,
                'fileName' | 'fileType' | 'caption'
              >
            >
          >
        >;
        allImg?: Maybe<
          Array<
            Maybe<{ __typename?: 'ImgProduct' } & Pick<ImgProduct, 'link' | 'width' | 'height'>>
          >
        >;
        availability?: Maybe<
          { __typename?: 'Availability' } & Pick<Availability, 'OK' | 'msg' | 'status'>
        >;
        dimensions?: Maybe<
          { __typename?: 'Dimensions' } & Pick<Dimensions, 'depth' | 'width' | 'height'>
        >;
        book?: Maybe<
          { __typename?: 'BookInfo' } & Pick<
            BookInfo,
            'description' | 'table_of_contents' | 'lang' | 'pages' | 'binding'
          > & {
              authors?: Maybe<
                Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
              >;
              edition?: Maybe<
                { __typename?: 'Edition' } & Pick<Edition, 'number' | 'year' | 'month'>
              >;
            }
        >;
        brand?: Maybe<{ __typename?: 'Brand' } & Pick<Brand, 'id' | 'name'>>;
        authors?: Maybe<Array<Maybe<{ __typename?: 'Author' } & Pick<Author, 'name'>>>>;
        prices?: Maybe<
          { __typename?: 'Prices' } & Pick<
            Prices,
            'sale' | 'saleSpecialDiscount' | 'ssdTo' | 'ssdFrom'
          >
        >;
        vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
        suscription?: Maybe<
          Array<Maybe<{ __typename?: 'SuscriptionBook' } & Pick<SuscriptionBook, 'email'>>>
        >;
        tags?: Maybe<Array<Maybe<{ __typename?: 'Tags' } & Pick<Tags, 'items' | 'category'>>>>;
        variantProduct?: Maybe<
          { __typename?: 'Book' } & Pick<
            Book,
            'ean' | 'title' | 'priceWithDiscount' | 'titleFriendly'
          >
        >;
        variant?: Maybe<
          { __typename?: 'Variant' } & Pick<Variant, 'group_id'> & {
              properties?: Maybe<
                Array<
                  Maybe<{ __typename?: 'Property' } & Pick<Property, 'color' | 'finish' | 'cuff'>>
                >
              >;
            }
        >;
        variantChildren?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Book' } & Pick<
                Book,
                | '_id'
                | 'id'
                | 'title'
                | 'ean'
                | 'priceWithDiscount'
                | 'titleFriendly'
                | 'product_type'
                | 'not_released'
                | 'themes_text'
                | 'mainImg'
                | 'unavailable'
              > & {
                  dimensions?: Maybe<
                    { __typename?: 'Dimensions' } & Pick<Dimensions, 'depth' | 'width' | 'height'>
                  >;
                  availability?: Maybe<
                    { __typename?: 'Availability' } & Pick<Availability, 'OK' | 'msg' | 'status'>
                  >;
                  book?: Maybe<
                    { __typename?: 'BookInfo' } & Pick<
                      BookInfo,
                      'table_of_contents' | 'lang' | 'pages' | 'binding' | 'description'
                    > & {
                        authors?: Maybe<
                          Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                        >;
                        edition?: Maybe<
                          { __typename?: 'Edition' } & Pick<Edition, 'number' | 'year' | 'month'>
                        >;
                      }
                  >;
                  brand?: Maybe<{ __typename?: 'Brand' } & Pick<Brand, 'id' | 'name'>>;
                  authors?: Maybe<Array<Maybe<{ __typename?: 'Author' } & Pick<Author, 'name'>>>>;
                  allImg?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ImgProduct' } & Pick<
                          ImgProduct,
                          'link' | 'width' | 'height'
                        >
                      >
                    >
                  >;
                  tags?: Maybe<
                    Array<Maybe<{ __typename?: 'Tags' } & Pick<Tags, 'items' | 'category'>>>
                  >;
                  prices?: Maybe<{ __typename?: 'Prices' } & Pick<Prices, 'sale'>>;
                  vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
                  variant?: Maybe<
                    { __typename?: 'Variant' } & Pick<Variant, 'group_id'> & {
                        properties?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'Property' } & Pick<
                                Property,
                                'color' | 'finish' | 'cuff'
                              >
                            >
                          >
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GetUserBasketQueryVariables = Exact<{
  idUser: Scalars['ID'];
}>;

export type GetUserBasketQuery = { __typename?: 'RootQuery' } & {
  user?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id'> & {
        profile?: Maybe<
          { __typename?: 'UserProfile' } & Pick<UserProfile, 'NIF'> & {
              basket?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Basket' } & Pick<Basket, '_id' | 'ean' | 'units'> & {
                        book?: Maybe<
                          { __typename?: 'Book' } & Pick<
                            Book,
                            | '_id'
                            | 'id'
                            | 'ean'
                            | 'priceWithDiscount'
                            | 'titleFriendly'
                            | 'lastBuyPrice'
                            | 'exchangeRate'
                            | 'buyExpenses'
                            | 'reposition_level'
                            | 'product_type'
                            | 'stock_available'
                            | 'title'
                            | 'weight'
                            | 'mainImg'
                          > & {
                              availability?: Maybe<
                                { __typename?: 'Availability' } & Pick<
                                  Availability,
                                  'OK' | 'msg' | 'status'
                                >
                              >;
                              dimensions?: Maybe<
                                { __typename?: 'Dimensions' } & Pick<
                                  Dimensions,
                                  'depth' | 'width' | 'height'
                                >
                              >;
                              tags?: Maybe<
                                Array<
                                  Maybe<{ __typename?: 'Tags' } & Pick<Tags, 'items' | 'category'>>
                                >
                              >;
                              brand?: Maybe<{ __typename?: 'Brand' } & Pick<Brand, 'id' | 'name'>>;
                              prices?: Maybe<
                                { __typename?: 'Prices' } & Pick<
                                  Prices,
                                  'sale' | 'discount' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
                                >
                              >;
                              vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
                            }
                        >;
                      }
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type OrderQueryVariables = Exact<{
  _id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
}>;

export type OrderQuery = { __typename?: 'RootQuery' } & {
  order?: Maybe<
    { __typename?: 'Order' } & Pick<
      Order,
      | '_id'
      | 'order'
      | 'userId'
      | 'orderSequence'
      | 'amountIVA'
      | 'amountWithoutIVA'
      | 'transport'
      | 'amountTransport'
      | 'amount'
      | 'methodPayment'
      | 'fullNameUser'
      | 'fullNameUserClean'
      | 'legalNameFiscalData'
      | 'fiscalDoc'
      | 'state_order'
      | 'amountDiscount'
      | 'discountIdCode'
      | 'discountIdCodeWork'
      | 'confirmDate'
      | 'autorizationCode'
      | 'responseCode'
      | 'order_multiple'
      | 'giftCardCode'
      | 'errorCode'
      | 'deleted'
      | 'createdAt'
    > & {
        deliveryAddress?: Maybe<
          { __typename?: 'OrderDeliveryAddress' } & Pick<
            OrderDeliveryAddress,
            | 'name'
            | 'address'
            | 'route'
            | 'streetNumber'
            | 'floor'
            | 'postalCode'
            | 'locality'
            | 'province'
            | 'country'
            | 'comment'
            | 'isOutUE'
            | 'deliverTo'
            | 'countryCode'
            | 'cif'
            | 'businessName'
          >
        >;
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: 'OrderItems' } & Pick<
                OrderItems,
                | '_id'
                | 'ean'
                | 'units'
                | 'priceSignDeal'
                | 'priceSign'
                | 'itemState'
                | 'canceledDate'
              > & {
                  book?: Maybe<
                    { __typename?: 'Book' } & Pick<
                      Book,
                      | 'priceWithDiscount'
                      | 'lastBuyPrice'
                      | 'exchangeRate'
                      | 'buyExpenses'
                      | '_id'
                      | 'id'
                      | 'title'
                      | 'reposition_level'
                      | 'stock_available'
                    > & {
                        availability?: Maybe<
                          { __typename?: 'Availability' } & Pick<
                            Availability,
                            'OK' | 'msg' | 'status'
                          >
                        >;
                        prices?: Maybe<{ __typename?: 'Prices' } & Pick<Prices, 'sale'>>;
                        vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type CurrentOrdersUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type CurrentOrdersUserQuery = { __typename?: 'RootQuery' } & {
  orders?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Order' } & Pick<
          Order,
          | '_id'
          | 'order'
          | 'userId'
          | 'orderSequence'
          | 'amountIVA'
          | 'amountWithoutIVA'
          | 'transport'
          | 'billGridId'
          | 'billId'
          | 'methodPayment'
          | 'amountTransport'
          | 'amount'
          | 'discountIdCode'
          | 'legalNameFiscalData'
          | 'state_order'
          | 'amountDiscount'
          | 'confirmDate'
          | 'autorizationCode'
          | 'responseCode'
          | 'order_multiple'
          | 'deleted'
          | 'createdAt'
        > & {
            otherTaxes?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'OtherTaxes' } & Pick<
                    OtherTaxes,
                    'amount' | 'description' | 'name'
                  >
                >
              >
            >;
            deliveryAddress?: Maybe<
              { __typename?: 'OrderDeliveryAddress' } & Pick<
                OrderDeliveryAddress,
                | 'name'
                | 'address'
                | 'route'
                | 'streetNumber'
                | 'floor'
                | 'postalCode'
                | 'locality'
                | 'province'
                | 'country'
                | 'comment'
                | 'deliverTo'
                | 'countryCode'
                | 'cif'
                | 'businessName'
                | 'isOutUE'
              >
            >;
            fiscalAddress?: Maybe<
              { __typename?: 'OrderFiscalAddress' } & Pick<
                OrderFiscalAddress,
                'name' | 'address' | 'locality' | 'cif'
              >
            >;
            items?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'OrderItems' } & Pick<
                    OrderItems,
                    | 'ean'
                    | 'units'
                    | 'priceSignDeal'
                    | 'priceSign'
                    | 'itemState'
                    | 'canceledDate'
                    | 'shipped'
                  > & {
                      invoice?: Maybe<
                        { __typename?: 'Order' } & Pick<
                          Order,
                          | '_id'
                          | 'billGridId'
                          | 'amountDiscount'
                          | 'amountIVA'
                          | 'discountIdCode'
                          | 'billId'
                          | 'orderSequence'
                          | 'state_order'
                          | 'order'
                          | 'amountTransport'
                        > & {
                            couponObject?: Maybe<
                              { __typename?: 'Coupon' } & Pick<
                                Coupon,
                                '_id' | 'coupon' | 'discount' | 'value' | 'productType'
                              >
                            >;
                            otherTaxes?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'OtherTaxes' } & Pick<
                                    OtherTaxes,
                                    'amount' | 'description' | 'name'
                                  >
                                >
                              >
                            >;
                          }
                      >;
                      book?: Maybe<
                        { __typename?: 'Book' } & Pick<
                          Book,
                          | 'ean'
                          | 'priceWithDiscount'
                          | 'titleFriendly'
                          | 'id'
                          | 'title'
                          | 'reposition_level'
                          | 'mainImg'
                          | 'product_type'
                          | 'stock_available'
                        > & {
                            vat?: Maybe<{ __typename?: 'Vat' } & Pick<Vat, 'sale'>>;
                            availability?: Maybe<
                              { __typename?: 'Availability' } & Pick<
                                Availability,
                                'OK' | 'msg' | 'status'
                              >
                            >;
                            prices?: Maybe<
                              { __typename?: 'Prices' } & Pick<Prices, 'clearing' | 'sale'>
                            >;
                          }
                      >;
                    }
                >
              >
            >;
            couponObject?: Maybe<
              { __typename?: 'Coupon' } & Pick<
                Coupon,
                '_id' | 'coupon' | 'discount' | 'value' | 'productType'
              >
            >;
          }
      >
    >
  >;
};

export type GetMagazinesPredictiveQueryVariables = Exact<{
  search: Scalars['String'];
  limit: Scalars['Int'];
}>;

export type GetMagazinesPredictiveQuery = { __typename?: 'RootQuery' } & {
  getMagazinesPredictive?: Maybe<
    Array<
      Maybe<
        { __typename?: 'MagazineJournals' } & Pick<
          MagazineJournals,
          | '_id'
          | 'title'
          | 'version'
          | 'issn'
          | 'issues'
          | 'id_journal'
          | 'country_key'
          | 'languages'
          | 'subjects'
          | 'titleFriendly'
        > & { brand?: Maybe<{ __typename?: 'BrandJournal' } & Pick<BrandJournal, 'id' | 'name'>> }
      >
    >
  >;
};

export type GetTagsCustomQueryVariables = Exact<{
  search: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  areaOfInterest?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;

export type GetTagsCustomQuery = { __typename?: 'RootQuery' } & {
  getTagsCustom?: Maybe<
    Array<Maybe<{ __typename?: 'TagSearch' } & Pick<TagSearch, '_id' | 'name' | 'total'>>>
  >;
};

export type GetResultsPredictiveQueryVariables = Exact<{
  search: Scalars['String'];
  limit: Scalars['Int'];
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  orderByScore?: Maybe<Scalars['Boolean']>;
}>;

export type GetResultsPredictiveQuery = { __typename?: 'RootQuery' } & {
  books?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Book' } & Pick<
          Book,
          | 'id'
          | 'ean'
          | 'titleFriendly'
          | 'ebookContent'
          | 'product_type'
          | 'mainImg'
          | 'title'
          | 'priceWithDiscount'
        > & {
            tags?: Maybe<Array<Maybe<{ __typename?: 'Tags' } & Pick<Tags, 'category' | 'items'>>>>;
            relatedInfo?: Maybe<
              { __typename?: 'Book' } & Pick<
                Book,
                | '_id'
                | 'id'
                | 'product_type'
                | 'priceWithDiscount'
                | 'ean'
                | 'title'
                | 'titleFriendly'
                | 'ebookContent'
                | 'weight'
              > & {
                  availability?: Maybe<
                    { __typename?: 'Availability' } & Pick<Availability, 'OK' | 'msg' | 'status'>
                  >;
                  prices?: Maybe<
                    { __typename?: 'Prices' } & Pick<
                      Prices,
                      'sale' | 'clearing' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
                    >
                  >;
                  book?: Maybe<
                    { __typename?: 'BookInfo' } & Pick<BookInfo, 'lang' | 'pages' | 'binding'> & {
                        authors?: Maybe<
                          Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                        >;
                      }
                  >;
                }
            >;
            prices?: Maybe<{ __typename?: 'Prices' } & Pick<Prices, 'sale'>>;
            book?: Maybe<
              { __typename?: 'BookInfo' } & Pick<BookInfo, 'binding' | 'pages'> & {
                  authors?: Maybe<Array<Maybe<{ __typename?: 'Author' } & Pick<Author, 'name'>>>>;
                  edition?: Maybe<{ __typename?: 'Edition' } & Pick<Edition, 'year'>>;
                }
            >;
          }
      >
    >
  >;
};

export type GetTagsPredictiveQueryVariables = Exact<{
  search: Scalars['String'];
  limit: Scalars['Int'];
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type GetTagsPredictiveQuery = { __typename?: 'RootQuery' } & {
  tags?: Maybe<
    Array<
      Maybe<{ __typename?: 'TagsResult' } & Pick<TagsResult, '_id' | 'name' | 'category' | 'tagEs'>>
    >
  >;
};

export type GetAreaQueryVariables = Exact<{
  nameEsFriendly: Scalars['String'];
}>;

export type GetAreaQuery = { __typename?: 'RootQuery' } & {
  getArea?: Maybe<{ __typename?: 'Area' } & Pick<Area, '_id' | 'id' | 'nameEs' | 'nameEsFriendly'>>;
};

export type GetResultsPredictiveSearchQueryVariables = Exact<{
  search: Scalars['String'];
  limit: Scalars['Int'];
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type GetResultsPredictiveSearchQuery = { __typename?: 'RootQuery' } & {
  resultsPredictive?: Maybe<
    Array<Maybe<{ __typename?: 'TagsResult' } & Pick<TagsResult, '_id' | 'name' | 'category'>>>
  >;
};

export type ShippingApiQueryVariables = Exact<{
  amount: Scalars['Float'];
  province: Scalars['String'];
  country: Scalars['String'];
  zip?: Maybe<Scalars['String']>;
}>;

export type ShippingApiQuery = { __typename?: 'RootQuery' } & {
  shippingAPI?: Maybe<
    Array<
      Maybe<{ __typename?: 'ShippingAPIData' } & Pick<ShippingApiData, 'company' | 'fee' | 'days'>>
    >
  >;
};

export type ProductsWithBannersQueryVariables = Exact<{
  _id?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  hasBanner?: Maybe<Scalars['String']>;
  product_type?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  unpublish?: Maybe<Scalars['Boolean']>;
}>;

export type ProductsWithBannersQuery = { __typename?: 'RootQuery' } & {
  productsWithBanners?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Book' } & Pick<
          Book,
          | '_id'
          | 'id'
          | 'title'
          | 'ean'
          | 'priceWithDiscount'
          | 'titleFriendly'
          | 'product_type'
          | 'not_released'
          | 'mainImg'
        > & {
            book?: Maybe<
              { __typename?: 'BookInfo' } & Pick<BookInfo, 'binding'> & {
                  authors?: Maybe<
                    Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                  >;
                  edition?: Maybe<
                    { __typename?: 'Edition' } & Pick<Edition, 'number' | 'year' | 'month'>
                  >;
                }
            >;
            prices?: Maybe<
              { __typename?: 'Prices' } & Pick<
                Prices,
                'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo' | 'sale'
              >
            >;
            banner?: Maybe<
              { __typename?: 'Banner' } & Pick<
                Banner,
                'active' | 'launching' | 'start' | 'end' | 'price'
              >
            >;
          }
      >
    >
  >;
};

export type GetBestSellersQueryVariables = Exact<{
  product_type?: Maybe<Scalars['String']>;
  areas_of_interest?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;

export type GetBestSellersQuery = { __typename?: 'RootQuery' } & {
  getBestSellers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Book' } & Pick<
          Book,
          | 'id'
          | '_id'
          | 'titleFriendly'
          | 'title'
          | 'ean'
          | 'mainImg'
          | 'areas_of_interest'
          | 'product_type'
          | 'priceWithDiscount'
        > & {
            prices?: Maybe<
              { __typename?: 'Prices' } & Pick<
                Prices,
                'sale' | 'clearing' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
              >
            >;
            relatedInfo?: Maybe<
              { __typename?: 'Book' } & Pick<
                Book,
                | '_id'
                | 'id'
                | 'product_type'
                | 'priceWithDiscount'
                | 'ean'
                | 'title'
                | 'titleFriendly'
                | 'ebookContent'
                | 'weight'
              > & {
                  book?: Maybe<
                    { __typename?: 'BookInfo' } & Pick<BookInfo, 'pages' | 'lang' | 'binding'> & {
                        authors?: Maybe<
                          Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                        >;
                      }
                  >;
                  availability?: Maybe<
                    { __typename?: 'Availability' } & Pick<Availability, 'OK' | 'msg' | 'status'>
                  >;
                  prices?: Maybe<
                    { __typename?: 'Prices' } & Pick<
                      Prices,
                      'sale' | 'clearing' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
                    >
                  >;
                }
            >;
            book?: Maybe<
              { __typename?: 'BookInfo' } & Pick<BookInfo, 'lang' | 'binding'> & {
                  authors?: Maybe<
                    Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type BooksFeaturedQueryVariables = Exact<{ [key: string]: never }>;

export type BooksFeaturedQuery = { __typename?: 'RootQuery' } & {
  booksFeatured?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Book' } & Pick<
          Book,
          | 'id'
          | '_id'
          | 'titleFriendly'
          | 'title'
          | 'ean'
          | 'mainImg'
          | 'ebookContent'
          | 'product_type'
          | 'priceWithDiscount'
        > & {
            relatedInfo?: Maybe<
              { __typename?: 'Book' } & Pick<
                Book,
                | '_id'
                | 'id'
                | 'product_type'
                | 'priceWithDiscount'
                | 'ean'
                | 'title'
                | 'titleFriendly'
                | 'ebookContent'
                | 'weight'
              > & {
                  book?: Maybe<
                    { __typename?: 'BookInfo' } & Pick<BookInfo, 'pages' | 'lang' | 'binding'> & {
                        authors?: Maybe<
                          Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                        >;
                      }
                  >;
                  prices?: Maybe<
                    { __typename?: 'Prices' } & Pick<
                      Prices,
                      'sale' | 'clearing' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
                    >
                  >;
                  availability?: Maybe<
                    { __typename?: 'Availability' } & Pick<Availability, 'OK' | 'msg' | 'status'>
                  >;
                }
            >;
            prices?: Maybe<
              { __typename?: 'Prices' } & Pick<
                Prices,
                'sale' | 'clearing' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
              >
            >;
            book?: Maybe<
              { __typename?: 'BookInfo' } & Pick<BookInfo, 'lang'> & {
                  authors?: Maybe<
                    Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type BooksNewsQueryVariables = Exact<{ [key: string]: never }>;

export type BooksNewsQuery = { __typename?: 'RootQuery' } & {
  booksNews?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Book' } & Pick<
          Book,
          | 'id'
          | '_id'
          | 'titleFriendly'
          | 'title'
          | 'ean'
          | 'mainImg'
          | 'ebookContent'
          | 'product_type'
          | 'priceWithDiscount'
        > & {
            relatedInfo?: Maybe<
              { __typename?: 'Book' } & Pick<
                Book,
                | '_id'
                | 'id'
                | 'product_type'
                | 'priceWithDiscount'
                | 'ean'
                | 'title'
                | 'titleFriendly'
                | 'ebookContent'
                | 'weight'
              > & {
                  book?: Maybe<
                    { __typename?: 'BookInfo' } & Pick<BookInfo, 'pages' | 'lang' | 'binding'> & {
                        authors?: Maybe<
                          Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                        >;
                      }
                  >;
                  prices?: Maybe<
                    { __typename?: 'Prices' } & Pick<
                      Prices,
                      'sale' | 'clearing' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
                    >
                  >;
                  availability?: Maybe<
                    { __typename?: 'Availability' } & Pick<Availability, 'OK' | 'msg' | 'status'>
                  >;
                }
            >;
            prices?: Maybe<
              { __typename?: 'Prices' } & Pick<
                Prices,
                'sale' | 'clearing' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
              >
            >;
            book?: Maybe<
              { __typename?: 'BookInfo' } & Pick<BookInfo, 'lang'> & {
                  authors?: Maybe<
                    Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type RelatedProductsQueryVariables = Exact<{
  idUser?: Maybe<Scalars['String']>;
  eanProduct: Scalars['String'];
}>;

export type RelatedProductsQuery = { __typename?: 'RootQuery' } & {
  relatedProducts?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Book' } & Pick<
          Book,
          | 'id'
          | '_id'
          | 'titleFriendly'
          | 'title'
          | 'ean'
          | 'mainImg'
          | 'ebookContent'
          | 'product_type'
          | 'priceWithDiscount'
        > & {
            relatedInfo?: Maybe<
              { __typename?: 'Book' } & Pick<
                Book,
                | '_id'
                | 'id'
                | 'product_type'
                | 'priceWithDiscount'
                | 'ean'
                | 'title'
                | 'titleFriendly'
                | 'ebookContent'
                | 'weight'
              > & {
                  book?: Maybe<
                    { __typename?: 'BookInfo' } & Pick<BookInfo, 'pages' | 'lang' | 'binding'> & {
                        authors?: Maybe<
                          Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                        >;
                      }
                  >;
                  prices?: Maybe<
                    { __typename?: 'Prices' } & Pick<
                      Prices,
                      'sale' | 'clearing' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
                    >
                  >;
                  availability?: Maybe<
                    { __typename?: 'Availability' } & Pick<Availability, 'OK' | 'msg' | 'status'>
                  >;
                }
            >;
            prices?: Maybe<
              { __typename?: 'Prices' } & Pick<
                Prices,
                'sale' | 'clearing' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
              >
            >;
            book?: Maybe<
              { __typename?: 'BookInfo' } & Pick<BookInfo, 'lang'> & {
                  authors?: Maybe<
                    Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type GetRecentViewsQueryVariables = Exact<{
  idUser?: Maybe<Scalars['String']>;
}>;

export type GetRecentViewsQuery = { __typename?: 'RootQuery' } & {
  getRecentViews?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Book' } & Pick<
          Book,
          | 'id'
          | '_id'
          | 'titleFriendly'
          | 'title'
          | 'ean'
          | 'mainImg'
          | 'product_type'
          | 'priceWithDiscount'
        > & {
            prices?: Maybe<
              { __typename?: 'Prices' } & Pick<
                Prices,
                'sale' | 'clearing' | 'saleSpecialDiscount' | 'ssdFrom' | 'ssdTo'
              >
            >;
            book?: Maybe<
              { __typename?: 'BookInfo' } & Pick<BookInfo, 'lang'> & {
                  authors?: Maybe<
                    Array<Maybe<{ __typename?: 'Author' } & Pick<Author, '_id' | 'name'>>>
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type GetRateRequestQueryVariables = Exact<{
  _id?: Maybe<Scalars['ID']>;
  destinationAddress?: Maybe<OrderDeliveryAddressNew>;
  package?: Maybe<NewPackage>;
  totalAmount?: Maybe<Scalars['Float']>;
}>;

export type GetRateRequestQuery = { __typename?: 'RootQuery' } & {
  getRateRequest?: Maybe<
    { __typename?: 'AmountDelivery' } & Pick<
      AmountDelivery,
      'currency' | 'amount' | 'deliveryTime' | 'error'
    >
  >;
};

export type GetAreasFromNewsletterQueryVariables = Exact<{
  email?: Maybe<Scalars['String']>;
}>;

export type GetAreasFromNewsletterQuery = { __typename?: 'RootQuery' } & {
  getAreasFromNewsletter?: Maybe<Array<Maybe<{ __typename?: 'Area' } & Pick<Area, 'id'>>>>;
};

export type GetUserAreasQueryVariables = Exact<{
  email?: Maybe<Scalars['String']>;
}>;

export type GetUserAreasQuery = { __typename?: 'RootQuery' } & {
  getUserAreas?: Maybe<
    { __typename?: 'NewsletterStatus' } & Pick<NewsletterStatus, 'status' | 'areaOfInterest'>
  >;
};

export type SignUpMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  profile?: Maybe<UserProfileNew>;
}>;

export type SignUpMutation = { __typename?: 'RootMutation' } & {
  signUp?: Maybe<
    { __typename?: 'Auth' } & Pick<Auth, 'token' | 'OK' | 'error'> & {
        me?: Maybe<
          { __typename?: 'User' } & Pick<User, '_id' | 'email'> & {
              profile?: Maybe<
                { __typename?: 'UserProfile' } & Pick<
                  UserProfile,
                  'alias' | 'firstName' | 'lastName'
                > & {
                    basket?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'Basket' } & Pick<Basket, '_id' | 'ean' | 'units'> & {
                              book?: Maybe<{ __typename?: 'Book' } & Pick<Book, '_id' | 'title'>>;
                            }
                        >
                      >
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export const UpdateDirectionDocument = gql`
  mutation updateDirection($_id: ID!, $index: Int, $newAddressVar: NewAddress!) {
    updateDirection(_id: $_id, index: $index, newAddressVar: $newAddressVar) {
      _id
      email
      profile {
        alias
        firstName
        lastName
        phone
        mobile
        basket {
          _id
          ean
          units
          book {
            priceWithDiscount
            _id
            title
          }
        }
        areaOfInterest
        shopping_default
        shoppingAddress {
          name
          address
          route
          streetNumber
          floor
          postalCode
          locality
          province
          country
          comment
          deliverTo
          isOutUE
          countryCode
          location {
            lat
            lng
          }
          cif
          businessName
        }
      }
    }
  }
`;
export type UpdateDirectionMutationFn = Apollo.MutationFunction<
  UpdateDirectionMutation,
  UpdateDirectionMutationVariables
>;

/**
 * __useUpdateDirectionMutation__
 *
 * To run a mutation, you first call `useUpdateDirectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirectionMutation, { data, loading, error }] = useUpdateDirectionMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      index: // value for 'index'
 *      newAddressVar: // value for 'newAddressVar'
 *   },
 * });
 */
export function useUpdateDirectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDirectionMutation,
    UpdateDirectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDirectionMutation, UpdateDirectionMutationVariables>(
    UpdateDirectionDocument,
    options,
  );
}
export type UpdateDirectionMutationHookResult = ReturnType<typeof useUpdateDirectionMutation>;
export type UpdateDirectionMutationResult = Apollo.MutationResult<UpdateDirectionMutation>;
export type UpdateDirectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateDirectionMutation,
  UpdateDirectionMutationVariables
>;
export const UpdateBillingDataDocument = gql`
  mutation updateBillingData($_id: ID!, $index: Int, $newAddressVar: NewAddress!) {
    updateBillingData(_id: $_id, index: $index, newAddressVar: $newAddressVar) {
      _id
      email
      profile {
        alias
        firstName
        lastName
        phone
        mobile
        basket {
          _id
          ean
          units
          book {
            priceWithDiscount
            _id
            title
          }
        }
        areaOfInterest
        shopping_default
        shoppingAddress {
          name
          address
          route
          streetNumber
          floor
          postalCode
          locality
          province
          country
          comment
          deliverTo
          isOutUE
          countryCode
          location {
            lat
            lng
          }
          cif
          businessName
        }
        billing_default
        billingData {
          address
          route
          streetNumber
          floor
          postalCode
          locality
          province
          country
          countryCode
          cif
          businessName
        }
      }
    }
  }
`;
export type UpdateBillingDataMutationFn = Apollo.MutationFunction<
  UpdateBillingDataMutation,
  UpdateBillingDataMutationVariables
>;

/**
 * __useUpdateBillingDataMutation__
 *
 * To run a mutation, you first call `useUpdateBillingDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingDataMutation, { data, loading, error }] = useUpdateBillingDataMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      index: // value for 'index'
 *      newAddressVar: // value for 'newAddressVar'
 *   },
 * });
 */
export function useUpdateBillingDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBillingDataMutation,
    UpdateBillingDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBillingDataMutation, UpdateBillingDataMutationVariables>(
    UpdateBillingDataDocument,
    options,
  );
}
export type UpdateBillingDataMutationHookResult = ReturnType<typeof useUpdateBillingDataMutation>;
export type UpdateBillingDataMutationResult = Apollo.MutationResult<UpdateBillingDataMutation>;
export type UpdateBillingDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateBillingDataMutation,
  UpdateBillingDataMutationVariables
>;
export const RememberPasswordDocument = gql`
  mutation rememberPassword($email: String!) {
    rememberPassword(email: $email) {
      OK
    }
  }
`;
export type RememberPasswordMutationFn = Apollo.MutationFunction<
  RememberPasswordMutation,
  RememberPasswordMutationVariables
>;

/**
 * __useRememberPasswordMutation__
 *
 * To run a mutation, you first call `useRememberPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRememberPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rememberPasswordMutation, { data, loading, error }] = useRememberPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRememberPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RememberPasswordMutation,
    RememberPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RememberPasswordMutation, RememberPasswordMutationVariables>(
    RememberPasswordDocument,
    options,
  );
}
export type RememberPasswordMutationHookResult = ReturnType<typeof useRememberPasswordMutation>;
export type RememberPasswordMutationResult = Apollo.MutationResult<RememberPasswordMutation>;
export type RememberPasswordMutationOptions = Apollo.BaseMutationOptions<
  RememberPasswordMutation,
  RememberPasswordMutationVariables
>;
export const SetAreaOfInterestDocument = gql`
  mutation setAreaOfInterest($_id: ID!, $numberAreaOfInterest: Int) {
    setAreaOfInterest(_id: $_id, numberAreaOfInterest: $numberAreaOfInterest) {
      _id
      email
      profile {
        areaOfInterest
      }
    }
  }
`;
export type SetAreaOfInterestMutationFn = Apollo.MutationFunction<
  SetAreaOfInterestMutation,
  SetAreaOfInterestMutationVariables
>;

/**
 * __useSetAreaOfInterestMutation__
 *
 * To run a mutation, you first call `useSetAreaOfInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAreaOfInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAreaOfInterestMutation, { data, loading, error }] = useSetAreaOfInterestMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      numberAreaOfInterest: // value for 'numberAreaOfInterest'
 *   },
 * });
 */
export function useSetAreaOfInterestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAreaOfInterestMutation,
    SetAreaOfInterestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetAreaOfInterestMutation, SetAreaOfInterestMutationVariables>(
    SetAreaOfInterestDocument,
    options,
  );
}
export type SetAreaOfInterestMutationHookResult = ReturnType<typeof useSetAreaOfInterestMutation>;
export type SetAreaOfInterestMutationResult = Apollo.MutationResult<SetAreaOfInterestMutation>;
export type SetAreaOfInterestMutationOptions = Apollo.BaseMutationOptions<
  SetAreaOfInterestMutation,
  SetAreaOfInterestMutationVariables
>;
export const SubscribeToNewsletterDocument = gql`
  mutation subscribeToNewsletter(
    $_id: ID
    $email: String!
    $areaOfInterest: [Int]
    $active: Boolean
  ) {
    subscribeToNewsletter(
      _id: $_id
      email: $email
      areaOfInterest: $areaOfInterest
      active: $active
    ) {
      _id
      email
      profile {
        newsletter {
          areaOfInterest
        }
      }
    }
  }
`;
export type SubscribeToNewsletterMutationFn = Apollo.MutationFunction<
  SubscribeToNewsletterMutation,
  SubscribeToNewsletterMutationVariables
>;

/**
 * __useSubscribeToNewsletterMutation__
 *
 * To run a mutation, you first call `useSubscribeToNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToNewsletterMutation, { data, loading, error }] = useSubscribeToNewsletterMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      email: // value for 'email'
 *      areaOfInterest: // value for 'areaOfInterest'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useSubscribeToNewsletterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeToNewsletterMutation,
    SubscribeToNewsletterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubscribeToNewsletterMutation, SubscribeToNewsletterMutationVariables>(
    SubscribeToNewsletterDocument,
    options,
  );
}
export type SubscribeToNewsletterMutationHookResult = ReturnType<
  typeof useSubscribeToNewsletterMutation
>;
export type SubscribeToNewsletterMutationResult =
  Apollo.MutationResult<SubscribeToNewsletterMutation>;
export type SubscribeToNewsletterMutationOptions = Apollo.BaseMutationOptions<
  SubscribeToNewsletterMutation,
  SubscribeToNewsletterMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($_id: ID!, $newUserVar: UserNew!) {
    updateUser(_id: $_id, newUserVar: $newUserVar) {
      _id
      email
      profile {
        alias
        firstName
        lastName
        phone
        mobile
        basket {
          _id
          ean
          units
          book {
            priceWithDiscount
            _id
            title
          }
        }
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      newUserVar: // value for 'newUserVar'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($_id: ID!) {
    deleteUser(_id: $_id) {
      OK
      error
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const DeleteAddressDocument = gql`
  mutation deleteAddress($_id: ID!, $index: Int!) {
    deleteAddress(_id: $_id, index: $index) {
      _id
      email
      profile {
        alias
        firstName
        lastName
        areaOfInterest
        newsletter {
          areaOfInterest
        }
        basket {
          _id
          ean
          units
          book {
            priceWithDiscount
            product_type
            titleFriendly
            _id
            title
          }
        }
        shopping_default
        shoppingAddress {
          name
          address
          route
          streetNumber
          floor
          postalCode
          locality
          province
          country
          comment
          countryCode
          deliverTo
          isOutUE
          location {
            lat
            lng
          }
          cif
          businessName
        }
      }
    }
  }
`;
export type DeleteAddressMutationFn = Apollo.MutationFunction<
  DeleteAddressMutation,
  DeleteAddressMutationVariables
>;

/**
 * __useDeleteAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDeleteAddressMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useDeleteAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAddressMutation, DeleteAddressMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAddressMutation, DeleteAddressMutationVariables>(
    DeleteAddressDocument,
    options,
  );
}
export type DeleteAddressMutationHookResult = ReturnType<typeof useDeleteAddressMutation>;
export type DeleteAddressMutationResult = Apollo.MutationResult<DeleteAddressMutation>;
export type DeleteAddressMutationOptions = Apollo.BaseMutationOptions<
  DeleteAddressMutation,
  DeleteAddressMutationVariables
>;
export const DeleteBillingDataDocument = gql`
  mutation deleteBillingData($_id: ID!, $index: Int!) {
    deleteBillingData(_id: $_id, index: $index) {
      _id
      email
      profile {
        alias
        firstName
        lastName
        areaOfInterest
        newsletter {
          areaOfInterest
        }
        basket {
          _id
          ean
          units
          book {
            priceWithDiscount
            product_type
            titleFriendly
            _id
            title
          }
        }
        shopping_default
        shoppingAddress {
          name
          address
          route
          streetNumber
          floor
          postalCode
          locality
          province
          country
          comment
          countryCode
          deliverTo
          isOutUE
          location {
            lat
            lng
          }
          cif
          businessName
        }
        billing_default
        billingData {
          address
          route
          streetNumber
          floor
          postalCode
          locality
          province
          country
          countryCode
          cif
          businessName
        }
      }
    }
  }
`;
export type DeleteBillingDataMutationFn = Apollo.MutationFunction<
  DeleteBillingDataMutation,
  DeleteBillingDataMutationVariables
>;

/**
 * __useDeleteBillingDataMutation__
 *
 * To run a mutation, you first call `useDeleteBillingDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillingDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillingDataMutation, { data, loading, error }] = useDeleteBillingDataMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useDeleteBillingDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBillingDataMutation,
    DeleteBillingDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBillingDataMutation, DeleteBillingDataMutationVariables>(
    DeleteBillingDataDocument,
    options,
  );
}
export type DeleteBillingDataMutationHookResult = ReturnType<typeof useDeleteBillingDataMutation>;
export type DeleteBillingDataMutationResult = Apollo.MutationResult<DeleteBillingDataMutation>;
export type DeleteBillingDataMutationOptions = Apollo.BaseMutationOptions<
  DeleteBillingDataMutation,
  DeleteBillingDataMutationVariables
>;
export const AddToBasketDocument = gql`
  mutation addToBasket($idUser: ID!, $idProduct: String!, $ean: String!, $units: Int) {
    addToBasket(idUser: $idUser, idProduct: $idProduct, ean: $ean, units: $units) {
      _id
      profile {
        basket {
          _id
          ean
          product_type
          units
        }
      }
    }
  }
`;
export type AddToBasketMutationFn = Apollo.MutationFunction<
  AddToBasketMutation,
  AddToBasketMutationVariables
>;

/**
 * __useAddToBasketMutation__
 *
 * To run a mutation, you first call `useAddToBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToBasketMutation, { data, loading, error }] = useAddToBasketMutation({
 *   variables: {
 *      idUser: // value for 'idUser'
 *      idProduct: // value for 'idProduct'
 *      ean: // value for 'ean'
 *      units: // value for 'units'
 *   },
 * });
 */
export function useAddToBasketMutation(
  baseOptions?: Apollo.MutationHookOptions<AddToBasketMutation, AddToBasketMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddToBasketMutation, AddToBasketMutationVariables>(
    AddToBasketDocument,
    options,
  );
}
export type AddToBasketMutationHookResult = ReturnType<typeof useAddToBasketMutation>;
export type AddToBasketMutationResult = Apollo.MutationResult<AddToBasketMutation>;
export type AddToBasketMutationOptions = Apollo.BaseMutationOptions<
  AddToBasketMutation,
  AddToBasketMutationVariables
>;
export const ModifyToBasketDocument = gql`
  mutation modifyToBasket($idUser: ID!, $idProduct: String!, $operation: Boolean) {
    modifyToBasket(idUser: $idUser, idProduct: $idProduct, operation: $operation) {
      _id
      profile {
        basket {
          _id
          ean
          units
          product_type
          book {
            _id
            ean
            priceWithDiscount
            titleFriendly
            reposition_level
            product_type
            stock_available
            availability {
              OK
              msg
              status
            }
            title
            weight
            brand {
              name
            }
            dimensions {
              depth
              width
              height
            }
            prices {
              sale
            }
            vat {
              sale
            }
            mainImg
          }
        }
      }
    }
  }
`;
export type ModifyToBasketMutationFn = Apollo.MutationFunction<
  ModifyToBasketMutation,
  ModifyToBasketMutationVariables
>;

/**
 * __useModifyToBasketMutation__
 *
 * To run a mutation, you first call `useModifyToBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyToBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyToBasketMutation, { data, loading, error }] = useModifyToBasketMutation({
 *   variables: {
 *      idUser: // value for 'idUser'
 *      idProduct: // value for 'idProduct'
 *      operation: // value for 'operation'
 *   },
 * });
 */
export function useModifyToBasketMutation(
  baseOptions?: Apollo.MutationHookOptions<ModifyToBasketMutation, ModifyToBasketMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ModifyToBasketMutation, ModifyToBasketMutationVariables>(
    ModifyToBasketDocument,
    options,
  );
}
export type ModifyToBasketMutationHookResult = ReturnType<typeof useModifyToBasketMutation>;
export type ModifyToBasketMutationResult = Apollo.MutationResult<ModifyToBasketMutation>;
export type ModifyToBasketMutationOptions = Apollo.BaseMutationOptions<
  ModifyToBasketMutation,
  ModifyToBasketMutationVariables
>;
export const RemoveToBasketDocument = gql`
  mutation removeToBasket($idUser: ID!, $idProduct: String!) {
    removeToBasket(idUser: $idUser, idProduct: $idProduct) {
      _id
      profile {
        basket {
          _id
          ean
          units
          book {
            _id
            ean
            priceWithDiscount
            titleFriendly
            reposition_level
            product_type
            stock_available
            availability {
              OK
              msg
              status
            }
            title
            weight
            brand {
              name
            }
            dimensions {
              depth
              width
              height
            }
            prices {
              sale
            }
            vat {
              sale
            }
            mainImg
          }
        }
      }
    }
  }
`;
export type RemoveToBasketMutationFn = Apollo.MutationFunction<
  RemoveToBasketMutation,
  RemoveToBasketMutationVariables
>;

/**
 * __useRemoveToBasketMutation__
 *
 * To run a mutation, you first call `useRemoveToBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveToBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeToBasketMutation, { data, loading, error }] = useRemoveToBasketMutation({
 *   variables: {
 *      idUser: // value for 'idUser'
 *      idProduct: // value for 'idProduct'
 *   },
 * });
 */
export function useRemoveToBasketMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveToBasketMutation, RemoveToBasketMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveToBasketMutation, RemoveToBasketMutationVariables>(
    RemoveToBasketDocument,
    options,
  );
}
export type RemoveToBasketMutationHookResult = ReturnType<typeof useRemoveToBasketMutation>;
export type RemoveToBasketMutationResult = Apollo.MutationResult<RemoveToBasketMutation>;
export type RemoveToBasketMutationOptions = Apollo.BaseMutationOptions<
  RemoveToBasketMutation,
  RemoveToBasketMutationVariables
>;
export const NewOrderDocument = gql`
  mutation newOrder($newOrderVar: OrderNew!) {
    newOrder(newOrderVar: $newOrderVar) {
      _id
      order
      userId
      amountIVA
      orderSequence
      amountWithoutIVA
      deliveryAddress {
        name
        cif
        address
        route
        streetNumber
        floor
        postalCode
        locality
        province
        country
        comment
        countryCode
        isOutUE
      }
      transport
      amountTransport
      amount
      methodPayment
      fullNameUser
      fullNameUserClean
      legalNameFiscalData
      fiscalDoc
      state_order
      amountDiscount
      discountIdCode
      discountIdCodeWork
      confirmDate
      autorizationCode
      responseCode
      otherTaxes {
        amount
        description
        name
      }
      order_multiple
      giftCardCode
      errorCode
      deleted
      items {
        _id
        ean
        units
        priceSign
        priceSignDeal
        itemState
        canceledDate
        book {
          priceWithDiscount
          lastBuyPrice
          exchangeRate
          buyExpenses
          _id
          id
          title
          prices {
            sale
          }
          vat {
            sale
          }
        }
      }
      createdAt
    }
  }
`;
export type NewOrderMutationFn = Apollo.MutationFunction<
  NewOrderMutation,
  NewOrderMutationVariables
>;

/**
 * __useNewOrderMutation__
 *
 * To run a mutation, you first call `useNewOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newOrderMutation, { data, loading, error }] = useNewOrderMutation({
 *   variables: {
 *      newOrderVar: // value for 'newOrderVar'
 *   },
 * });
 */
export function useNewOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<NewOrderMutation, NewOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NewOrderMutation, NewOrderMutationVariables>(NewOrderDocument, options);
}
export type NewOrderMutationHookResult = ReturnType<typeof useNewOrderMutation>;
export type NewOrderMutationResult = Apollo.MutationResult<NewOrderMutation>;
export type NewOrderMutationOptions = Apollo.BaseMutationOptions<
  NewOrderMutation,
  NewOrderMutationVariables
>;
export const NewEbookOrderDocument = gql`
  mutation newEbookOrder($newOrderVar: OrderNew!) {
    newEbookOrder(newOrderVar: $newOrderVar) {
      _id
      order
      userId
      amountIVA
      orderSequence
      amountWithoutIVA
      amount
      methodPayment
      fullNameUser
      fullNameUserClean
      legalNameFiscalData
      fiscalDoc
      state_order
      amountDiscount
      discountIdCode
      discountIdCodeWork
      confirmDate
      autorizationCode
      responseCode
      otherTaxes {
        amount
        description
        name
      }
      order_multiple
      giftCardCode
      errorCode
      deleted
      items {
        _id
        ean
        units
        priceSign
        priceSignDeal
        itemState
        canceledDate
        book {
          priceWithDiscount
          lastBuyPrice
          exchangeRate
          buyExpenses
          _id
          id
          title
          prices {
            sale
          }
          vat {
            sale
          }
        }
      }
      createdAt
    }
  }
`;
export type NewEbookOrderMutationFn = Apollo.MutationFunction<
  NewEbookOrderMutation,
  NewEbookOrderMutationVariables
>;

/**
 * __useNewEbookOrderMutation__
 *
 * To run a mutation, you first call `useNewEbookOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewEbookOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newEbookOrderMutation, { data, loading, error }] = useNewEbookOrderMutation({
 *   variables: {
 *      newOrderVar: // value for 'newOrderVar'
 *   },
 * });
 */
export function useNewEbookOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<NewEbookOrderMutation, NewEbookOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NewEbookOrderMutation, NewEbookOrderMutationVariables>(
    NewEbookOrderDocument,
    options,
  );
}
export type NewEbookOrderMutationHookResult = ReturnType<typeof useNewEbookOrderMutation>;
export type NewEbookOrderMutationResult = Apollo.MutationResult<NewEbookOrderMutation>;
export type NewEbookOrderMutationOptions = Apollo.BaseMutationOptions<
  NewEbookOrderMutation,
  NewEbookOrderMutationVariables
>;
export const CreatePaymentDocument = gql`
  mutation createPayment(
    $method: String
    $items: [OrderItemsNew]!
    $total: Float!
    $token: String
    $email: String
    $coupon: String
    $order_id: ID
    $state_order: String
    $orderSequence: Int!
    $amountTransport: Float!
    $isEbookCart: Boolean
  ) {
    createPayment(
      method: $method
      items: $items
      total: $total
      token: $token
      email: $email
      coupon: $coupon
      order_id: $order_id
      state_order: $state_order
      orderSequence: $orderSequence
      amountTransport: $amountTransport
      isEbookCart: $isEbookCart
    ) {
      id
      status
      errorCode
      outcome {
        network_status
        reason
        risk_level
        seller_message
        type
      }
      payer {
        payment_method
      }
      links {
        href
        method
      }
      redirect_urls
    }
  }
`;
export type CreatePaymentMutationFn = Apollo.MutationFunction<
  CreatePaymentMutation,
  CreatePaymentMutationVariables
>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      method: // value for 'method'
 *      items: // value for 'items'
 *      total: // value for 'total'
 *      token: // value for 'token'
 *      email: // value for 'email'
 *      coupon: // value for 'coupon'
 *      order_id: // value for 'order_id'
 *      state_order: // value for 'state_order'
 *      orderSequence: // value for 'orderSequence'
 *      amountTransport: // value for 'amountTransport'
 *      isEbookCart: // value for 'isEbookCart'
 *   },
 * });
 */
export function useCreatePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(
    CreatePaymentDocument,
    options,
  );
}
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentMutation,
  CreatePaymentMutationVariables
>;
export const ExecutePaymentDocument = gql`
  mutation executePayment($paymentId: String, $payerID: String) {
    executePayment(paymentId: $paymentId, payerID: $payerID) {
      id
    }
  }
`;
export type ExecutePaymentMutationFn = Apollo.MutationFunction<
  ExecutePaymentMutation,
  ExecutePaymentMutationVariables
>;

/**
 * __useExecutePaymentMutation__
 *
 * To run a mutation, you first call `useExecutePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecutePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executePaymentMutation, { data, loading, error }] = useExecutePaymentMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *      payerID: // value for 'payerID'
 *   },
 * });
 */
export function useExecutePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<ExecutePaymentMutation, ExecutePaymentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExecutePaymentMutation, ExecutePaymentMutationVariables>(
    ExecutePaymentDocument,
    options,
  );
}
export type ExecutePaymentMutationHookResult = ReturnType<typeof useExecutePaymentMutation>;
export type ExecutePaymentMutationResult = Apollo.MutationResult<ExecutePaymentMutation>;
export type ExecutePaymentMutationOptions = Apollo.BaseMutationOptions<
  ExecutePaymentMutation,
  ExecutePaymentMutationVariables
>;
export const Get3DTokenDocument = gql`
  mutation get3DToken {
    get3DToken {
      token
    }
  }
`;
export type Get3DTokenMutationFn = Apollo.MutationFunction<
  Get3DTokenMutation,
  Get3DTokenMutationVariables
>;

/**
 * __useGet3DTokenMutation__
 *
 * To run a mutation, you first call `useGet3DTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGet3DTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [get3DTokenMutation, { data, loading, error }] = useGet3DTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useGet3DTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<Get3DTokenMutation, Get3DTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Get3DTokenMutation, Get3DTokenMutationVariables>(
    Get3DTokenDocument,
    options,
  );
}
export type Get3DTokenMutationHookResult = ReturnType<typeof useGet3DTokenMutation>;
export type Get3DTokenMutationResult = Apollo.MutationResult<Get3DTokenMutation>;
export type Get3DTokenMutationOptions = Apollo.BaseMutationOptions<
  Get3DTokenMutation,
  Get3DTokenMutationVariables
>;
export const SaveRefundRequestDocument = gql`
  mutation saveRefundRequest($data: RefundData!) {
    saveRefundRequest(data: $data) {
      OK
    }
  }
`;
export type SaveRefundRequestMutationFn = Apollo.MutationFunction<
  SaveRefundRequestMutation,
  SaveRefundRequestMutationVariables
>;

/**
 * __useSaveRefundRequestMutation__
 *
 * To run a mutation, you first call `useSaveRefundRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRefundRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRefundRequestMutation, { data, loading, error }] = useSaveRefundRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveRefundRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveRefundRequestMutation,
    SaveRefundRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveRefundRequestMutation, SaveRefundRequestMutationVariables>(
    SaveRefundRequestDocument,
    options,
  );
}
export type SaveRefundRequestMutationHookResult = ReturnType<typeof useSaveRefundRequestMutation>;
export type SaveRefundRequestMutationResult = Apollo.MutationResult<SaveRefundRequestMutation>;
export type SaveRefundRequestMutationOptions = Apollo.BaseMutationOptions<
  SaveRefundRequestMutation,
  SaveRefundRequestMutationVariables
>;
export const EmptyBasketDocument = gql`
  mutation emptyBasket($idUser: ID!) {
    emptyBasket(idUser: $idUser) {
      _id
      profile {
        basket {
          _id
          ean
          units
          book {
            _id
            id
            title
            prices {
              sale
            }
            vat {
              sale
            }
          }
        }
      }
    }
  }
`;
export type EmptyBasketMutationFn = Apollo.MutationFunction<
  EmptyBasketMutation,
  EmptyBasketMutationVariables
>;

/**
 * __useEmptyBasketMutation__
 *
 * To run a mutation, you first call `useEmptyBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmptyBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emptyBasketMutation, { data, loading, error }] = useEmptyBasketMutation({
 *   variables: {
 *      idUser: // value for 'idUser'
 *   },
 * });
 */
export function useEmptyBasketMutation(
  baseOptions?: Apollo.MutationHookOptions<EmptyBasketMutation, EmptyBasketMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EmptyBasketMutation, EmptyBasketMutationVariables>(
    EmptyBasketDocument,
    options,
  );
}
export type EmptyBasketMutationHookResult = ReturnType<typeof useEmptyBasketMutation>;
export type EmptyBasketMutationResult = Apollo.MutationResult<EmptyBasketMutation>;
export type EmptyBasketMutationOptions = Apollo.BaseMutationOptions<
  EmptyBasketMutation,
  EmptyBasketMutationVariables
>;
export const LoginDocument = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      OK
      error
      me {
        _id
        email
        profile {
          alias
          firstName
          lastName
          basket {
            _id
            ean
            units
            book {
              _id
              title
            }
          }
        }
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const InsertClickDocument = gql`
  mutation insertClick($eanProduct: String!) {
    insertClick(eanProduct: $eanProduct) {
      _id
    }
  }
`;
export type InsertClickMutationFn = Apollo.MutationFunction<
  InsertClickMutation,
  InsertClickMutationVariables
>;

/**
 * __useInsertClickMutation__
 *
 * To run a mutation, you first call `useInsertClickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClickMutation, { data, loading, error }] = useInsertClickMutation({
 *   variables: {
 *      eanProduct: // value for 'eanProduct'
 *   },
 * });
 */
export function useInsertClickMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertClickMutation, InsertClickMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertClickMutation, InsertClickMutationVariables>(
    InsertClickDocument,
    options,
  );
}
export type InsertClickMutationHookResult = ReturnType<typeof useInsertClickMutation>;
export type InsertClickMutationResult = Apollo.MutationResult<InsertClickMutation>;
export type InsertClickMutationOptions = Apollo.BaseMutationOptions<
  InsertClickMutation,
  InsertClickMutationVariables
>;
export const InsertClickIdDocument = gql`
  mutation insertClickID($idProduct: String!) {
    insertClickID(idProduct: $idProduct) {
      _id
    }
  }
`;
export type InsertClickIdMutationFn = Apollo.MutationFunction<
  InsertClickIdMutation,
  InsertClickIdMutationVariables
>;

/**
 * __useInsertClickIdMutation__
 *
 * To run a mutation, you first call `useInsertClickIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClickIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClickIdMutation, { data, loading, error }] = useInsertClickIdMutation({
 *   variables: {
 *      idProduct: // value for 'idProduct'
 *   },
 * });
 */
export function useInsertClickIdMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertClickIdMutation, InsertClickIdMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertClickIdMutation, InsertClickIdMutationVariables>(
    InsertClickIdDocument,
    options,
  );
}
export type InsertClickIdMutationHookResult = ReturnType<typeof useInsertClickIdMutation>;
export type InsertClickIdMutationResult = Apollo.MutationResult<InsertClickIdMutation>;
export type InsertClickIdMutationOptions = Apollo.BaseMutationOptions<
  InsertClickIdMutation,
  InsertClickIdMutationVariables
>;
export const InsertRecentViewDocument = gql`
  mutation insertRecentView($eanProduct: String, $userId: String) {
    insertRecentView(eanProduct: $eanProduct, userId: $userId) {
      _id
      lastViews {
        ean
        dateAdded
      }
    }
  }
`;
export type InsertRecentViewMutationFn = Apollo.MutationFunction<
  InsertRecentViewMutation,
  InsertRecentViewMutationVariables
>;

/**
 * __useInsertRecentViewMutation__
 *
 * To run a mutation, you first call `useInsertRecentViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRecentViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRecentViewMutation, { data, loading, error }] = useInsertRecentViewMutation({
 *   variables: {
 *      eanProduct: // value for 'eanProduct'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInsertRecentViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertRecentViewMutation,
    InsertRecentViewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertRecentViewMutation, InsertRecentViewMutationVariables>(
    InsertRecentViewDocument,
    options,
  );
}
export type InsertRecentViewMutationHookResult = ReturnType<typeof useInsertRecentViewMutation>;
export type InsertRecentViewMutationResult = Apollo.MutationResult<InsertRecentViewMutation>;
export type InsertRecentViewMutationOptions = Apollo.BaseMutationOptions<
  InsertRecentViewMutation,
  InsertRecentViewMutationVariables
>;
export const SuscriptionRequestDocument = gql`
  mutation suscriptionRequest($user_id: ID!, $pack_id: String!, $message: String!) {
    suscriptionRequest(user_id: $user_id, pack_id: $pack_id, message: $message) {
      _id
    }
  }
`;
export type SuscriptionRequestMutationFn = Apollo.MutationFunction<
  SuscriptionRequestMutation,
  SuscriptionRequestMutationVariables
>;

/**
 * __useSuscriptionRequestMutation__
 *
 * To run a mutation, you first call `useSuscriptionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuscriptionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suscriptionRequestMutation, { data, loading, error }] = useSuscriptionRequestMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      pack_id: // value for 'pack_id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSuscriptionRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SuscriptionRequestMutation,
    SuscriptionRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SuscriptionRequestMutation, SuscriptionRequestMutationVariables>(
    SuscriptionRequestDocument,
    options,
  );
}
export type SuscriptionRequestMutationHookResult = ReturnType<typeof useSuscriptionRequestMutation>;
export type SuscriptionRequestMutationResult = Apollo.MutationResult<SuscriptionRequestMutation>;
export type SuscriptionRequestMutationOptions = Apollo.BaseMutationOptions<
  SuscriptionRequestMutation,
  SuscriptionRequestMutationVariables
>;
export const CancelNewsletterDocument = gql`
  mutation cancelNewsletter($email: String!) {
    cancelNewsletter(email: $email) {
      OK
      message
    }
  }
`;
export type CancelNewsletterMutationFn = Apollo.MutationFunction<
  CancelNewsletterMutation,
  CancelNewsletterMutationVariables
>;

/**
 * __useCancelNewsletterMutation__
 *
 * To run a mutation, you first call `useCancelNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelNewsletterMutation, { data, loading, error }] = useCancelNewsletterMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCancelNewsletterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelNewsletterMutation,
    CancelNewsletterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelNewsletterMutation, CancelNewsletterMutationVariables>(
    CancelNewsletterDocument,
    options,
  );
}
export type CancelNewsletterMutationHookResult = ReturnType<typeof useCancelNewsletterMutation>;
export type CancelNewsletterMutationResult = Apollo.MutationResult<CancelNewsletterMutation>;
export type CancelNewsletterMutationOptions = Apollo.BaseMutationOptions<
  CancelNewsletterMutation,
  CancelNewsletterMutationVariables
>;
export const OptInSuscriptionDocument = gql`
  mutation optInSuscription($_id: ID!, $email: String!) {
    optInSuscription(_id: $_id, email: $email) {
      OK
    }
  }
`;
export type OptInSuscriptionMutationFn = Apollo.MutationFunction<
  OptInSuscriptionMutation,
  OptInSuscriptionMutationVariables
>;

/**
 * __useOptInSuscriptionMutation__
 *
 * To run a mutation, you first call `useOptInSuscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptInSuscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optInSuscriptionMutation, { data, loading, error }] = useOptInSuscriptionMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useOptInSuscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OptInSuscriptionMutation,
    OptInSuscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OptInSuscriptionMutation, OptInSuscriptionMutationVariables>(
    OptInSuscriptionDocument,
    options,
  );
}
export type OptInSuscriptionMutationHookResult = ReturnType<typeof useOptInSuscriptionMutation>;
export type OptInSuscriptionMutationResult = Apollo.MutationResult<OptInSuscriptionMutation>;
export type OptInSuscriptionMutationOptions = Apollo.BaseMutationOptions<
  OptInSuscriptionMutation,
  OptInSuscriptionMutationVariables
>;
export const CouponCheckDocument = gql`
  query couponCheck($coupon: String!, $items: [String]) {
    couponCheck(coupon: $coupon, items: $items) {
      _id
      coupon
      discount
      value
      productType
      accountCode
    }
  }
`;

/**
 * __useCouponCheckQuery__
 *
 * To run a query within a React component, call `useCouponCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponCheckQuery({
 *   variables: {
 *      coupon: // value for 'coupon'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useCouponCheckQuery(
  baseOptions: Apollo.QueryHookOptions<CouponCheckQuery, CouponCheckQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CouponCheckQuery, CouponCheckQueryVariables>(CouponCheckDocument, options);
}
export function useCouponCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CouponCheckQuery, CouponCheckQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CouponCheckQuery, CouponCheckQueryVariables>(
    CouponCheckDocument,
    options,
  );
}
export type CouponCheckQueryHookResult = ReturnType<typeof useCouponCheckQuery>;
export type CouponCheckLazyQueryHookResult = ReturnType<typeof useCouponCheckLazyQuery>;
export type CouponCheckQueryResult = Apollo.QueryResult<
  CouponCheckQuery,
  CouponCheckQueryVariables
>;
export const GetAreaNameDocument = gql`
  query getAreaName($id: Int!) {
    getAreaName(id: $id) {
      _id
      id
      nameEsFriendly
      nameEs
    }
  }
`;

/**
 * __useGetAreaNameQuery__
 *
 * To run a query within a React component, call `useGetAreaNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreaNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreaNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAreaNameQuery(
  baseOptions: Apollo.QueryHookOptions<GetAreaNameQuery, GetAreaNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAreaNameQuery, GetAreaNameQueryVariables>(GetAreaNameDocument, options);
}
export function useGetAreaNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAreaNameQuery, GetAreaNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAreaNameQuery, GetAreaNameQueryVariables>(
    GetAreaNameDocument,
    options,
  );
}
export type GetAreaNameQueryHookResult = ReturnType<typeof useGetAreaNameQuery>;
export type GetAreaNameLazyQueryHookResult = ReturnType<typeof useGetAreaNameLazyQuery>;
export type GetAreaNameQueryResult = Apollo.QueryResult<
  GetAreaNameQuery,
  GetAreaNameQueryVariables
>;
export const GetPrePublicationDocument = gql`
  query getPrePublication($product_type: String!, $area: Int) {
    getPrePublication(product_type: $product_type, area: $area) {
      _id
      id
      ean
      priceWithDiscount
      prices {
        sale
      }
      title
      titleFriendly
      authors {
        name
      }
      mainImg
    }
  }
`;

/**
 * __useGetPrePublicationQuery__
 *
 * To run a query within a React component, call `useGetPrePublicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrePublicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrePublicationQuery({
 *   variables: {
 *      product_type: // value for 'product_type'
 *      area: // value for 'area'
 *   },
 * });
 */
export function useGetPrePublicationQuery(
  baseOptions: Apollo.QueryHookOptions<GetPrePublicationQuery, GetPrePublicationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrePublicationQuery, GetPrePublicationQueryVariables>(
    GetPrePublicationDocument,
    options,
  );
}
export function useGetPrePublicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrePublicationQuery,
    GetPrePublicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrePublicationQuery, GetPrePublicationQueryVariables>(
    GetPrePublicationDocument,
    options,
  );
}
export type GetPrePublicationQueryHookResult = ReturnType<typeof useGetPrePublicationQuery>;
export type GetPrePublicationLazyQueryHookResult = ReturnType<typeof useGetPrePublicationLazyQuery>;
export type GetPrePublicationQueryResult = Apollo.QueryResult<
  GetPrePublicationQuery,
  GetPrePublicationQueryVariables
>;
export const GetImportantProductDocument = gql`
  query getImportantProduct($product_type: String!, $area: Int) {
    getImportantProduct(product_type: $product_type, area: $area) {
      _id
      id
      ean
      priceWithDiscount
      prices {
        sale
      }
      title
      titleFriendly
      authors {
        name
      }
      mainImg
    }
  }
`;

/**
 * __useGetImportantProductQuery__
 *
 * To run a query within a React component, call `useGetImportantProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImportantProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImportantProductQuery({
 *   variables: {
 *      product_type: // value for 'product_type'
 *      area: // value for 'area'
 *   },
 * });
 */
export function useGetImportantProductQuery(
  baseOptions: Apollo.QueryHookOptions<GetImportantProductQuery, GetImportantProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetImportantProductQuery, GetImportantProductQueryVariables>(
    GetImportantProductDocument,
    options,
  );
}
export function useGetImportantProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImportantProductQuery,
    GetImportantProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetImportantProductQuery, GetImportantProductQueryVariables>(
    GetImportantProductDocument,
    options,
  );
}
export type GetImportantProductQueryHookResult = ReturnType<typeof useGetImportantProductQuery>;
export type GetImportantProductLazyQueryHookResult = ReturnType<
  typeof useGetImportantProductLazyQuery
>;
export type GetImportantProductQueryResult = Apollo.QueryResult<
  GetImportantProductQuery,
  GetImportantProductQueryVariables
>;
export const GetAreasPredictiveDocument = gql`
  query getAreasPredictive($search: String!, $limit: Int!) {
    getAreasPredictive(search: $search, limit: $limit) {
      _id
      id
      nameEs
      nameEsFriendly
    }
  }
`;

/**
 * __useGetAreasPredictiveQuery__
 *
 * To run a query within a React component, call `useGetAreasPredictiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreasPredictiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreasPredictiveQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAreasPredictiveQuery(
  baseOptions: Apollo.QueryHookOptions<GetAreasPredictiveQuery, GetAreasPredictiveQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAreasPredictiveQuery, GetAreasPredictiveQueryVariables>(
    GetAreasPredictiveDocument,
    options,
  );
}
export function useGetAreasPredictiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAreasPredictiveQuery,
    GetAreasPredictiveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAreasPredictiveQuery, GetAreasPredictiveQueryVariables>(
    GetAreasPredictiveDocument,
    options,
  );
}
export type GetAreasPredictiveQueryHookResult = ReturnType<typeof useGetAreasPredictiveQuery>;
export type GetAreasPredictiveLazyQueryHookResult = ReturnType<
  typeof useGetAreasPredictiveLazyQuery
>;
export type GetAreasPredictiveQueryResult = Apollo.QueryResult<
  GetAreasPredictiveQuery,
  GetAreasPredictiveQueryVariables
>;
export const CheckStockItemDocument = gql`
  query checkStockItem($items: [String]!) {
    checkStockItem(items: $items) {
      _id
      title
      ean
      priceWithDiscount
      titleFriendly
      lastBuyPrice
      exchangeRate
      buyExpenses
      mainImg
      reposition_level
      stock_available
      availability {
        OK
        msg
        status
      }
      dimensions {
        depth
        width
        height
      }
      reposition_level
      book {
        description
        table_of_contents
        authors {
          _id
          name
        }
        lang
        pages
        binding
        edition {
          number
          year
          month
        }
      }
      brand {
        id
        name
      }
      product_type
      authors {
        name
      }
      not_released
      stock_available
      unavailable
      prices {
        sale
      }
      vat {
        sale
      }
      group_property_names
      suscription {
        email
      }
      tags {
        items
        category
      }
      themes_text
      defaultVariant
      variantChildren {
        _id
        id
        title
        ean
        priceWithDiscount
        titleFriendly
        dimensions {
          depth
          width
          height
        }
        reposition_level
        book {
          authors {
            _id
            name
          }
          lang
          pages
          binding
          edition {
            number
            year
            month
          }
        }
        brand {
          id
          name
        }
        product_type
        authors {
          name
        }
        not_released
        stock_available
        unavailable
        prices {
          sale
        }
        vat {
          sale
        }
        variant {
          group_id
          properties {
            color
            finish
          }
        }
      }
    }
  }
`;

/**
 * __useCheckStockItemQuery__
 *
 * To run a query within a React component, call `useCheckStockItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckStockItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckStockItemQuery({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useCheckStockItemQuery(
  baseOptions: Apollo.QueryHookOptions<CheckStockItemQuery, CheckStockItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckStockItemQuery, CheckStockItemQueryVariables>(
    CheckStockItemDocument,
    options,
  );
}
export function useCheckStockItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckStockItemQuery, CheckStockItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckStockItemQuery, CheckStockItemQueryVariables>(
    CheckStockItemDocument,
    options,
  );
}
export type CheckStockItemQueryHookResult = ReturnType<typeof useCheckStockItemQuery>;
export type CheckStockItemLazyQueryHookResult = ReturnType<typeof useCheckStockItemLazyQuery>;
export type CheckStockItemQueryResult = Apollo.QueryResult<
  CheckStockItemQuery,
  CheckStockItemQueryVariables
>;
export const GetAreasDocument = gql`
  query getAreas {
    getAreas {
      _id
      id
      nameEs
      nameEsFriendly
    }
  }
`;

/**
 * __useGetAreasQuery__
 *
 * To run a query within a React component, call `useGetAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAreasQuery, GetAreasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAreasQuery, GetAreasQueryVariables>(GetAreasDocument, options);
}
export function useGetAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAreasQuery, GetAreasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAreasQuery, GetAreasQueryVariables>(GetAreasDocument, options);
}
export type GetAreasQueryHookResult = ReturnType<typeof useGetAreasQuery>;
export type GetAreasLazyQueryHookResult = ReturnType<typeof useGetAreasLazyQuery>;
export type GetAreasQueryResult = Apollo.QueryResult<GetAreasQuery, GetAreasQueryVariables>;
export const GetTagsDocument = gql`
  query getTags {
    getTags {
      _id
      id
      tagEs
    }
  }
`;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
}
export function useGetTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
}
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsQueryResult = Apollo.QueryResult<GetTagsQuery, GetTagsQueryVariables>;
export const CurrentProductsDocument = gql`
  query currentProducts(
    $limit: Int
    $skip: Int!
    $sort: String
    $order: Int
    $product_type: [String]
    $lang: [String]
    $rangePrice: RangePrice
    $areas_of_interest: [Int]
    $areas_of_interest_text: String
    $tags: [TagsInput]
    $type: String
    $searchGeneral: String
    $prox: Boolean
    $area_prox: String
    $featured: Boolean
    $is_new: Boolean
  ) {
    books(
      limit: $limit
      skip: $skip
      sort: $sort
      order: $order
      product_type: $product_type
      lang: $lang
      rangePrice: $rangePrice
      areas_of_interest: $areas_of_interest
      areas_of_interest_text: $areas_of_interest_text
      tags: $tags
      type: $type
      searchGeneral: $searchGeneral
      prox: $prox
      area_prox: $area_prox
      featured: $featured
      is_new: $is_new
    ) {
      id
      _id
      titleFriendly
      title
      ean
      mainImg
      ebookContent
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
        book {
          authors {
            _id
            name
          }
          pages
          lang
          binding
        }
        availability {
          OK
          msg
          status
        }
      }
      prices {
        sale
        clearing
        saleSpecialDiscount
        ssdFrom
        ssdTo
      }
      vat {
        sale
      }
      tags {
        category
        items
      }
      book {
        authors {
          _id
          name
        }
        lang
        binding
        edition {
          year
          number
          month
        }
      }
      product_type
      priority
      priority_nov
      priceWithDiscount
      not_released
    }
  }
`;

/**
 * __useCurrentProductsQuery__
 *
 * To run a query within a React component, call `useCurrentProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentProductsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      order: // value for 'order'
 *      product_type: // value for 'product_type'
 *      lang: // value for 'lang'
 *      rangePrice: // value for 'rangePrice'
 *      areas_of_interest: // value for 'areas_of_interest'
 *      areas_of_interest_text: // value for 'areas_of_interest_text'
 *      tags: // value for 'tags'
 *      type: // value for 'type'
 *      searchGeneral: // value for 'searchGeneral'
 *      prox: // value for 'prox'
 *      area_prox: // value for 'area_prox'
 *      featured: // value for 'featured'
 *      is_new: // value for 'is_new'
 *   },
 * });
 */
export function useCurrentProductsQuery(
  baseOptions: Apollo.QueryHookOptions<CurrentProductsQuery, CurrentProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentProductsQuery, CurrentProductsQueryVariables>(
    CurrentProductsDocument,
    options,
  );
}
export function useCurrentProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentProductsQuery, CurrentProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentProductsQuery, CurrentProductsQueryVariables>(
    CurrentProductsDocument,
    options,
  );
}
export type CurrentProductsQueryHookResult = ReturnType<typeof useCurrentProductsQuery>;
export type CurrentProductsLazyQueryHookResult = ReturnType<typeof useCurrentProductsLazyQuery>;
export type CurrentProductsQueryResult = Apollo.QueryResult<
  CurrentProductsQuery,
  CurrentProductsQueryVariables
>;
export const AggregateDocument = gql`
  query aggregate(
    $collectionName: String!
    $group: String!
    $total: String
    $product_type: [String]
    $lang: [String]
    $rangePrice: RangePrice
    $areas_of_interest: [Int]
    $tags: [TagsInput]
    $type: String
    $searchGeneral: String
    $languages: [String]
    $version: [String]
    $prox: Boolean
    $area_prox: String
  ) {
    aggregate(
      collectionName: $collectionName
      group: $group
      total: $total
      product_type: $product_type
      lang: $lang
      rangePrice: $rangePrice
      areas_of_interest: $areas_of_interest
      tags: $tags
      type: $type
      searchGeneral: $searchGeneral
      languages: $languages
      version: $version
      prox: $prox
      area_prox: $area_prox
    ) {
      _id
      results {
        _id
        name
        total
        category
      }
    }
  }
`;

/**
 * __useAggregateQuery__
 *
 * To run a query within a React component, call `useAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateQuery({
 *   variables: {
 *      collectionName: // value for 'collectionName'
 *      group: // value for 'group'
 *      total: // value for 'total'
 *      product_type: // value for 'product_type'
 *      lang: // value for 'lang'
 *      rangePrice: // value for 'rangePrice'
 *      areas_of_interest: // value for 'areas_of_interest'
 *      tags: // value for 'tags'
 *      type: // value for 'type'
 *      searchGeneral: // value for 'searchGeneral'
 *      languages: // value for 'languages'
 *      version: // value for 'version'
 *      prox: // value for 'prox'
 *      area_prox: // value for 'area_prox'
 *   },
 * });
 */
export function useAggregateQuery(
  baseOptions: Apollo.QueryHookOptions<AggregateQuery, AggregateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AggregateQuery, AggregateQueryVariables>(AggregateDocument, options);
}
export function useAggregateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AggregateQuery, AggregateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AggregateQuery, AggregateQueryVariables>(AggregateDocument, options);
}
export type AggregateQueryHookResult = ReturnType<typeof useAggregateQuery>;
export type AggregateLazyQueryHookResult = ReturnType<typeof useAggregateLazyQuery>;
export type AggregateQueryResult = Apollo.QueryResult<AggregateQuery, AggregateQueryVariables>;
export const MeDocument = gql`
  query me($state: Float) {
    me(state: $state) {
      _id
      email
      newsletterActive
      createdAt
      profile {
        alias
        firstName
        lastName
        mobile
        phone
        areaOfInterest
        NIF
        customerId
        newsletter {
          areaOfInterest
        }
        ebooks {
          _id
          ean
          code
          buyDate
          mainImg
        }
        basket {
          _id
          ean
          units
          book {
            lastBuyPrice
            exchangeRate
            buyExpenses
            priceWithDiscount
            product_type
            titleFriendly
            _id
            title
            mainImg
            brand {
              name
            }
          }
        }
        shopping_default
        shoppingAddress {
          name
          address
          route
          streetNumber
          floor
          postalCode
          locality
          isOutUE
          province
          country
          comment
          deliverTo
          countryCode
          location {
            lat
            lng
          }
          cif
          businessName
        }
        billing_default
        billingData {
          address
          route
          streetNumber
          floor
          postalCode
          locality
          province
          country
          countryCode
          cif
          businessName
        }
        couponsUsed {
          _id
          coupon
          discount
          value
          usedDate
        }
      }
      bestCoupon {
        _id
        coupon
        discount
        value
        productType
        accountCode
      }
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CurrentJournalsDocument = gql`
  query currentJournals($id_journal: Int) {
    journal(id_journal: $id_journal) {
      _id
      id_journal
      title
      version
      issn
      issues
      country_key
      titleFriendly
      brand {
        id
        name
      }
      languages
      version
      packs {
        _id
        id_pack
        rates {
          _id
          id_rate
          subscriber_type
          price
          start
          end
          valid_to
          is_valid
        }
        journals {
          _id
          id_journal
          title
          version
          issn
          issues
          country_key
          titleFriendly
        }
      }
    }
  }
`;

/**
 * __useCurrentJournalsQuery__
 *
 * To run a query within a React component, call `useCurrentJournalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentJournalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentJournalsQuery({
 *   variables: {
 *      id_journal: // value for 'id_journal'
 *   },
 * });
 */
export function useCurrentJournalsQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentJournalsQuery, CurrentJournalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentJournalsQuery, CurrentJournalsQueryVariables>(
    CurrentJournalsDocument,
    options,
  );
}
export function useCurrentJournalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentJournalsQuery, CurrentJournalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentJournalsQuery, CurrentJournalsQueryVariables>(
    CurrentJournalsDocument,
    options,
  );
}
export type CurrentJournalsQueryHookResult = ReturnType<typeof useCurrentJournalsQuery>;
export type CurrentJournalsLazyQueryHookResult = ReturnType<typeof useCurrentJournalsLazyQuery>;
export type CurrentJournalsQueryResult = Apollo.QueryResult<
  CurrentJournalsQuery,
  CurrentJournalsQueryVariables
>;
export const GetBookIdDocument = gql`
  query getBookId($ean: String!) {
    book(ean: $ean) {
      _id
      id
    }
  }
`;

/**
 * __useGetBookIdQuery__
 *
 * To run a query within a React component, call `useGetBookIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookIdQuery({
 *   variables: {
 *      ean: // value for 'ean'
 *   },
 * });
 */
export function useGetBookIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetBookIdQuery, GetBookIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBookIdQuery, GetBookIdQueryVariables>(GetBookIdDocument, options);
}
export function useGetBookIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBookIdQuery, GetBookIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBookIdQuery, GetBookIdQueryVariables>(GetBookIdDocument, options);
}
export type GetBookIdQueryHookResult = ReturnType<typeof useGetBookIdQuery>;
export type GetBookIdLazyQueryHookResult = ReturnType<typeof useGetBookIdLazyQuery>;
export type GetBookIdQueryResult = Apollo.QueryResult<GetBookIdQuery, GetBookIdQueryVariables>;
export const CurrentProductDocument = gql`
  query currentProduct($ean: String!) {
    book(ean: $ean) {
      _id
      banner {
        active
        launching
      }
      prepublication {
        launching
        start
        end
      }
      id
      title
      ean
      titleFriendly
      mainImg
      priceWithDiscount
      ebookContent
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        allImg {
          link
          width
          height
        }
        mainImg
        book {
          authors {
            _id
            name
          }
          lang
          pages
          binding
        }
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
        availability {
          OK
          msg
          status
        }
        dimensions {
          depth
          width
          height
        }
      }
      attachments {
        fileName
        fileType
        caption
      }
      weight
      allImg {
        link
        width
        height
      }
      availability {
        OK
        msg
        status
      }
      dimensions {
        depth
        width
        height
      }
      book {
        description
        table_of_contents
        authors {
          _id
          name
        }
        lang
        pages
        binding
        edition {
          number
          year
          month
        }
      }
      brand {
        id
        name
      }
      product_type
      authors {
        name
      }
      not_released
      stock_available
      isNotToPublish
      unavailable
      prices {
        sale
        saleSpecialDiscount
        ssdTo
        ssdFrom
      }
      vat {
        sale
      }
      suscription {
        email
      }
      tags {
        items
        category
      }
      themes_text
      variantProduct {
        ean
        title
        priceWithDiscount
        titleFriendly
      }
      variant {
        group_id
        properties {
          color
          finish
          cuff
        }
      }
      defaultVariant
      variantChildren {
        _id
        id
        title
        ean
        priceWithDiscount
        titleFriendly
        dimensions {
          depth
          width
          height
        }
        availability {
          OK
          msg
          status
        }
        book {
          table_of_contents
          authors {
            _id
            name
          }
          lang
          pages
          binding
          description
          binding
          edition {
            number
            year
            month
          }
        }
        brand {
          id
          name
        }
        product_type
        authors {
          name
        }
        not_released
        allImg {
          link
          width
          height
        }
        tags {
          items
          category
        }
        themes_text
        mainImg
        unavailable
        prices {
          sale
        }
        vat {
          sale
        }
        variant {
          group_id
          properties {
            color
            finish
            cuff
          }
        }
      }
    }
  }
`;

/**
 * __useCurrentProductQuery__
 *
 * To run a query within a React component, call `useCurrentProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentProductQuery({
 *   variables: {
 *      ean: // value for 'ean'
 *   },
 * });
 */
export function useCurrentProductQuery(
  baseOptions: Apollo.QueryHookOptions<CurrentProductQuery, CurrentProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentProductQuery, CurrentProductQueryVariables>(
    CurrentProductDocument,
    options,
  );
}
export function useCurrentProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentProductQuery, CurrentProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentProductQuery, CurrentProductQueryVariables>(
    CurrentProductDocument,
    options,
  );
}
export type CurrentProductQueryHookResult = ReturnType<typeof useCurrentProductQuery>;
export type CurrentProductLazyQueryHookResult = ReturnType<typeof useCurrentProductLazyQuery>;
export type CurrentProductQueryResult = Apollo.QueryResult<
  CurrentProductQuery,
  CurrentProductQueryVariables
>;
export const CurrentProductIdDocument = gql`
  query currentProductID($id: String!) {
    book: bookByIdBBDD(id: $id) {
      _id
      id
      banner {
        active
        launching
      }
      ebookContent
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
        availability {
          OK
          msg
          status
        }
        book {
          authors {
            _id
            name
          }
          pages
          lang
          binding
        }
      }
      prepublication {
        launching
        start
        end
      }
      title
      ean
      titleFriendly
      mainImg
      priceWithDiscount
      attachments {
        fileName
        fileType
        caption
      }
      weight
      allImg {
        link
        width
        height
      }
      availability {
        OK
        msg
        status
      }
      dimensions {
        depth
        width
        height
      }
      book {
        description
        table_of_contents
        authors {
          _id
          name
        }
        lang
        pages
        binding
        edition {
          number
          year
          month
        }
      }
      brand {
        id
        name
      }
      product_type
      authors {
        name
      }
      not_released
      stock_available
      isNotToPublish
      unavailable
      prices {
        sale
        saleSpecialDiscount
        ssdTo
        ssdFrom
      }
      vat {
        sale
      }
      suscription {
        email
      }
      tags {
        items
        category
      }
      themes_text
      variantProduct {
        ean
        title
        priceWithDiscount
        titleFriendly
      }
      variant {
        group_id
        properties {
          color
          finish
          cuff
        }
      }
      defaultVariant
      variantChildren {
        _id
        id
        title
        ean
        priceWithDiscount
        titleFriendly
        dimensions {
          depth
          width
          height
        }
        availability {
          OK
          msg
          status
        }
        book {
          table_of_contents
          authors {
            _id
            name
          }
          lang
          pages
          binding
          description
          edition {
            number
            year
            month
          }
        }
        brand {
          id
          name
        }
        product_type
        authors {
          name
        }
        not_released
        allImg {
          link
          width
          height
        }
        tags {
          items
          category
        }
        themes_text
        mainImg
        unavailable
        prices {
          sale
        }
        vat {
          sale
        }
        variant {
          group_id
          properties {
            color
            finish
            cuff
          }
        }
      }
    }
  }
`;

/**
 * __useCurrentProductIdQuery__
 *
 * To run a query within a React component, call `useCurrentProductIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentProductIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentProductIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCurrentProductIdQuery(
  baseOptions: Apollo.QueryHookOptions<CurrentProductIdQuery, CurrentProductIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentProductIdQuery, CurrentProductIdQueryVariables>(
    CurrentProductIdDocument,
    options,
  );
}
export function useCurrentProductIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentProductIdQuery, CurrentProductIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentProductIdQuery, CurrentProductIdQueryVariables>(
    CurrentProductIdDocument,
    options,
  );
}
export type CurrentProductIdQueryHookResult = ReturnType<typeof useCurrentProductIdQuery>;
export type CurrentProductIdLazyQueryHookResult = ReturnType<typeof useCurrentProductIdLazyQuery>;
export type CurrentProductIdQueryResult = Apollo.QueryResult<
  CurrentProductIdQuery,
  CurrentProductIdQueryVariables
>;
export const GetUserBasketDocument = gql`
  query getUserBasket($idUser: ID!) {
    user(_id: $idUser) {
      _id
      profile {
        NIF
        basket {
          _id
          ean
          units
          book {
            _id
            id
            ean
            priceWithDiscount
            titleFriendly
            lastBuyPrice
            exchangeRate
            buyExpenses
            reposition_level
            product_type
            stock_available
            availability {
              OK
              msg
              status
            }
            title
            weight
            dimensions {
              depth
              width
              height
            }
            tags {
              items
              category
            }
            brand {
              id
              name
            }
            prices {
              sale
              discount
              saleSpecialDiscount
              ssdFrom
              ssdTo
            }
            vat {
              sale
            }
            mainImg
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserBasketQuery__
 *
 * To run a query within a React component, call `useGetUserBasketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBasketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBasketQuery({
 *   variables: {
 *      idUser: // value for 'idUser'
 *   },
 * });
 */
export function useGetUserBasketQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserBasketQuery, GetUserBasketQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserBasketQuery, GetUserBasketQueryVariables>(
    GetUserBasketDocument,
    options,
  );
}
export function useGetUserBasketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserBasketQuery, GetUserBasketQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserBasketQuery, GetUserBasketQueryVariables>(
    GetUserBasketDocument,
    options,
  );
}
export type GetUserBasketQueryHookResult = ReturnType<typeof useGetUserBasketQuery>;
export type GetUserBasketLazyQueryHookResult = ReturnType<typeof useGetUserBasketLazyQuery>;
export type GetUserBasketQueryResult = Apollo.QueryResult<
  GetUserBasketQuery,
  GetUserBasketQueryVariables
>;
export const OrderDocument = gql`
  query order($_id: ID, $order: String) {
    order(_id: $_id, order: $order) {
      _id
      order
      userId
      orderSequence
      amountIVA
      amountWithoutIVA
      deliveryAddress {
        name
        address
        route
        streetNumber
        floor
        postalCode
        locality
        province
        country
        comment
        isOutUE
        deliverTo
        countryCode
        cif
        businessName
      }
      transport
      amountTransport
      amount
      methodPayment
      fullNameUser
      fullNameUserClean
      legalNameFiscalData
      fiscalDoc
      state_order
      amountDiscount
      discountIdCode
      discountIdCodeWork
      confirmDate
      autorizationCode
      responseCode
      order_multiple
      giftCardCode
      errorCode
      deleted
      items {
        _id
        ean
        units
        priceSignDeal
        priceSign
        itemState
        canceledDate
        book {
          priceWithDiscount
          lastBuyPrice
          exchangeRate
          buyExpenses
          _id
          id
          title
          reposition_level
          stock_available
          availability {
            OK
            msg
            status
          }
          prices {
            sale
          }
          vat {
            sale
          }
        }
      }
      confirmDate
      createdAt
    }
  }
`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderQuery(
  baseOptions?: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
}
export function useOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const CurrentOrdersUserDocument = gql`
  query currentOrdersUser($userId: ID!) {
    orders(userId: $userId) {
      _id
      order
      userId
      orderSequence
      amountIVA
      otherTaxes {
        amount
        description
        name
      }
      amountWithoutIVA
      transport
      billGridId
      billId
      methodPayment
      deliveryAddress {
        name
        address
        route
        streetNumber
        floor
        postalCode
        locality
        province
        country
        comment
        deliverTo
        countryCode
        cif
        businessName
        isOutUE
      }
      fiscalAddress {
        name
        address
        locality
        cif
      }
      items {
        ean
        units
        priceSignDeal
        priceSign
        itemState
        canceledDate
        invoice {
          _id
          billGridId
          amountDiscount
          amountIVA
          discountIdCode
          billId
          orderSequence
          state_order
          order
          amountTransport
          couponObject {
            _id
            coupon
            discount
            value
            productType
          }
          otherTaxes {
            amount
            description
            name
          }
        }
        shipped
        book {
          vat {
            sale
          }
          ean
          priceWithDiscount
          titleFriendly
          id
          title
          reposition_level
          mainImg
          product_type
          stock_available
          availability {
            OK
            msg
            status
          }
          prices {
            clearing
            sale
          }
        }
      }
      amountTransport
      amount
      discountIdCode
      legalNameFiscalData
      state_order
      amountDiscount
      confirmDate
      autorizationCode
      responseCode
      order_multiple
      deleted
      confirmDate
      createdAt
      couponObject {
        _id
        coupon
        discount
        value
        productType
      }
    }
  }
`;

/**
 * __useCurrentOrdersUserQuery__
 *
 * To run a query within a React component, call `useCurrentOrdersUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentOrdersUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentOrdersUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCurrentOrdersUserQuery(
  baseOptions: Apollo.QueryHookOptions<CurrentOrdersUserQuery, CurrentOrdersUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentOrdersUserQuery, CurrentOrdersUserQueryVariables>(
    CurrentOrdersUserDocument,
    options,
  );
}
export function useCurrentOrdersUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentOrdersUserQuery,
    CurrentOrdersUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentOrdersUserQuery, CurrentOrdersUserQueryVariables>(
    CurrentOrdersUserDocument,
    options,
  );
}
export type CurrentOrdersUserQueryHookResult = ReturnType<typeof useCurrentOrdersUserQuery>;
export type CurrentOrdersUserLazyQueryHookResult = ReturnType<typeof useCurrentOrdersUserLazyQuery>;
export type CurrentOrdersUserQueryResult = Apollo.QueryResult<
  CurrentOrdersUserQuery,
  CurrentOrdersUserQueryVariables
>;
export const GetMagazinesPredictiveDocument = gql`
  query getMagazinesPredictive($search: String!, $limit: Int!) {
    getMagazinesPredictive(search: $search, limit: $limit) {
      _id
      title
      version
      issn
      issues
      id_journal
      country_key
      brand {
        id
        name
      }
      languages
      subjects
      titleFriendly
    }
  }
`;

/**
 * __useGetMagazinesPredictiveQuery__
 *
 * To run a query within a React component, call `useGetMagazinesPredictiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMagazinesPredictiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMagazinesPredictiveQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMagazinesPredictiveQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMagazinesPredictiveQuery,
    GetMagazinesPredictiveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMagazinesPredictiveQuery, GetMagazinesPredictiveQueryVariables>(
    GetMagazinesPredictiveDocument,
    options,
  );
}
export function useGetMagazinesPredictiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMagazinesPredictiveQuery,
    GetMagazinesPredictiveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMagazinesPredictiveQuery, GetMagazinesPredictiveQueryVariables>(
    GetMagazinesPredictiveDocument,
    options,
  );
}
export type GetMagazinesPredictiveQueryHookResult = ReturnType<
  typeof useGetMagazinesPredictiveQuery
>;
export type GetMagazinesPredictiveLazyQueryHookResult = ReturnType<
  typeof useGetMagazinesPredictiveLazyQuery
>;
export type GetMagazinesPredictiveQueryResult = Apollo.QueryResult<
  GetMagazinesPredictiveQuery,
  GetMagazinesPredictiveQueryVariables
>;
export const GetTagsCustomDocument = gql`
  query getTagsCustom(
    $search: String!
    $limit: Int
    $product_type: [String]
    $areaOfInterest: [Int]
  ) {
    getTagsCustom(
      search: $search
      limit: $limit
      product_type: $product_type
      areas_of_interest: $areaOfInterest
    ) {
      _id
      name
      total
    }
  }
`;

/**
 * __useGetTagsCustomQuery__
 *
 * To run a query within a React component, call `useGetTagsCustomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsCustomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsCustomQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      product_type: // value for 'product_type'
 *      areaOfInterest: // value for 'areaOfInterest'
 *   },
 * });
 */
export function useGetTagsCustomQuery(
  baseOptions: Apollo.QueryHookOptions<GetTagsCustomQuery, GetTagsCustomQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTagsCustomQuery, GetTagsCustomQueryVariables>(
    GetTagsCustomDocument,
    options,
  );
}
export function useGetTagsCustomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTagsCustomQuery, GetTagsCustomQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTagsCustomQuery, GetTagsCustomQueryVariables>(
    GetTagsCustomDocument,
    options,
  );
}
export type GetTagsCustomQueryHookResult = ReturnType<typeof useGetTagsCustomQuery>;
export type GetTagsCustomLazyQueryHookResult = ReturnType<typeof useGetTagsCustomLazyQuery>;
export type GetTagsCustomQueryResult = Apollo.QueryResult<
  GetTagsCustomQuery,
  GetTagsCustomQueryVariables
>;
export const GetResultsPredictiveDocument = gql`
  query getResultsPredictive(
    $search: String!
    $limit: Int!
    $areas_of_interest: [Int]
    $product_type: [String]
    $orderByScore: Boolean
  ) {
    books(
      search: $search
      limit: $limit
      areas_of_interest: $areas_of_interest
      order: -1
      product_type: $product_type
      orderByScore: $orderByScore
    ) {
      id
      ean
      titleFriendly
      tags {
        category
        items
      }
      ebookContent
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        availability {
          OK
          msg
          status
        }
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
        book {
          authors {
            _id
            name
          }
          lang
          pages
          binding
        }
      }
      product_type
      mainImg
      title
      priceWithDiscount
      prices {
        sale
      }
      book {
        authors {
          name
        }
        binding
        pages
        edition {
          year
        }
      }
    }
  }
`;

/**
 * __useGetResultsPredictiveQuery__
 *
 * To run a query within a React component, call `useGetResultsPredictiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResultsPredictiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResultsPredictiveQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      areas_of_interest: // value for 'areas_of_interest'
 *      product_type: // value for 'product_type'
 *      orderByScore: // value for 'orderByScore'
 *   },
 * });
 */
export function useGetResultsPredictiveQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetResultsPredictiveQuery,
    GetResultsPredictiveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetResultsPredictiveQuery, GetResultsPredictiveQueryVariables>(
    GetResultsPredictiveDocument,
    options,
  );
}
export function useGetResultsPredictiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetResultsPredictiveQuery,
    GetResultsPredictiveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetResultsPredictiveQuery, GetResultsPredictiveQueryVariables>(
    GetResultsPredictiveDocument,
    options,
  );
}
export type GetResultsPredictiveQueryHookResult = ReturnType<typeof useGetResultsPredictiveQuery>;
export type GetResultsPredictiveLazyQueryHookResult = ReturnType<
  typeof useGetResultsPredictiveLazyQuery
>;
export type GetResultsPredictiveQueryResult = Apollo.QueryResult<
  GetResultsPredictiveQuery,
  GetResultsPredictiveQueryVariables
>;
export const GetTagsPredictiveDocument = gql`
  query getTagsPredictive(
    $search: String!
    $limit: Int!
    $areas_of_interest: [Int]
    $product_type: [String]
  ) {
    tags(
      searchTag: $search
      limit: $limit
      areas_of_interest: $areas_of_interest
      product_type: $product_type
    ) {
      _id
      name
      category
      tagEs
    }
  }
`;

/**
 * __useGetTagsPredictiveQuery__
 *
 * To run a query within a React component, call `useGetTagsPredictiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsPredictiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsPredictiveQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      areas_of_interest: // value for 'areas_of_interest'
 *      product_type: // value for 'product_type'
 *   },
 * });
 */
export function useGetTagsPredictiveQuery(
  baseOptions: Apollo.QueryHookOptions<GetTagsPredictiveQuery, GetTagsPredictiveQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTagsPredictiveQuery, GetTagsPredictiveQueryVariables>(
    GetTagsPredictiveDocument,
    options,
  );
}
export function useGetTagsPredictiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTagsPredictiveQuery,
    GetTagsPredictiveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTagsPredictiveQuery, GetTagsPredictiveQueryVariables>(
    GetTagsPredictiveDocument,
    options,
  );
}
export type GetTagsPredictiveQueryHookResult = ReturnType<typeof useGetTagsPredictiveQuery>;
export type GetTagsPredictiveLazyQueryHookResult = ReturnType<typeof useGetTagsPredictiveLazyQuery>;
export type GetTagsPredictiveQueryResult = Apollo.QueryResult<
  GetTagsPredictiveQuery,
  GetTagsPredictiveQueryVariables
>;
export const GetAreaDocument = gql`
  query getArea($nameEsFriendly: String!) {
    getArea(nameEsFriendly: $nameEsFriendly) {
      _id
      id
      nameEs
      nameEsFriendly
    }
  }
`;

/**
 * __useGetAreaQuery__
 *
 * To run a query within a React component, call `useGetAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreaQuery({
 *   variables: {
 *      nameEsFriendly: // value for 'nameEsFriendly'
 *   },
 * });
 */
export function useGetAreaQuery(
  baseOptions: Apollo.QueryHookOptions<GetAreaQuery, GetAreaQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAreaQuery, GetAreaQueryVariables>(GetAreaDocument, options);
}
export function useGetAreaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAreaQuery, GetAreaQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAreaQuery, GetAreaQueryVariables>(GetAreaDocument, options);
}
export type GetAreaQueryHookResult = ReturnType<typeof useGetAreaQuery>;
export type GetAreaLazyQueryHookResult = ReturnType<typeof useGetAreaLazyQuery>;
export type GetAreaQueryResult = Apollo.QueryResult<GetAreaQuery, GetAreaQueryVariables>;
export const GetResultsPredictiveSearchDocument = gql`
  query getResultsPredictiveSearch(
    $search: String!
    $limit: Int!
    $areas_of_interest: [Int]
    $product_type: [String]
  ) {
    resultsPredictive(
      searchTag: $search
      limit: $limit
      areas_of_interest: $areas_of_interest
      product_type: $product_type
    ) {
      _id
      name
      category
    }
  }
`;

/**
 * __useGetResultsPredictiveSearchQuery__
 *
 * To run a query within a React component, call `useGetResultsPredictiveSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResultsPredictiveSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResultsPredictiveSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      areas_of_interest: // value for 'areas_of_interest'
 *      product_type: // value for 'product_type'
 *   },
 * });
 */
export function useGetResultsPredictiveSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetResultsPredictiveSearchQuery,
    GetResultsPredictiveSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetResultsPredictiveSearchQuery, GetResultsPredictiveSearchQueryVariables>(
    GetResultsPredictiveSearchDocument,
    options,
  );
}
export function useGetResultsPredictiveSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetResultsPredictiveSearchQuery,
    GetResultsPredictiveSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetResultsPredictiveSearchQuery,
    GetResultsPredictiveSearchQueryVariables
  >(GetResultsPredictiveSearchDocument, options);
}
export type GetResultsPredictiveSearchQueryHookResult = ReturnType<
  typeof useGetResultsPredictiveSearchQuery
>;
export type GetResultsPredictiveSearchLazyQueryHookResult = ReturnType<
  typeof useGetResultsPredictiveSearchLazyQuery
>;
export type GetResultsPredictiveSearchQueryResult = Apollo.QueryResult<
  GetResultsPredictiveSearchQuery,
  GetResultsPredictiveSearchQueryVariables
>;
export const ShippingApiDocument = gql`
  query shippingAPI($amount: Float!, $province: String!, $country: String!, $zip: String) {
    shippingAPI(amount: $amount, province: $province, country: $country, zip: $zip) {
      company
      fee
      days
    }
  }
`;

/**
 * __useShippingApiQuery__
 *
 * To run a query within a React component, call `useShippingApiQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippingApiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippingApiQuery({
 *   variables: {
 *      amount: // value for 'amount'
 *      province: // value for 'province'
 *      country: // value for 'country'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useShippingApiQuery(
  baseOptions: Apollo.QueryHookOptions<ShippingApiQuery, ShippingApiQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShippingApiQuery, ShippingApiQueryVariables>(ShippingApiDocument, options);
}
export function useShippingApiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShippingApiQuery, ShippingApiQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShippingApiQuery, ShippingApiQueryVariables>(
    ShippingApiDocument,
    options,
  );
}
export type ShippingApiQueryHookResult = ReturnType<typeof useShippingApiQuery>;
export type ShippingApiLazyQueryHookResult = ReturnType<typeof useShippingApiLazyQuery>;
export type ShippingApiQueryResult = Apollo.QueryResult<
  ShippingApiQuery,
  ShippingApiQueryVariables
>;
export const ProductsWithBannersDocument = gql`
  query productsWithBanners(
    $_id: ID
    $skip: Int
    $limit: Int
    $sort: String
    $order: Int
    $hasBanner: String
    $product_type: [String]
    $areas_of_interest: [Int]
    $unpublish: Boolean
  ) {
    productsWithBanners(
      _id: $_id
      skip: $skip
      limit: $limit
      sort: $sort
      order: $order
      hasBanner: $hasBanner
      product_type: $product_type
      areas_of_interest: $areas_of_interest
      unpublish: $unpublish
    ) {
      _id
      id
      title
      ean
      priceWithDiscount
      titleFriendly
      book {
        authors {
          _id
          name
        }
        binding
        edition {
          number
          year
          month
        }
      }
      product_type
      not_released
      prices {
        saleSpecialDiscount
        ssdFrom
        ssdTo
        sale
      }
      mainImg
      banner {
        active
        launching
        start
        end
        price
      }
    }
  }
`;

/**
 * __useProductsWithBannersQuery__
 *
 * To run a query within a React component, call `useProductsWithBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsWithBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsWithBannersQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      order: // value for 'order'
 *      hasBanner: // value for 'hasBanner'
 *      product_type: // value for 'product_type'
 *      areas_of_interest: // value for 'areas_of_interest'
 *      unpublish: // value for 'unpublish'
 *   },
 * });
 */
export function useProductsWithBannersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductsWithBannersQuery,
    ProductsWithBannersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsWithBannersQuery, ProductsWithBannersQueryVariables>(
    ProductsWithBannersDocument,
    options,
  );
}
export function useProductsWithBannersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsWithBannersQuery,
    ProductsWithBannersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsWithBannersQuery, ProductsWithBannersQueryVariables>(
    ProductsWithBannersDocument,
    options,
  );
}
export type ProductsWithBannersQueryHookResult = ReturnType<typeof useProductsWithBannersQuery>;
export type ProductsWithBannersLazyQueryHookResult = ReturnType<
  typeof useProductsWithBannersLazyQuery
>;
export type ProductsWithBannersQueryResult = Apollo.QueryResult<
  ProductsWithBannersQuery,
  ProductsWithBannersQueryVariables
>;
export const GetBestSellersDocument = gql`
  query getBestSellers($product_type: String, $areas_of_interest: [Int]) {
    getBestSellers(product_type: $product_type, areas_of_interest: $areas_of_interest) {
      id
      _id
      titleFriendly
      title
      ean
      mainImg
      areas_of_interest
      prices {
        sale
        clearing
        saleSpecialDiscount
        ssdFrom
        ssdTo
      }
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        book {
          pages
          authors {
            _id
            name
          }
          lang
          binding
        }
        availability {
          OK
          msg
          status
        }
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
      }
      book {
        authors {
          _id
          name
        }
        lang
        binding
      }
      product_type
      priceWithDiscount
    }
  }
`;

/**
 * __useGetBestSellersQuery__
 *
 * To run a query within a React component, call `useGetBestSellersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBestSellersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBestSellersQuery({
 *   variables: {
 *      product_type: // value for 'product_type'
 *      areas_of_interest: // value for 'areas_of_interest'
 *   },
 * });
 */
export function useGetBestSellersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBestSellersQuery, GetBestSellersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBestSellersQuery, GetBestSellersQueryVariables>(
    GetBestSellersDocument,
    options,
  );
}
export function useGetBestSellersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBestSellersQuery, GetBestSellersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBestSellersQuery, GetBestSellersQueryVariables>(
    GetBestSellersDocument,
    options,
  );
}
export type GetBestSellersQueryHookResult = ReturnType<typeof useGetBestSellersQuery>;
export type GetBestSellersLazyQueryHookResult = ReturnType<typeof useGetBestSellersLazyQuery>;
export type GetBestSellersQueryResult = Apollo.QueryResult<
  GetBestSellersQuery,
  GetBestSellersQueryVariables
>;
export const BooksFeaturedDocument = gql`
  query booksFeatured {
    booksFeatured {
      id
      _id
      titleFriendly
      title
      ean
      mainImg
      ebookContent
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        book {
          authors {
            _id
            name
          }
          pages
          lang
          binding
        }
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
        availability {
          OK
          msg
          status
        }
      }
      prices {
        sale
        clearing
        saleSpecialDiscount
        ssdFrom
        ssdTo
      }
      book {
        authors {
          _id
          name
        }
        lang
      }
      product_type
      priceWithDiscount
    }
  }
`;

/**
 * __useBooksFeaturedQuery__
 *
 * To run a query within a React component, call `useBooksFeaturedQuery` and pass it any options that fit your needs.
 * When your component renders, `useBooksFeaturedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBooksFeaturedQuery({
 *   variables: {
 *   },
 * });
 */
export function useBooksFeaturedQuery(
  baseOptions?: Apollo.QueryHookOptions<BooksFeaturedQuery, BooksFeaturedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BooksFeaturedQuery, BooksFeaturedQueryVariables>(
    BooksFeaturedDocument,
    options,
  );
}
export function useBooksFeaturedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BooksFeaturedQuery, BooksFeaturedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BooksFeaturedQuery, BooksFeaturedQueryVariables>(
    BooksFeaturedDocument,
    options,
  );
}
export type BooksFeaturedQueryHookResult = ReturnType<typeof useBooksFeaturedQuery>;
export type BooksFeaturedLazyQueryHookResult = ReturnType<typeof useBooksFeaturedLazyQuery>;
export type BooksFeaturedQueryResult = Apollo.QueryResult<
  BooksFeaturedQuery,
  BooksFeaturedQueryVariables
>;
export const BooksNewsDocument = gql`
  query booksNews {
    booksNews {
      id
      _id
      titleFriendly
      title
      ean
      mainImg
      ebookContent
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        book {
          authors {
            _id
            name
          }
          pages
          lang
          binding
        }
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
        availability {
          OK
          msg
          status
        }
      }
      prices {
        sale
        clearing
        saleSpecialDiscount
        ssdFrom
        ssdTo
      }
      book {
        authors {
          _id
          name
        }
        lang
      }
      product_type
      priceWithDiscount
    }
  }
`;

/**
 * __useBooksNewsQuery__
 *
 * To run a query within a React component, call `useBooksNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBooksNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBooksNewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBooksNewsQuery(
  baseOptions?: Apollo.QueryHookOptions<BooksNewsQuery, BooksNewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BooksNewsQuery, BooksNewsQueryVariables>(BooksNewsDocument, options);
}
export function useBooksNewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BooksNewsQuery, BooksNewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BooksNewsQuery, BooksNewsQueryVariables>(BooksNewsDocument, options);
}
export type BooksNewsQueryHookResult = ReturnType<typeof useBooksNewsQuery>;
export type BooksNewsLazyQueryHookResult = ReturnType<typeof useBooksNewsLazyQuery>;
export type BooksNewsQueryResult = Apollo.QueryResult<BooksNewsQuery, BooksNewsQueryVariables>;
export const RelatedProductsDocument = gql`
  query relatedProducts($idUser: String, $eanProduct: String!) {
    relatedProducts(idUser: $idUser, eanProduct: $eanProduct) {
      id
      _id
      titleFriendly
      title
      ean
      mainImg
      ebookContent
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        book {
          authors {
            _id
            name
          }
          pages
          lang
          binding
        }
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
        availability {
          OK
          msg
          status
        }
      }
      prices {
        sale
        clearing
        saleSpecialDiscount
        ssdFrom
        ssdTo
      }
      book {
        authors {
          _id
          name
        }
        lang
      }
      product_type
      priceWithDiscount
    }
  }
`;

/**
 * __useRelatedProductsQuery__
 *
 * To run a query within a React component, call `useRelatedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedProductsQuery({
 *   variables: {
 *      idUser: // value for 'idUser'
 *      eanProduct: // value for 'eanProduct'
 *   },
 * });
 */
export function useRelatedProductsQuery(
  baseOptions: Apollo.QueryHookOptions<RelatedProductsQuery, RelatedProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RelatedProductsQuery, RelatedProductsQueryVariables>(
    RelatedProductsDocument,
    options,
  );
}
export function useRelatedProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RelatedProductsQuery, RelatedProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RelatedProductsQuery, RelatedProductsQueryVariables>(
    RelatedProductsDocument,
    options,
  );
}
export type RelatedProductsQueryHookResult = ReturnType<typeof useRelatedProductsQuery>;
export type RelatedProductsLazyQueryHookResult = ReturnType<typeof useRelatedProductsLazyQuery>;
export type RelatedProductsQueryResult = Apollo.QueryResult<
  RelatedProductsQuery,
  RelatedProductsQueryVariables
>;
export const GetRecentViewsDocument = gql`
  query getRecentViews($idUser: String) {
    getRecentViews(idUser: $idUser) {
      id
      _id
      titleFriendly
      title
      ean
      mainImg
      prices {
        sale
        clearing
        saleSpecialDiscount
        ssdFrom
        ssdTo
      }
      book {
        authors {
          _id
          name
        }
        lang
      }
      product_type
      priceWithDiscount
    }
  }
`;

/**
 * __useGetRecentViewsQuery__
 *
 * To run a query within a React component, call `useGetRecentViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentViewsQuery({
 *   variables: {
 *      idUser: // value for 'idUser'
 *   },
 * });
 */
export function useGetRecentViewsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRecentViewsQuery, GetRecentViewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecentViewsQuery, GetRecentViewsQueryVariables>(
    GetRecentViewsDocument,
    options,
  );
}
export function useGetRecentViewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRecentViewsQuery, GetRecentViewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecentViewsQuery, GetRecentViewsQueryVariables>(
    GetRecentViewsDocument,
    options,
  );
}
export type GetRecentViewsQueryHookResult = ReturnType<typeof useGetRecentViewsQuery>;
export type GetRecentViewsLazyQueryHookResult = ReturnType<typeof useGetRecentViewsLazyQuery>;
export type GetRecentViewsQueryResult = Apollo.QueryResult<
  GetRecentViewsQuery,
  GetRecentViewsQueryVariables
>;
export const GetRateRequestDocument = gql`
  query getRateRequest(
    $_id: ID
    $destinationAddress: OrderDeliveryAddressNew
    $package: newPackage
    $totalAmount: Float
  ) {
    getRateRequest(
      idUser: $_id
      destinationAddress: $destinationAddress
      package: $package
      totalAmount: $totalAmount
    ) {
      currency
      amount
      deliveryTime
      error
    }
  }
`;

/**
 * __useGetRateRequestQuery__
 *
 * To run a query within a React component, call `useGetRateRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRateRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRateRequestQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      destinationAddress: // value for 'destinationAddress'
 *      package: // value for 'package'
 *      totalAmount: // value for 'totalAmount'
 *   },
 * });
 */
export function useGetRateRequestQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRateRequestQuery, GetRateRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRateRequestQuery, GetRateRequestQueryVariables>(
    GetRateRequestDocument,
    options,
  );
}
export function useGetRateRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRateRequestQuery, GetRateRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRateRequestQuery, GetRateRequestQueryVariables>(
    GetRateRequestDocument,
    options,
  );
}
export type GetRateRequestQueryHookResult = ReturnType<typeof useGetRateRequestQuery>;
export type GetRateRequestLazyQueryHookResult = ReturnType<typeof useGetRateRequestLazyQuery>;
export type GetRateRequestQueryResult = Apollo.QueryResult<
  GetRateRequestQuery,
  GetRateRequestQueryVariables
>;
export const GetAreasFromNewsletterDocument = gql`
  query getAreasFromNewsletter($email: String) {
    getAreasFromNewsletter(email: $email) {
      id
    }
  }
`;

/**
 * __useGetAreasFromNewsletterQuery__
 *
 * To run a query within a React component, call `useGetAreasFromNewsletterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreasFromNewsletterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreasFromNewsletterQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetAreasFromNewsletterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAreasFromNewsletterQuery,
    GetAreasFromNewsletterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAreasFromNewsletterQuery, GetAreasFromNewsletterQueryVariables>(
    GetAreasFromNewsletterDocument,
    options,
  );
}
export function useGetAreasFromNewsletterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAreasFromNewsletterQuery,
    GetAreasFromNewsletterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAreasFromNewsletterQuery, GetAreasFromNewsletterQueryVariables>(
    GetAreasFromNewsletterDocument,
    options,
  );
}
export type GetAreasFromNewsletterQueryHookResult = ReturnType<
  typeof useGetAreasFromNewsletterQuery
>;
export type GetAreasFromNewsletterLazyQueryHookResult = ReturnType<
  typeof useGetAreasFromNewsletterLazyQuery
>;
export type GetAreasFromNewsletterQueryResult = Apollo.QueryResult<
  GetAreasFromNewsletterQuery,
  GetAreasFromNewsletterQueryVariables
>;
export const GetUserAreasDocument = gql`
  query getUserAreas($email: String) {
    getUserAreas(email: $email) {
      status
      areaOfInterest
    }
  }
`;

/**
 * __useGetUserAreasQuery__
 *
 * To run a query within a React component, call `useGetUserAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAreasQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserAreasQuery, GetUserAreasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserAreasQuery, GetUserAreasQueryVariables>(
    GetUserAreasDocument,
    options,
  );
}
export function useGetUserAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserAreasQuery, GetUserAreasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserAreasQuery, GetUserAreasQueryVariables>(
    GetUserAreasDocument,
    options,
  );
}
export type GetUserAreasQueryHookResult = ReturnType<typeof useGetUserAreasQuery>;
export type GetUserAreasLazyQueryHookResult = ReturnType<typeof useGetUserAreasLazyQuery>;
export type GetUserAreasQueryResult = Apollo.QueryResult<
  GetUserAreasQuery,
  GetUserAreasQueryVariables
>;
export const SignUpDocument = gql`
  mutation signUp($email: String!, $password: String!, $profile: UserProfileNew) {
    signUp(email: $email, password: $password, profile: $profile) {
      token
      OK
      error
      me {
        _id
        email
        profile {
          alias
          firstName
          lastName
          basket {
            _id
            ean
            units
            book {
              _id
              title
            }
          }
        }
      }
    }
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
